//import Logo from './Logo';
//import Notification from '../notificaton/Notification';
 import Notification from '../notification/Notification';
import LoginIcon from '../LoginIcon/LoginIcon';
import classes from './header.module.css';
import PrayerWeaverLogo from '../PrayerWeaverLogo/PrayerWeaverLogo';

const TopHeader = ()=>{
    
    return(
        <div id={classes.topBanner}>
        <div className='my-auto'>
            <PrayerWeaverLogo/>
        </div> 
     <div id={classes.alignFlex} className='gap-1'>
            <Notification/>
            <LoginIcon/>
     </div>
            
     

        </div>
    );
}
export default TopHeader;