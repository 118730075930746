import classes from "./MainHead.module.css";
//import { useLocation } from "react-router-dom";
//const NAVBAR_TEXTS = [{page:"/PrayerFor", text: "I want to Prayer For"}, {page:"/SavedPrayer", text: "Save Prayer"},{page:"/MinePrayers",text:"Save Prayer"}]
const HeaderText= (props)=>{
    //const location = useLocation();
//const textToShow=NAVBAR_TEXTS;
    return(
       <div>
                <p id={classes.headerTxt} className="mt-2">{props.children}</p>
                {/* I Need a (Urgent) Prayer</p> */}
       </div>
    )
    // {textToShow = NAVBAR_TEXTS.find(el => el.page === location.pathname)?.text}
}
export default HeaderText;