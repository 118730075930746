import classes from './MainHead.module.css';
const MainHeading = (props) =>{
    return(
        <div>
         <h1 className={classes.Prayertitle}>
         {props.children}</h1>
     </div>
       
    )
}
export default MainHeading;