// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/PrayerIcon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrayerFor_prayerFor__rGc71 {\n    background-Image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    color:#4f4f4f;\n    border:0px;\n    width:70px;\n    height: 70px;\n  \n\n  }\n  #PrayerFor_setCol__CrEAc{\n     \n      flex-direction: column;\n      \n  }\n  /* .fixedWidth{\n    \n  } */", "",{"version":3,"sources":["webpack://./src/Components/PrayerType/PrayerFor/PrayerFor.module.css"],"names":[],"mappings":"AAAA;IACI,wDAAmD;IACnD,4BAA4B;IAC5B,aAAa;IACb,UAAU;IACV,UAAU;IACV,YAAY;;;EAGd;EACA;;MAEI,sBAAsB;;EAE1B;EACA;;KAEG","sourcesContent":[".prayerFor {\n    background-Image:url('../../images/PrayerIcon.png');\n    background-repeat: no-repeat;\n    color:#4f4f4f;\n    border:0px;\n    width:70px;\n    height: 70px;\n  \n\n  }\n  #setCol{\n     \n      flex-direction: column;\n      \n  }\n  /* .fixedWidth{\n    \n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prayerFor": "PrayerFor_prayerFor__rGc71",
	"setCol": "PrayerFor_setCol__CrEAc"
};
export default ___CSS_LOADER_EXPORT___;
