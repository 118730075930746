import React, {useState, createContext,useContext} from "react";
const BackgroundContext = createContext();
export const BackgroundProvider = ({children}) => {
    const [isBackgroundVisible, setBackgroundVisibility] = useState(true);
  
    return (
      <BackgroundContext.Provider value={{isBackgroundVisible, setBackgroundVisibility}}>
        {children}
      </BackgroundContext.Provider>
    );
  };
  
  export const useBackground = () => useContext(BackgroundContext);