import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import classes from '../../Registerpge/WBackground.module.css';
import PrayerHead from "../../Logo/PrayerHead";
import MainHeader from "../../MainHeader/MainHeader";
import MinePrayers from "./MinePrayers";
import { ApiProviders } from "../../../context/ApiContext";

const MinePrayer = () =>{
return(

<div id={classes.Backgrnd} className='bg-image'>
       <PrayerHead/>
    <div>
        <MainHeader page="SavedPrayer"/>
    </div>
    <div>
   <ApiProviders>
      <MinePrayers/>
   </ApiProviders>
   </div>

</div>
 
)

}
export default MinePrayer;