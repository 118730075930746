 import classes from '../Notification.module.css';
 import React,{useState,useEffect, useCallback,memo} from 'react';
 import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import IconText from '../IconText/IconText';
import { useApi } from '../../../context/ApiContext';

 const ResponseB = ({prayerId}) =>{
  const { fetchpeoplesPraying, unfetchpeoplesPraying} = useApi();
  const [isLiked, setIsLiked] = useState(() => {
   
    return localStorage.getItem(`liked_${prayerId}`) === 'true';
  });
    console.log("prayerId",prayerId);
    useEffect(() => {
    
      localStorage.setItem(`liked_${prayerId}`, isLiked.toString());
    }, [isLiked, prayerId]);
  
    const handleToggle =async () => {
      // setisLiked(!isLiked);
      try {
        if (isLiked) {
          await unfetchpeoplesPraying(prayerId);
          setIsLiked(false);
        } else {
          await fetchpeoplesPraying(prayerId);
          setIsLiked(true);
        }
        // Update isLiked based on the likedata state
        //setisLiked(likedata ? false : true);
      } catch (error) {
        console.error(error);
      }
    }
  
  return (
    <div className='mb-3'>
      <div className={isLiked ? classes.peplefilled : classes.peopleb} style={{ marginLeft: 80 }} alt="" onClick={handleToggle} id={classes.clickable} />
    </div>
  );
     

 }
 export default memo(ResponseB);