import classes from './UrgentPrayer.module.css';
import IconText from '../IconText/IconText';
import { Link } from 'react-router-dom';
const UrgentPrayer = ()=>{
    return(
  
        <div id={classes.setCol} className='justify-content-center align-item-center mt-4'>
            <Link to="/Main/PrayerReqSend" >
                {console.log("2")}
          <div className={classes.urgentP}/>
          </Link>
         <div>
             <IconText>Pray <br></br> for me </IconText>
            
           
         </div>
       
        </div>
        )
}
export default UrgentPrayer;