import React,{useState} from 'react';
import classes from '../Notification.module.css';
//import icon from '../IconText.module.css';
//import axios from 'axios';
//import prayer from '../../../../../PWAPI/models/prayer';
const CommentB = () =>
{
    //const [comments,setCommments] = useState();
    //const [showCommentSection, setShowCommentSection] = useState(false);
    // const prayerId = localStorage.getItem("prayer-id").toString();
    // const userId = localStorage.getItem("user-id").toString();
    //const [prayerData,setPrayerReq] = useState({});

   
// useEffect(() => {
//    const url= "http://localhost:8080/prayer/allprayerrs";
//    const fetchPrayer = async () => {
//      try {
//        const responseData = await axios.get(url);
//        //console.log(responseData.data);
//        setPrayerReq(responseData);
//      } catch (err) {
//         console.log(err);
//      }
//    };
//    fetchPrayer();
//  },[]);
    // const url = "http://localhost:8080/comments";
    // const makecomment = ()=>{
    //     console.log("comment added ");
    
    
   
    //     const commentt = {
    //         PrayerComments:comments,
    //         // prayerId:prayerId,
    //         userId:localStorage.getItem("user-id").toString(),
    //         prayerId:localStorage.getItem("prayer-id").toString()
            
    //     }
    //     const config = {
    //         headers:{
    //             'Content-Type': 'application/json'
    //            //"content-type":"application/json"
    //         }
    //     }
    //     const res = axios.post(url,commentt,config)
    //     setCommments(res);
    //             console.log(res);
         
    //      }
    

//     const onSubmit = event=>{
//         event.preventDefault()
//         makecomment(commments,prayerId)
//     }
//const onSubmit = {onSubmit} https://www.youtube.com/watch?v=7ejY1b_NqHI
    return(
  
        <div className='mb-2'>
      
           
           <div className={classes.commentb} style={{marginLeft:-66}} />
           
   
   

        </div>
        )

}
export default CommentB
