
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import MainHeader from "../../MainHeader/MainHeader";
import classes from '../../Registerpge/WBackground.module.css';
import PopularPrayer from "../PrayerPopular/PopularPrayer";
import PrayerHead from "../../Logo/PrayerHead";
//import {ApiProviders} from "../../../context/ApiContext";

// import PopularPrayer2 from "../PrayerPopular/PopularPrayer2";
const PopularPrayers = ()=>{
    return(

        <div id={classes.Backgrnd} className='bg-image'>
            <PrayerHead/>
            <div>
                <MainHeader page="PrayerFor"/>
            </div>
        <div className="d-flex justify-content-center">
            {/* <ApiProviders> */}
               <PopularPrayer/>
            {/* </ApiProviders> */}
            
        </div>
       
        </div>
        
        )
}
export default PopularPrayers;