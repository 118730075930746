import classes from './PrayerFor.module.css';
import IconText from '../IconText/IconText';
import { NavLink} from 'react-router-dom';
const PrayerFor = () =>
{
    return(
  
        <div id={classes.setCol} className='justify-content-center align-item-center mt-4'>
     <NavLink activeclassname="is-active" to="/Main/UrgentPrayers">
        <div className={classes.prayerFor} />
        </NavLink>
     
       <IconText>I want to <br></br> Prayer For</IconText>
   
        
         
       
        </div>
        )

}
export default PrayerFor; 