import classes from './SavePrayer.module.css';
import IconText from '../IconText/IconText';
import { NavLink } from 'react-router-dom';
const SavePrayer = () =>{
    return(
  
        <div id={classes.setCol} className='justify-content-center align-item-center mt-4'>
   <NavLink activeclassname="is-active" to="/Main/SavedPrayer">
       <div className={classes.savePrayer} />
      
       </NavLink>
        <div>
        <IconText> Saved <br></br> Prayers</IconText> 
        </div>
     
        </div>
        )
}
export default SavePrayer;