// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#WBackground_Backgrnd__eBHF7{\n    background-image:linear-gradient(180deg, #D8D8D8 0%, #F4F4F4 36.95%, #FFFFFF 87.19%),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    height:auto;\n    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);\n  \n    }\n    .WBackground_WBackgrnd__U5WPt{\n        background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n        height:100vh;\n        }\n        #WBackground_formbck__5oyRN{\n            background-image: linear-gradient(to top, rgb(201, 138, 247), skyblue);\n\n            /* width:950px;\n            height:900px; */\n            box-shadow:  5px 5px 5px 5px rgba(5, 113, 171, 0.2);\n            border-radius: 25px;\nmargin-top:40px;\nmargin-bottom: 20px;\n\n        }", "",{"version":3,"sources":["webpack://./src/Components/Registerpge/WBackground.module.css"],"names":[],"mappings":"AAAA;IACI,4HAA0G;IAC1G,WAAW;IACX,yCAAyC;;IAEzC;IACA;QACI,wDAAsC;QACtC,YAAY;QACZ;QACA;YACI,sEAAsE;;YAEtE;2BACe;YACf,mDAAmD;YACnD,mBAAmB;AAC/B,eAAe;AACf,mBAAmB;;QAEX","sourcesContent":["#Backgrnd{\n    background-image:linear-gradient(180deg, #D8D8D8 0%, #F4F4F4 36.95%, #FFFFFF 87.19%),url(../images/bg.png);\n    height:auto;\n    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);\n  \n    }\n    .WBackgrnd{\n        background-image:url(../images/bg.png);\n        height:100vh;\n        }\n        #formbck{\n            background-image: linear-gradient(to top, rgb(201, 138, 247), skyblue);\n\n            /* width:950px;\n            height:900px; */\n            box-shadow:  5px 5px 5px 5px rgba(5, 113, 171, 0.2);\n            border-radius: 25px;\nmargin-top:40px;\nmargin-bottom: 20px;\n\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backgrnd": "WBackground_Backgrnd__eBHF7",
	"WBackgrnd": "WBackground_WBackgrnd__U5WPt",
	"formbck": "WBackground_formbck__5oyRN"
};
export default ___CSS_LOADER_EXPORT___;
