import classes from './SecureConnection.module.css'; 


const SecureConnection = () =>{
return(
  
         <div className='my-3 d-flex justify-content-center mt-3 mb-5'>
         <div  id={classes.iconC} alt=""/>  
    
        <p className={classes.connection}>We use a secure connection </p>
         </div>
      

  
    
   
);
}
export default SecureConnection;