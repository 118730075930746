import classes from '../Notification.module.css';

const Share = () =>{
    return(
  
        <div>
      
       <div className={classes.Sshare}/>
      
     
        </div>
        )
}
export default Share;