// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/Vector.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/goBack.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#PrayerWeaverLogo_logoPW__ZyA8B{\n    background-Image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    width: 120px;\n    height:108px;\n    margin-left:10px;\n    \n}\n#PrayerWeaverLogo_goBackBtn__XF1I\\+{\n    background-Image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    width: 20.38px;\n    height:40px;\n    padding:10px;\n    margin-left: 60px;\n    display: flex; \n    align-items: center;\n    background-repeat: no-repeat;\n    \n}\n#PrayerWeaverLogo_setLogo__EwohA{\n    vertical-align: middle;\n \n    \n}\n", "",{"version":3,"sources":["webpack://./src/Components/PrayerWeaverLogo/PrayerWeaverLogo.module.css"],"names":[],"mappings":"AAAA;IACI,wDAA0C;IAC1C,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,gBAAgB;;AAEpB;AACA;IACI,wDAA0C;IAC1C,cAAc;IACd,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,4BAA4B;;AAEhC;AACA;IACI,sBAAsB;;;AAG1B","sourcesContent":["#logoPW{\n    background-Image:url(../images/Vector.png);\n    background-repeat: no-repeat;\n    width: 120px;\n    height:108px;\n    margin-left:10px;\n    \n}\n#goBackBtn{\n    background-Image:url(../images/goBack.png);\n    width: 20.38px;\n    height:40px;\n    padding:10px;\n    margin-left: 60px;\n    display: flex; \n    align-items: center;\n    background-repeat: no-repeat;\n    \n}\n#setLogo{\n    vertical-align: middle;\n \n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoPW": "PrayerWeaverLogo_logoPW__ZyA8B",
	"goBackBtn": "PrayerWeaverLogo_goBackBtn__XF1I+",
	"setLogo": "PrayerWeaverLogo_setLogo__EwohA"
};
export default ___CSS_LOADER_EXPORT___;
