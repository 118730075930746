// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/X2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#header_topBanner__Lpxqd{\n    background-Image:linear-gradient(92.81deg, #B258F1 10.47%, #00DBDE 91.96%),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    width: 1800px;\n    max-width: 100%;\n    height: 120px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 2rem;\n   \n}\n#header_toppBaner__C-UkN{\n    background-Image:linear-gradient(92.81deg, #B258F1 10.47%, #00DBDE 91.96%),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    width: 1800px;\n    max-width: 100%;\n    height: 100px;\n    display: flex;\n    align-items:center;\n    padding: 0 1rem 0 0;\n    /* padding: 0 50rem 0 0; */\n        \n}\n#header_alignFlex__zajH7{\n    display: flex;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Components/Logo/header.module.css"],"names":[],"mappings":"AAAA;IACI,kHAAgG;IAChG,4BAA4B;IAC5B,aAAa;IACb,eAAe;IACf,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;;AAEnB;AACA;IACI,kHAAgG;IAChG,4BAA4B;IAC5B,aAAa;IACb,eAAe;IACf,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,0BAA0B;;AAE9B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["#topBanner{\n    background-Image:linear-gradient(92.81deg, #B258F1 10.47%, #00DBDE 91.96%),url(../images/X2.png);\n    background-repeat: no-repeat;\n    width: 1800px;\n    max-width: 100%;\n    height: 120px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 2rem;\n   \n}\n#toppBaner{\n    background-Image:linear-gradient(92.81deg, #B258F1 10.47%, #00DBDE 91.96%),url(../images/X2.png);\n    background-repeat: no-repeat;\n    width: 1800px;\n    max-width: 100%;\n    height: 100px;\n    display: flex;\n    align-items:center;\n    padding: 0 1rem 0 0;\n    /* padding: 0 50rem 0 0; */\n        \n}\n#alignFlex{\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBanner": "header_topBanner__Lpxqd",
	"toppBaner": "header_toppBaner__C-UkN",
	"alignFlex": "header_alignFlex__zajH7"
};
export default ___CSS_LOADER_EXPORT___;
