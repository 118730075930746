 import classes from './Logo.module.css'
 import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

 const Logo = () =>{
     return (
      
        <div className="d-flex align-items-center justify-content-center" >
 

        <div  id={classes.logoPW} alt=""/>
       </div>
        
     
     )

 }
 export default Logo;