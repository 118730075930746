import React from 'react';

import Navigation from './Navigation';
import classes from './MainHeader.module.css';
import NavSaveP from './NavSaveP'

const MainHeader = ({page}) => {
  
  return (
    <header className={classes.mainHeader}>
      {page === 'PrayerFor' ? <Navigation/> : <NavSaveP/>}
      {/* <Navigation isLoggedIn={props.isAuthenticated} onLogout={props.onLogout} /> */}
      
    </header>
  );
};

export default MainHeader;
