// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, " #ButtonT_button__-Mys7{\n    width:416px;\n    height: 55px;\n    display: flex;\n    justify-content: center;\n    border: 1px solid #62BB46;\n    font-family:'Big Shoulders Display';\n    font-weight: 800;\n    font-size:26px;\n    background: #62BB46;\n    margin-left: 120px;\n    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);\n }\n", "",{"version":3,"sources":["webpack://./src/Components/UI/Button/ButtonT.module.css"],"names":[],"mappings":"CAAC;IACG,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,mCAAmC;IACnC,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,uCAAuC;CAC1C","sourcesContent":[" #button{\n    width:416px;\n    height: 55px;\n    display: flex;\n    justify-content: center;\n    border: 1px solid #62BB46;\n    font-family:'Big Shoulders Display';\n    font-weight: 800;\n    font-size:26px;\n    background: #62BB46;\n    margin-left: 120px;\n    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ButtonT_button__-Mys7"
};
export default ___CSS_LOADER_EXPORT___;
