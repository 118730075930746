
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useParams } from "react-router-dom"; 
import CompleteNews from './CompleteNews';
//import comment from '../../../../../PWAPI/models/comment';
// import {Link} from 'react-router-dom';
//import comment from '../../../../../PWAPI/models/comment';
const SingleNew = ()=>{
  const {NewsId} = useParams();
  console.log("News ID inside single new",NewsId);
  
    return(
   
      
        <div>
        
            <CompleteNews NewsId={NewsId}/>
       </div>
  
    )
}
export default SingleNew;