import React,{useState,useEffect} from 'react';
 import classes from '../Notification.module.css'
 import 'bootstrap/dist/css/bootstrap.min.css';
 import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { useApi} from '../../../context/ApiContext';
 const ResponseP = ({prayerId}) =>{
  const { fetchpeoplesPraying, unfetchpeoplesPraying} = useApi();

  const [isLiked, setIsLiked] = useState(() => {
   
   return localStorage.getItem(`liked_${prayerId}`) === 'true';
 });
   console.log("prayerId",prayerId);
   useEffect(() => {
   
     localStorage.setItem(`liked_${prayerId}`, isLiked.toString());
   }, [isLiked, prayerId]);

  const handleToggle = async () => {
    try {
      if (isLiked) {
        await unfetchpeoplesPraying(prayerId);
        setIsLiked(false);
      } else {
        await fetchpeoplesPraying(prayerId);
        setIsLiked(true);
      }

    } catch (error) {
      console.error(error);
    }
  };

 return (
      <div className='mb-3'>
        <div className={isLiked ? classes.peoplef : classes.peoplep} alt="" onClick={handleToggle} id={classes.clickable} />
  
        
       
       </div>
     )

 }
 export default ResponseP;