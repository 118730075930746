import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import classes from './Main.module.css';
const PrayerSign = () =>{
return(


<div className={`d-flex align-item-center justify-content-center ${classes.mainBackground}`}>
      {/* <img className={classes.Prayerr}  /> */}
</div>




)

}
export default PrayerSign;