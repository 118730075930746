import React,{useState,useEffect, useCallback,memo} from 'react';
 import classes from '../Notification.module.css'
 import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useApi } from '../../../context/ApiContext';
//import socketIOClient from 'socket.io-client';

const Response = ({ prayerId }) => {

  const { fetchpeoplesPraying, unfetchpeoplesPraying} = useApi();
 
 const [isLiked, setIsLiked] = useState(() => {
  // Get the liked status from local storage when the component mounts
  return localStorage.getItem(`liked_${prayerId}`) === 'true';
});
  console.log("prayerId",prayerId);
  
  useEffect(() => {
    // Update local storage when isLiked changes
    localStorage.setItem(`liked_${prayerId}`, isLiked.toString());
  }, [isLiked, prayerId]);
 

  const handleToggle = async () => {
   
    try {
      if (isLiked) {
        await unfetchpeoplesPraying(prayerId);
        setIsLiked(false)
      
      } else {
        await fetchpeoplesPraying(prayerId);
        setIsLiked(true);
     
      }
      // Update isLiked based on the likedata state
      //setisLiked(likedata ? false : true);
    } catch (error) {
      console.error(error);
    }
  } 

  return (
    <div className='mb-3'>
      <div className={isLiked ? classes.peplefilled: classes.people} alt="" onClick={handleToggle} id={classes.clickable}/>
     
    </div>
  );
};

export default memo(Response);
