//import React,{useState} from "react";
import { useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import { useQuery } from "react-query";
//import ButtonT from "../UI/Button/ButtonT";
//import classes from './Registerpge.module.css';
//import {Form} from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';
const apiUrl = config();
const PrayerReqGet = () =>{
const [prayerData,setPrayerReq] = useState({});
const isProduction = process.env.NODE_ENV === 'production';
const serverUrl = isProduction ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_LOCAL;

useEffect(() => {
   const url= `${process.env.REACT_APP_API_HOST}/prayers/allprayerrs`;
   const fetchPrayer = async () => {
     try {
       const responseData = await axios.get(url);
       console.log("Get Prayers 01",responseData.data.prayers);
       setPrayerReq(responseData.data.prayers);
     } catch (err) {}
   };
   fetchPrayer();
 },[]);
//console.log(prayerData.data);
//const { data} = useQuery("getPrayerReq",fetchPrayer);

//setPrayerReq()

return(
   
    
<div className="d-flex align-item-center justify-content-center">

<div>
   <h1>Hi</h1>
   {prayerData !== undefined && prayerData.length > 0 && (

   <table style={{width:'100%'}} border='2'>
      <thead>
      <tr>
      <th style={{width:'15%'}}>Prayer Title</th>
         <th style={{width:'20%'}}>Description</th>
         <th style={{width:'20%'}}>Image</th>
         <th style={{width:'20%'}}>Prayer Group</th>
         <th style={{width:'15%'}}>userName</th>
      </tr>
      </thead>
      <tbody>
      {prayerData.map((prayers, index) => {
        console.log('Prayers before mapping:', prayers); // Add this line for debugging

        return (
          <tr key={index}>
            <td>{prayers.prayerTitle}</td>
            <td>{prayers.prayerdesc}</td>
            <td>
              <img src={`${serverUrl}${prayers.image}`} style={{ height: 50, width: 100 }} alt="Profilepic" />
            </td>
            <td>{prayers.prayergroup}</td>
            <td>{prayers.userId  ? prayers.userId.username : ' value not found'}</td>
          </tr>
        );
      })}
    </tbody>
     
   </table>
   )}
</div>
</div>
)

}
export default PrayerReqGet;
//<label for="img">Select image:</label>
//<input type="file" id="img" name="img" accept="image/*">
//<input type="submit">