import React, { useState, useEffect } from 'react';
import { useLocation} from 'react-router-dom';

function NetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [networkError, setNetworkError] = useState(false);
  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  const isSignupPage = location.pathname === '/signup';
  const isProfilePage = location.pathname === '/profile';
  const isModelPage = location.pathname === '/Main/UrgentPrayers';
  const isCompletePost = location.pathname === '/singlepost/:prayerid';
const isPrayerUpdate = location.pathname === '/Main/MinePrayers';

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  const leftPosition = isProfilePage
  ? 695
  : isModelPage || isPrayerUpdate
  ? 355
  : 675;

const topPosition = isLoginPage
  ? 270
  : isSignupPage
  ? 265
  : isProfilePage
  ? 110
  : isModelPage || isPrayerUpdate
  ? 10
  :isCompletePost
  ? 11
  : 115;

  return (
    <div>
      {!isOnline || networkError ? (
        <div
          style={{
            color: 'red',
            fontWeight: 'bold',
            padding: '8px',
            border: '1px solid red',
            borderRadius: '5px',
            position: 'absolute',
            left: leftPosition,
            top: topPosition,
          }}
        >
          Network Error
        </div>
      ) : null}
    </div>

  );
}

export default NetworkStatus;
