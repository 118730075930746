import classes from './UrgentPrayer.module.css';
import PrayerText from "../../UI/MainHead/PrayerText";
import Share from '../../notification/feedback/Share';
import ResponseB from '../../notification/feedback/ResponseB';
import CommentB from '../../notification/feedback/CommentB';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React,{useState,useEffect,memo,useCallback} from "react";
import { useApi } from '../../../context/ApiContext';
import { socket } from '../../../index';
import { S3Client } from '@aws-sdk/client-s3';
import debounce from 'lodash.debounce';
import nonProfilPic from '../../images/nonIcon.png';
import NetworkStatus from '../../Registerpge/NetworkStatus';

  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,

  });


var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const PrayerReq = ({prayerId})=>{
   const {prayerData,setPrayerReq,commments,postedComment,getCommments,fetchSinglePost,commentFetching,makeCommentApi} = useApi();
   const [comments,setCommments] = useState([]);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [likescount, setLikesCount] = useState(0);
   const [commentsRealTime, setCommentsRealTime] = useState([]);
   const [networkError,setNetworkError] = useState(null);
  
   const urlC = `${process.env.REACT_APP_API_HOST}/prayers/${prayerId}/comments`;
   console.log("url post comment model",urlC);
   const isProduction = process.env.NODE_ENV === 'production';
  const serverUrl = isProduction ? process.env.REACT_APP_ASSET_URL : process.env.REACT_APP_ASSET_URL_LOCAL;
  const UserName = localStorage.getItem("user-name").toString();
  const updateImageUrls = (post) => {
    if (!post) return null;

    const imageUrl = post.image ? `https://${host}/prayer/${post.image}` : null;
    const profileImgUrl = post.userId?.AddPic ? `https://${host}/member/${post.userId.AddPic}` : nonProfilPic;

    return {
      ...post,
      imageUrl,
      profileImgUrl,
    };
  };
  const fetchData = useCallback(debounce(async (prayerId) => {
    try {
        console.log("Fetching prayer post id",prayerId);
        const fetchedData = await fetchSinglePost(prayerId);
        const updatedPrayerData = updateImageUrls(fetchedData);
        setPrayerReq(updatedPrayerData);
        console.log("Updated prayer data", updatedPrayerData);
    } catch (err) {
        console.error("Error fetching prayer data:", err);
    }
}, 100), []);

const fetchComment = useCallback(debounce(async (prayerId) => {
  try {
       await commentFetching(prayerId);
      
  } catch (err) {
      console.error("Error fetching comments:", err);
  }
}, 100), []);
    useEffect(() => {
      if (prayerId) {
        fetchData(prayerId);
        fetchComment(prayerId);
    }
 
  },[prayerId,fetchData,fetchComment]);

 const makecomment = async ()=>{
    console.log("comment added ");

    setIsSubmitting(true); 
 
    try{
     const res = await makeCommentApi({
        PrayerComments:comments,
        prayerId:prayerId,
        userId:localStorage.getItem("user-id").toString(),
        userName:UserName
    });
    setNetworkError(false);
    if (res.status === 201 && postedComment) {
    
      console.log('comment',postedComment);
  } 
       setCommments('');
    }catch(err){
      setNetworkError(true);
      console.log(err);
    } finally {
      setIsSubmitting(false); // Reset the submission flag
    }
  
     }

useEffect(() => {
      const handleCommentAdded = (comment) => {
        console.log("new Comment", comment);
        getCommments(prevComments => [comment, ...prevComments]);
      };
      const handleLikeUpdate = (newPost) => {
         const updatedPost = updateImageUrls(newPost);
         setPrayerReq(updatedPost);
      };
      socket.connect();
      console.log("UseEffect Socket.io commentAdded", socket);
      socket.on("connect", () => {
      console.log("Socket connected successfully");
  });
           socket.on("newComment", handleCommentAdded);
           socket.on('likeAdded', handleLikeUpdate);
           socket.on('unlikeAdded', handleLikeUpdate);
      return () => {
          socket.off("likeAdded");
          socket.off("unlikeAdded");
          socket.off("newComment");
      };
  },[socket]);
  const updatedLikesCount =(newLikesCount) =>{
    setLikesCount(newLikesCount);
     console.log("likes inside prayer page",likescount);
     };
     const minLength = 100;
     const handleKeyDown = (e) => {
       if (e.ctrlKey && e.keyCode === 13) {
         e.preventDefault();
   
         const position = e.target.selectionEnd;
         const value = e.target.value;
         const newValue = value.substring(0, position) + '\n' + value.substring(position);
         e.target.value = newValue;
   
         // Move the cursor to the next line
         e.target.selectionStart = e.target.selectionEnd = position + 1;
       }
     };
     if (!prayerData) {
      return <p>Loading</p>;
    }
  
    const prayerArray = Array.isArray(prayerData) ? prayerData : [prayerData];
    let uiCommentUpdate = commentsRealTime.length > 0 ? commentsRealTime : commments;

    return(
   
      
        <div>
               
          <div className={'col'}>
          <div className={`modal-header sticky-top`} style ={{position:"sticky",backgroundColor: "#f8f9fa"}}>
               <h4 className="modal-title" style ={{marginLeft:330}} >Prayer Request</h4>
           </div>
           <div className={`modal-body `} style ={{maxHeight: 489, overflowY: "auto"}}>
           <NetworkStatus  networkError={networkError} />
          {prayerArray !==null ? (
        
        <div>
           
           {prayerArray.map((item) => (
            <>          
               <div className={'mt-2'}>
               <img src={item.profileImgUrl} style={{width:46, height:43, borderRadius:50}} />
               {/* <UserPic className={'justify-content-between'}/> */}
               <div className="ms-4">{item.userId && item.userId.username && (<p style={{fontFamily:'calibri',fontSize:16,fontWeight:700,color:'black',marginTop:-43, marginLeft:30}}>{item.userId.username}</p>
) }
{new Date(item.createdAt) instanceof Date && !isNaN(new Date(item.createdAt)) ? (
     <span style={{fontFamily:'calibri',fontSize:14,fontWeight:600,color:'black',opacity:0.3,marginLeft:28,position:'relative',top:-20}}>
      {new Date(item.createdAt).toLocaleString(undefined, dateOptions)}
    </span>
  ) : (
    <span>Invalid Date</span>
  )}</div>
          </div>   
              <div key={item._id} style={{marginLeft:-20}}>
                  <PrayerText className={'justify-content-center'}>{item.prayerdesc}</PrayerText>  
             </div>
             <div className="ml-5">
                  <img src={item.imageUrl} style={{width:450,height:300}} className="d-block mx-auto mt-5"/>
             </div>
             <div className={'mt-3'}>
             <hr style={{width:770}}/>
                <div className='d-flex'>
                        
                          <div style={{position:"relative",left:98,top:28}}>
                           <ResponseB prayerId={item._id} likes={item.likes} updatedLikesCount={updatedLikesCount} />
                           <p style={{position:"relative", top:-44,left:125}}>{item.likes.length}</p>
                          </div>
                          <div>
                          
                <div style={{position:"relative",left:339,top:34}}>
                {/* <div className={classess.commentb} style={{marginLeft:50, marginTop:5}}> */}
                      {/* <i className={classess.commentb}></i> */}
                      <CommentB/> <p style={{position:"relative", top:-28,left:-31}}>{item.Comments.length}</p>
                </div>
                     {/* <p id={icon.IconF} className='text-black' style={{marginLeft:30}}>Comments</p> */}
             
                  </div>
                          <div style={{position:"relative",left:577,top:31}}>
                              <Share/>
                          </div>
                      
                 </div>
                 <hr style={{width:770}}/>
             </div>
             </>

           ))}
 
         </div>
       ) : (
         <p>No Post found</p>
       )}
          
               {uiCommentUpdate !== undefined && (
            
            <div style={{marginLeft:45, marginTop:-30}}>
              { (uiCommentUpdate.map((comment)=>{
                 //const {PrayerComments,userName,prayerdesc} = comment;
                
                 console.log("check");
              return(
           
                <div key={comment._id} >  
                <p><strong >{comment.userName}</strong> <span className={classes.commentdata}>{comment.PrayerComments} </span></p>
                </div>
              )
                }))}
      
              </div>
              
                 )}       

          </div>
          <div className={`modal-footer`} style ={{position:"sticky", zIndex: 999, marginTop:28, fontFamily: 'Calibri'}}>
             <div> 
               <input type="input" className="form-control" placeholder="Comments.." onChange={(e)=>{setCommments(e.target.value)}} onKeyDown={handleKeyDown} value={comments} minLength={minLength} style ={{width:680,position:"absolute", right:94}} name={"Prayercomment"} />
               <button className="btn btn-light text-center" style ={{width:75,borderRadius:7,position:"relative", right:15}} onClick={()=>makecomment()} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'POST'}</button> </div>
               
              </div>  
              <p style={{position:"relative", left:45, color:'black',opacity:0.75}}> Character Count: {comments.length}/{minLength}</p> 
          </div>
 
    </div>
  
    )
}
export default memo(PrayerReq);