import React,{useState,useEffect, useCallback,memo} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import classes from './UrgentPrayer.module.css';
import axios from 'axios';
import Loading from "../../UI/Loading/Loadding";
import icon from '../../notification/IconText.module.css';
import Response from '../../notification/feedback/Response';
import settings from '../../UI/secConnection/SecureConnection.module.css';
import Modal from '../../UI/Modal/Modal';
import PrayerReq from "./PrayerReq";
import { useApi } from "../../../context/ApiContext";
import {Link} from 'react-router-dom';
import Comment from "../../notification/feedback/Comment";
import MainHead from "../../UI/MainHead/MainHead";
import UPText from "../../UI/MainHead/UPText";
import Share from "../../notification/feedback/Share";
import SaveP from "../../UI/ArrowRight/SaveP";
import { socket } from '../../../index';
import { S3Client } from '@aws-sdk/client-s3';
import nonProfilPic from '../../images/nonIcon.png';
import InfiniteScroll from "react-infinite-scroll-component";

  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,
 
  });
  
const Prayers = () =>{
  
const { updatedPrayerId,postedComment,makeCommentApi} = useApi();
const [loading,setloading] = useState(false);
const [comments,setCommments] = useState('');
const [commentsCount, setCommentsCount] = useState(0);
const [totalPrayers,setTotalPrayers] = useState(0);
const [selectedPostId, setSelectedPostId] = useState(null);
const [isModalOpened,setIsModalOpened] = useState(false);
const [isSubmitting, setIsSubmitting] = useState(false);
const [likescount, setLikesCount] = useState([]);
const [postRemoveLike, setPostRemoveLike] = useState([]);
const [moreRecordsAvailable, setMoreRecordsAvailable] = useState(true);
const [allRecordsLoaded, setAllRecordsLoaded] = useState(false);
const [prayers, setPrayers] = useState([]);
const [offset, setOffset] = useState(0);
const [hasMore, setHasMore] = useState(true);

const UserName = localStorage.getItem("user-name").toString();
const churchhId = localStorage.getItem("churchId");

const url =`${process.env.REACT_APP_API_HOST}/prayers/allprayers/${churchhId}`;

var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const limit = 4;
const updateImageUrls = (data) => {
  return data.map((prayerItem) => {
    const prayerImg = prayerItem.image;
    const profileImg = prayerItem.userId.AddPic;

    const imageUrl = prayerImg ? `https://${host}/prayer/${prayerImg}` : null;
    const profileImageUrl = profileImg ? `https://${host}/member/${profileImg}` : null;
    console.log('Profile Image URL:', profileImageUrl);
    return {
      ...prayerItem,
      imageUrl: imageUrl,
      profileImageUrl: profileImageUrl,
    };
  });
};
const fetchPrayers = async () => {
  try {
    const response = await axios.get(url, {
      params: {
        offset,
        limit
      },
    });
    const data = response.data;
    const updatedPrayers = updateImageUrls(data.prayers);
    setPrayers(prevPrayers => [...prevPrayers, ...updatedPrayers]);
    setTotalPrayers(response.data.prayers);

    // Check if we should continue loading more prayers
    if (prayers.length + updatedPrayers.length >= response.data.prayers) {
      setHasMore(false);
    }else {
      setHasMore(true);
    }

    setOffset(prevOffset => prevOffset + limit);
  } catch (error) {
    console.error('Error fetching prayers:', error);
  }
};

useEffect(() => {
  fetchPrayers();
}, []);



const makeComment = async ()=>{
  console.log("comment added ");
  const comment = comments[selectedPostId];

  setIsSubmitting(true); 

  try{
    const res = await makeCommentApi({
      PrayerComments:comment,
      prayerId:selectedPostId,
      userId:localStorage.getItem("user-id").toString(),
      userName:UserName
  });
  console.log("res",res);
  if (res.status === 201 && postedComment) {

    console.log('comment');
    //socket.emit("newcommentt",postedComment);
    
    console.log('comment',postedComment);
} 

    setCommments('');     
    setCommments(prevComments => ({
    ...prevComments,
    [selectedPostId]: '' // Clear comment for selected prayer ID after posting
  }));
    
  console.log ("recieved new comment",res);
           
  }catch(err){
    console.log(err);
    
  } finally {
    setIsSubmitting(false); // Reset the submission flag
  }

   }
useEffect(() => {
 
  const handlePostAdded = (data) => {
    console.log("Socket connected");
    console.log("data Received from socketio:", data);
    console.log(data.prayerTitle); //&& data.prayerdesc)
    socket.on("postAdded", (data) => {
    setPrayers((prevPosts) => {
      console.log("prevPosts test", prevPosts);
      const updateddpryers = updateImageUrls([data, ...prevPosts]);
      return [data, ...updateddpryers];
    });
  });
  };


  // const handleCommentAdded = (comment) => {
  //   console.log("new Comment", comment);
  //   setCommentsCount(prevCount => prevCount + 1);
  //   getCommments(prevComments => [comment, ...prevComments]);
  // };
  socket.connect();
  console.log("UseEffect Socket.io PostAdded", socket);

  socket.on("connect", () => {
    console.log("Socket connected successfully");
  });

  socket.on("postAdded", handlePostAdded);
  // socket.on("newComment", handleCommentAdded);

        socket.on('likeAdded', (newPosts) => {
          setPrayers((prevpryrs) => {
            const updated = prevpryrs.map((prayerr) => prayerr._id === newPosts._id ? newPosts : prayerr);
            return updateImageUrls(updated);
          });
        });

socket.on('unlikeAdded', (newPosts) => {
  setPrayers((prevPosts) => {
    const updated = prevPosts.map((prayerr) => prayerr._id === newPosts._id ? newPosts : prayerr);
    return updateImageUrls(updated);
  });
});

  return () => {
    socket.off("postAdded", handlePostAdded);
    socket.off("likeAdded");
    socket.off("unlikeAdded");
    // socket.off("newComment");
    // socket.disconnect();
  };
}, [socket]);


const handleClick = useCallback((postId,action) => {
  setSelectedPostId(postId);
  updatedPrayerId(postId);
  //setIsModalOpened(true);
  if (action === 'viewC') {
  // setSelectedPostId(postId);
   //prayerId = {selectedPostId};
   setIsModalOpened(true);
 }
 if (action === 'Coment') {
  //setSelectedPostId(postId);
  console.log("Selected Post Id post comment:", postId);
}
 if (action === 'like') {
  //setSelectedPostId(postId);
  //setIsModalOpened(true);
  console.log("Selected Post Id post comment:", postId);
}


},[])

   const HandleInput = (e,prayerId) => {
    const { value } = e.target;
    const stringValue = value.toString();
    setCommments(prevComments => ({
      ...prevComments,
      [prayerId]:stringValue
    }));
  };

 const updatedLikesCount =(newLikesCount) =>{
     setLikesCount(newLikesCount);
     console.log("likes inside prayer page",likescount);
      };
      
      const minLength = 100;
      const handleKeyDown = (e) => {
        if (e.ctrlKey && e.keyCode === 13) {
          e.preventDefault();
    
          const position = e.target.selectionEnd;
          const value = e.target.value;
          const newValue = value.substring(0, position) + '\n' + value.substring(position);
          e.target.value = newValue;
    
          // Move the cursor to the next line
          e.target.selectionStart = e.target.selectionEnd = position + 1;
        }
      };
      let uiPosts = likescount.length > 0 ? likescount : postRemoveLike.length > 0 ? postRemoveLike : prayers;
      // console.log(loading,"testout");
      // console.log(allRecordsLoaded,"check value");
return(
  <InfiniteScroll
  dataLength={prayers.length}
  next={fetchPrayers}
  hasMore={hasMore}
  loader={<Loading />}
  endMessage={<p style={{ textAlign: 'center' }}>No more records found</p>}
  >
   <div className={'d-flex justify-content-center'}>
       {   loading ? ( // Show loading spinner while data is being fetched
           <Loading />
      ) :  uiPosts !== undefined && uiPosts.length > 0 ? (    
      <div>

      {
       uiPosts.map(prayers =>(
        prayers.prayerPriority === "Normal"  ?(
          <div id= {classes.NPrayer} className={'mt-3 mb-4 bg-image'} prayerId={prayers._id} >
            <div>

            <div className={'col '}>
           
       
            <img src={prayers.profileImageUrl || nonProfilPic } style={{width:46,height:43, marginTop:10 , borderRadius:50 }} />
            <div className="ms-4">{prayers.userId && prayers.userId.username && (<p style={{fontFamily:'calibri',fontSize:16,fontWeight:700,color:'white',marginTop:-43,marginLeft:27}}>{prayers.userId.username}</p>
) }
{new Date(prayers.createdAt) instanceof Date && !isNaN(new Date(prayers.createdAt)) ? (
    <span style={{fontFamily:'calibri',fontSize:14,fontWeight:600,color:'white',opacity:0.3,marginLeft:27,position:'relative',top:-20}}>
      {new Date(prayers.createdAt).toLocaleString(undefined, dateOptions)}
    </span>
  ) : (
    <span>Invalid Date</span>
  )}</div>


          </div>
          <div className={'col mt-4 ms-5'}>
            <SaveP prayerId={prayers._id}/>
          </div>
            </div>
          
           <div className="mt-5">
             <MainHead style={{marginLeft:18}}>{prayers.prayerTitle} </MainHead>
              <Link to={`/singlepost/${prayers._id}`} style={{ textDecoration: 'none' }}><UPText className={'justify-content-center'} style={{marginLeft:18}}>{prayers.prayerdesc}</UPText> </Link> 
              <img src={prayers.imageUrl} style={{width:500}} className="d-block mx-auto mt-5" />
         </div>
      <div className={'col mt-4'} id={settings.optionP}>
                <div key={prayers._id} style={{marginLeft:-50}}>
                    <Response  prayerId={prayers._id} likes={prayers.likes} updatedLikesCount={updatedLikesCount}  />
                    <p className={icon.IconF}style={{marginLeft:139, marginTop:-43, color:"white", fontFamily: "Calibri", opacity: 0.5,}}>{prayers.likes.length}</p>
                </div>
                <div>
      <button className="btn bg-transparent" id={classes.ViewComment} onClick={() => handleClick(prayers._id,'viewC')} ><Comment/> <p style={{position:"relative", left:-3, top:-29, color:"white", opacity:0.50}}>{prayers.Comments.length}</p></button> 

      </div>
          
      <div>
          <Share style={{marginLeft:-55}} page="PrayerForr"/> 
        </div>
    </div>

      <div keys={prayers._id} style={{marginBottom:20}}>
      <input type="text" name={"Prayercomment" + prayers._id}  className={classes.CommentInpt} style={{width:1012,marginLeft:48,marginBottom:5}} disabled={isSubmitting}
      onKeyDown={handleKeyDown} maxLength={minLength}  value={comments[prayers._id] ||''}
      onChange={(e) => HandleInput(e, prayers._id)}  onClick={() => handleClick(prayers._id,'Coment')} placeholder="Write a comment..."  />
      
      <button className={classes.PostBtn} style={{marginBottom:6, marginLeft:4}} name={"submit" + prayers._id} onClick={()=>makeComment()} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'POST'}</button>
      <p style={{marginLeft:50,marginBottom:2,color:'white',opacity:0.65}}> Character Count: {comments[prayers._id]?.length || 0}</p>
      </div>
      </div>
       ):
       <div id= {classes.UPrayer} className={'mt-3 mb-4 bg-image'} prayerId={prayers._id} >
            <div>


            <div className={'col'}>
           
            <img src={prayers.profileImageUrl || nonProfilPic} style={{width:46,height:43 , borderRadius:50, marginTop:10}} className={'justify-content-between'} />
            
            <div className="ms-4">{prayers.userId && prayers.userId.username && (<p style={{fontFamily:'calibri',fontSize:16,fontWeight:700,color:'white',marginTop:-43,marginLeft:27}}>{prayers.userId.username}</p>
) }
{new Date(prayers.createdAt) instanceof Date && !isNaN(new Date(prayers.createdAt)) ? (
    <span style={{fontFamily:'calibri',fontSize:14,fontWeight:600,color:'white',opacity:0.3,marginLeft:27,position:'relative',top:-20}}>
      {new Date(prayers.createdAt).toLocaleString(undefined, dateOptions)}
    </span>
  ) : (
    <span>Invalid Date</span>
  )}</div>


          </div>
          <div className={'col mt-4 ms-5'}>
            <SaveP prayerId={prayers._id}/>
          </div>
            </div>
          
           <div className="mt-5" style={{marginLeft:-17}}>
             <MainHead style={{marginLeft:18}}>{prayers.prayerTitle} </MainHead>
              <Link to={`/singlepost/${prayers._id}`} style={{ textDecoration: 'none' }}><UPText className={'justify-content-center'} style={{marginLeft:18}}>{prayers.prayerdesc}</UPText> </Link> 
              <img src={prayers.imageUrl} style={{width:500}} className="d-block mx-auto mt-5" />
         </div>
      <div className={'col mt-4'} id={settings.optionP}>
                <div style={{marginLeft:-50}}>
                    <Response style={{marginLeft:-100}} className={classes.clickable} prayerId={prayers._id} likes={prayers.likes} updatedLikesCount={updatedLikesCount} />
                    <p className={icon.IconF}style={{marginLeft:139, marginTop:-43, color:"white", fontFamily: "Calibri", opacity: 0.5,}}>{prayers.likes.length}</p>
                </div>
                <div>
      
      <button className="btn bg-transparent" id={classes.ViewComment} onClick={() => handleClick(prayers._id,'viewC')} ><Comment/> <p style={{position:"relative", left:-3, top:-29, color:"white", opacity:0.50}}>{prayers.Comments.length}</p></button> 
   
      </div>
      
      <div>
          <Share style={{marginLeft:-55}} page="PrayerForr"/> 
        </div>
    </div>
   
        
     <div key={prayers._id} style={{marginBottom:20}}>
      <input type="text" className={classes.CommentInpt} name={"Prayercomment" + prayers._id} disabled={isSubmitting} style={{width:1012,marginLeft:48,marginBottom:5}} 
      onKeyDown={handleKeyDown} maxLength={minLength}   value={comments[prayers._id] ||''}
      onChange={(e) => HandleInput(e, prayers._id)} onClick={() => handleClick(prayers._id,'Coment')}  placeholder="Write a comment..." />
      
      <button className={classes.PostBtn} style={{marginBottom:6, marginLeft:4}} name={"submit" + prayers._id} onClick={()=>makeComment()} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'POST'}</button>
      <p style={{marginLeft:50,marginBottom:2,color:'white',opacity:0.65}}> Character Count: {comments[prayers._id]?.length || 0}</p>
      </div>
      </div>
        ))
      
      }
     
      
    
    </div>
    ): (
   
      <p>no more records found </p>
    )}
  {isModalOpened && (
                <Modal isOpened={isModalOpened} onClose={() => setIsModalOpened(false)}>
                 
                       <PrayerReq prayerId={selectedPostId}/>
                </Modal>
            )}
  
  </div>
  </InfiniteScroll>
)

}
export default memo(Prayers);
//https://www.youtube.com/watch?v=Dx4IeaL3oHA
//https://www.youtube.com/watch?v=T5aQWFVfeCs     //https://www.youtube.com/watch?v=B3pApL0Sey8