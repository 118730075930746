import React,{useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PrayerHead from "../../Logo/PrayerHead";
import MainHeader from "../../MainHeader/MainHeader";
import classes from '../../Registerpge/WBackground.module.css';
import MakeStory from "../prayerStory/MakeStory";
import LeftMenu from "../prayerStory/LeftMenu";
import axios from "axios";


const headers = {'Authorization': `Bearer ${localStorage.getItem('token')}`};
console.log("token in Reel",localStorage.getItem('token'));
const MakeStories = () =>{
    const [selectedUserId, setSelectedUserId] = useState(null);
    
    const [reels, setReels] = useState([]);
    const handleUserSelect = (userId) => {
        setSelectedUserId(userId);
  
        fetchReelsByUserId(userId);
      };
      const fetchReelsByUserId = async (userId) => {
        try {
          const response = await axios(`${process.env.REACT_APP_API_HOST}/reel/myreels/${userId}`,headers);
          setReels(response.data.reels);
        } catch (error) {
          console.error('Error fetching reels:', error);
        }
        console.log(reels);
      };

return(
    <div id={classes.Backgrnd} className='bg-image'>
       <PrayerHead/>
    <div>
        <MainHeader page="PrayerFor"/>
    </div>
    {/* <ApiProviders> */}
<div className='d-flex mt-5 justify-content-left' style={{marginLeft:250}} >
 
    <LeftMenu onUserSelect={handleUserSelect} />
  
    
</div>
<div className='d-flex justify-content-center'>

    <MakeStory reels={reels} userId={selectedUserId}/>
   
</div>

</div>
)
}

export default MakeStories;