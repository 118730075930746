 import classes from '../Notification.module.css';
 import React,{useState,useEffect} from 'react';
 import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import config from '../../../config';
import axios from 'axios';
const apiUrl = config();

 const ResponseN = ({newsid}) =>{

  const [isLiked, setIsLiked] = useState(() => {
  
    return localStorage.getItem(`liked_${newsid}`) === 'true';
  });
  useEffect(() => {
    // Update local storage when isLiked changes
    localStorage.setItem(`liked_${newsid}`, isLiked.toString());
  }, [isLiked, newsid]);

  const urlLke= `${process.env.REACT_APP_API_HOST}/news/like/${newsid}`;
  const urlunLke= `${process.env.REACT_APP_API_HOST}/news/unlike/${newsid}`;
  
  const fetchlike = async (newsid) => {
    try {
    
      const result = await axios.put(urlLke);
      
      localStorage.setItem(`liked_${newsid}`, 'true');
      setIsLiked(true);
 
      console.log(result);
      //setlikeData(newData);
      
    } catch (err) {
      console.log(err);
    }
  }
  const fetchUnlike = async (newsid) => {
    try {

       const result =  await axios.put(urlunLke);
       
       localStorage.setItem(`liked_${newsid}`, 'false');
    
        setIsLiked(false);
        console.log(result);
  
        
      } catch (err) {
        console.log(err);
      }
  };

  const handleToggle = async () => {
    // setisLiked(!isLiked);
    try {
      if (isLiked) {
        await fetchUnlike(newsid);
      
      } else {
        await fetchlike(newsid);
        
      }

    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <div className='mb-3'>
      
      <div className={isLiked ? classes.peplefilled: classes.peopleb} style={{ marginLeft:80}} alt="" onClick={handleToggle} id={classes.clickable}/>
    </div>
  );
     

 }
 export default ResponseN;