import React,{useState,useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useNavigate } from "react-router-dom";
import ButtonT from "../../UI/Button/ButtonT";
import classess from '../../Registerpge/Registerpge.module.css';
import {Form} from 'react-bootstrap';
import axios from 'axios';
import { useForm } from "react-hook-form";
import NetworkStatus from "../../Registerpge/NetworkStatus";



const headers = {'Authorization': `Bearer ${localStorage.getItem('token')}`};

const UpdatedPrayer = ({prayerid})=>{
  console.log("Received Prayer Id:", prayerid);
    const { handleSubmit, register, formState: { errors },setValue} = useForm();

    const url2 = `${process.env.REACT_APP_API_HOST}/prayers/${prayerid}`;

   const navigate = useNavigate();
   const [prayerTitle,setprayerTitle]= useState('');
   const [prayerdesc,setPrayerReq]= useState('');
   const [errorMsg , setErrorMsg]= useState(''); 
   const [image,setImage] = useState('');
   const [prayerData,setPrayerData] = useState(null);
   const [groupdata,setGroupData] = useState([]);
   const [isChecked, setIsChecked] = useState(false);
   const [networkError, setNetworkError] = useState(false);
   

   useEffect(() => {
    console.log("prayerid updated p", prayerid);

    const url= `${process.env.REACT_APP_API_HOST}/groups/all`;
    
    const fetchGroups = async () => {
      try {
        const groupData = await axios.get(url,headers);
        setGroupData(groupData);
      } catch (err) {}
    };

    fetchGroups();
    
  }, []);
  

  const onSubmit = async (data,e) => {
    try {
     await SubmitForm(data,e);
      console.log("data",data)
    } catch (e) {
      console.error(e);
    }
  }
  const SubmitForm = async(data,e) =>{
        e.preventDefault();
        if (!navigator.onLine) {
          setNetworkError(true);
          return;
          }

  const urlup = `${process.env.REACT_APP_API_HOST}/prayers/update/${prayerid}`;
        console.log("API URL:",urlup);
        console.log("test updated prayr sent prayer request send ");
        console.log(localStorage.getItem("user-id"));

        try{
          const formData = new FormData();
          setNetworkError(false)
          if (data.prayerTitle !== prayerData.prayerTitle) {
            formData.append('prayerTitle', data.prayerTitle);
        } else {
            formData.append('prayerTitle', prayerData.prayerTitle);
        }

        if (data.prayerdesc !== prayerData.prayerdesc) {
            formData.append('prayerdesc', data.prayerdesc);
        } else {
            formData.append('prayerdesc', prayerData.prayerdesc);
        }

        formData.append('favPrayer', prayerData.favPrayer || false);

        if (data.prayerPriority !== prayerData.prayerPriority) {
            formData.append('prayerPriority', data.prayerPriority);
        } else {
            formData.append('prayerPriority', prayerData.prayerPriority);
        }

        formData.append('prayerTypes', prayerData.prayerTypes || 'prayer');

        if (data.image[0]) {
            formData.append('image', data.image[0]);
        } else {
            formData.append('image', prayerData.image);
        }

        if (data.prayergroup !== prayerData.prayergroup) {
            formData.append('prayergroup', data.prayergroup);
        } else {
            formData.append('prayergroup', prayerData.prayergroup);
        }
          formData.append('userId', localStorage.getItem("user-id").toString());
          let prayerStatus = isChecked ? 'awaiting' : 'approved'; // Set to 'awaiting' if prayerPublic is true
          formData.append('prayerStatus', prayerStatus);

         const config = {
          headers:{
              "content-type":"multipart/form-data",      
          }
      }
          const response = await axios.put(urlup,formData, config).then()
     
          console.log("Update API Return" +response);  
          navigate("/Main/SavedPrayer");      
        }catch(err){
          setNetworkError(true);
          console.log(err);
        }
        };
        useEffect(()=>{
          const fetchPrayerData = async () => {
            try {
              const prayerData = await axios.get(url2,headers);
              const img = prayerData.data.prayer.image;
              console.log("img",img);
              setPrayerData(prayerData.data.prayer);
  
            }catch(err){
              console.log("error",err);
            }
          }
          fetchPrayerData();

        },[])
     
        useEffect(() => {
          if (prayerData) {
              setValue('prayerTitle',prayerData.prayerTitle);
              setValue('prayerdesc', prayerData.prayerdesc);
              setValue('prayerPublic',prayerData.prayerPublic);
              setValue('prayerPriority', prayerData.prayerPriority);
              setValue('prayerTypes', prayerData.prayerTypes);
              setValue('image', prayerData.image);
              setValue('prayergroup',prayerData.prayergroup);
              setValue('prayerStatus',prayerData.prayerStatus);
          }
      }, [prayerData,setValue]);
  
       const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= maxLength) {
          setPrayerReq(inputValue);
        }
      };
      const maxLength = 500;
      const minLength = 100;
      const handleKeyDown = (e) => {
        if (e.ctrlKey && e.keyCode === 13) {
          e.preventDefault();
    
          const position = e.target.selectionEnd;
          const value = e.target.value;
          const newValue = value.substring(0, position) + '\n' + value.substring(position);
          e.target.value = newValue;
  
          e.target.selectionStart = e.target.selectionEnd = position + 1;
        }
      };
      const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        setIsChecked(checked);
    }; 
    
    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      const maxSize = 1.5 * 1024 * 1024; // 2.5 MB
    
      if (file && file.size > maxSize) {
        setErrorMsg(`The selected image exceeds the 1.5 MB size limit.`);
         
        return;
      }
      setImage(file);
      setErrorMsg('');
    } 
    
    return(
        
       
    <div className="col">
       <div className={`modal-header sticky-top`} style ={{position:"sticky",backgroundColor: "#f8f9fa"}}>
               <h4 className="modal-title" style ={{marginLeft:330}} >Update Prayer</h4>
           </div>
           <div className={`modal-body `} style ={{maxHeight: 489, overflowY: "auto"}}>
           <NetworkStatus  networkError={networkError} />
    <form method="PUT" onSubmit={handleSubmit(onSubmit)}>
    
    <Form.Group className="mt-4">

      <Form.Label id={classess.lablle}>Where do you need a Prayer for? </Form.Label>
      <Form.Control 
         as="textarea"
         id="tb"
          className={classess.txtarea} //"form-control" 
          
          aria-describedby="prayerReqSend" 
          placeholder="Describe in 30 words what you would like to pray"
          name="prayerdesc"
          {...register("prayerdesc")}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
       
          />
            <p style={{marginLeft:5}}>
        Character Count: {prayerdesc.length}/{maxLength}
      </p>
      
  </Form.Group>
  <Form.Group className="mt-4">

      <Form.Label id={classess.lablle}>Prayer Title:</Form.Label>
      <Form.Control 
          type="input" 
          className="form-control" 
          id={classess.input} 
          aria-describedby="prayerTitle" 
          placeholder="Add prayer title"
          name="prayerTitle"
          {...register("prayerTitle")}
          onKeyDown={handleKeyDown}
          maxLength={minLength}
          onChange={event=>setprayerTitle(event.target.value)}
        
          />
         <p style={{marginLeft:5}}>
        Character Count: {prayerTitle.length}/{minLength}
        
      </p>
     
      
  </Form.Group>

    <Form.Group> 
      <Form.Label id={classess.lablle} className="mt-4"> Prayer Priority: </Form.Label>
               <select  style ={{width:910,borderRadius:4,backgroundColor:'white'}} id={classess.input}  
                name="prayerPriority"
                {...register("prayerPriority")}
      >
                    <option value="Urgent">Urgent</option>
                    <option value="Normal">Normal</option>
           
   </select>
   
    </Form.Group>
   
  <Form.Group>
       <Form.Label id={classess.lablle} className="mt-4">Do you want to add a picture: </Form.Label>
       <Form.Control 
       type="file" 
       className="form-control" 
       id={classess.fileUpload} 
       name="image"
       {...register("image")}
       aria-describedby="FileUpload" 
       placeholder="Choose your Picture"
       onChange={handleFileInputChange}
       />
       <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errorMsg && <span>{errorMsg}</span>}</span>
    </Form.Group>

    <Form.Group> 
      <Form.Label id={classess.lablle} className="mt-4"> Share in Group: </Form.Label>
   <select
   id={classess.input}
    name = "prayergroup"
    {...register("prayergroup")}
 
   style = {{width:910,borderRadius:4,backgroundColor:'white'}}
   
   >
         {
             groupdata.data !== undefined && groupdata.data.data.length > 0 && (
               groupdata.data.data.map(group =>(   
                    <option key={group._id}>{group.groupName}</option>
            )
            ) )
           }
   </select>
  
    </Form.Group>

       
        <Form.Group>
          <div style ={{position:"relative", left:62}}>
           <ButtonT type="submit" name="updatePrayerReq"  value="Submit" >Submit</ButtonT>
           </div>
        </Form.Group> 
    </form>
    </div >
    <div className={`modal-footer`} style ={{position:"sticky", backgroundColor: "#f8f9fa", zIndex: 999,justifyContent:'center', fontFamily: 'Calibri'}}>
               
              </div> 
    </div>
    

    )
}
export default UpdatedPrayer;