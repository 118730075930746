import React, {useState, createContext, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();


 function AuthContextProvider(props) {
    const [loggedIn,setLoggedIn] = useState(false);
    console.log("Logged In ",loggedIn);
    async function getLoggedIn(){
      try{
        const loggedInRes = await axios.get(
            `${process.env.REACT_APP_API_HOST}/members/loggedin`);
             setLoggedIn(loggedInRes.data);
             console.log("logged In API",loggedInRes.data)
             //console.log("Logged In ",loggedIn);
      }catch(err){
        console.log(err);
      } 
    }
    useEffect(()=>{
        getLoggedIn();
    },[]);

    return(
       <AuthContext.Provider value={{loggedIn, getLoggedIn}}>
         {props.children}
       </AuthContext.Provider>
    );
}

export default AuthContext;
export {AuthContextProvider};