import React,{useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ButtonT from "../UI/Button/ButtonT";
import classes from '../Registerpge/Registerpge.module.css';
import MainHead from "../UI/MainHead/MainHead";
import EmailText from "../UI/EmailTxt/InstructionTxt";
import {Form} from 'react-bootstrap';
import Logo from "../Logo/Logo";
import SecureConnection from "../UI/secConnection/SecureConnection";
import config from "../../config";
//import MainHead from "../UI/MainHead/MainHead";
const apiUrl = config();

const ForgotPassword = () =>{
const [msg,setMsg] = useState('');
  const [user,setUser] = useState({
    email: "" , 
   
  }); 
const {email} = user;
  let name,value;
const handleInputs = (e)  =>{
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({...user,[name]:value});
  }
const onSubmit = async(e) =>{
    e.preventDefault();
    
    try{
      const res = await fetch(`${process.env.REACT_APP_API_HOST}/members/forgetpassword`,{
        method:"POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email:email,
        }),
       
       });
       setUser({
        email: "",
       
      });
      //console.log(res.msg);
    const data = await res.json();
    setMsg(data.msg);
    console.log(data.msg);
    
    }catch(err){
    console.error("error",err.response.data);
    }
    };
return(
  <div>
<Logo/>
<EmailText><br/></EmailText>

<div style={{marginLeft:47,fontWeight:600}}>
<MainHead>Forget Password</MainHead>
</div>
<div className="d-flex align-item-start">

<form method="POST" onSubmit={(e)=>onSubmit(e)}>

<Form.Group>
      <Form.Label id={classes.label}>Email </Form.Label>
      <span className={classes.icon} id={classes.icnEml} />
      <Form.Control
      type="email" 
      id="email"
      className={classes.input}
      aria-describedby="emailHelp" 
      placeholder="helen@gmail.com"
      name="email"
      value={email}
      onChange={handleInputs}
       />
       {msg && <span style={{color:'red',fontFamily:'calibri',fontSize:16,position:'relative',left:120,top:-30}} className="mx-auto">{msg}</span>}
</Form.Group>
     <Form.Group>
       <ButtonT type="submit" name="ForgetPassword" value="submit">Send</ButtonT>
    </Form.Group>
    
</form>
</div>
<br/>
<br/>
<br/>
<br/>


<SecureConnection/>
</div>
   
    

)

}
export default ForgotPassword;