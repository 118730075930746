// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailText_account__uDNri {\n   \n    font-size: 16px;\n    font-family: ' Calibri';\n    font-style: normal;\n    font-weight: 400;\n    font-size:16px;\n    line-height: 24px;\n    color: #ffffff;\n    \n}", "",{"version":3,"sources":["webpack://./src/Components/UI/EmailTxt/EmailText.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,cAAc;;AAElB","sourcesContent":[".account {\n   \n    font-size: 16px;\n    font-family: ' Calibri';\n    font-style: normal;\n    font-weight: 400;\n    font-size:16px;\n    line-height: 24px;\n    color: #ffffff;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"account": "EmailText_account__uDNri"
};
export default ___CSS_LOADER_EXPORT___;
