import React,{useState} from "react";
import {useNavigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ButtonT from "../UI/Button/ButtonT";
import classes from '../Registerpge/Registerpge.module.css';
import MainHead from "../UI/MainHead/MainHead";
import EmailText from "../UI/EmailTxt/InstructionTxt";
import { useParams } from "react-router-dom";
import {Form} from 'react-bootstrap';
import Logo from "../Logo/Logo";
import axios from "axios";
import config from "../../config";
import SecureConnection from "../UI/secConnection/SecureConnection";
// import MainHead from "../UI/MainHead/MainHead";

const apiUrl= config();

const ResetPassword =()=>{
    const {id, token} = useParams();
    console.log("id" +id ,"token" +token);
    const navigate = useNavigate();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [ChpasswordVisible, setChPasswordVisible] = useState(false);
    const [user,setUser] = useState({ 
        password:"",
        ConfirmPassword:""
      }); 
     
 
 const { password,ConfirmPassword} = user;
      
    let name,value;
 
 const handleInputs = (e)  =>{
        console.log(e);
        name = e.target.name;
        value = e.target.value;
        setUser({...user,[name]:value});
      
        }
const togglePasswordVisibility = () => {
         setPasswordVisible(!passwordVisible);
   };
   const toggleChPasswordVisibility = () => {
      setChPasswordVisible(!ChpasswordVisible);
    };
        const onSubmit = async(e) =>{
               e.preventDefault();
               const url =`${process.env.REACT_APP_API_HOST}/members/resetpassword/${id}/${token}`;
               try{
                 const respnsedata = await axios.post(url,{ password, ConfirmPassword });
                  setUser(respnsedata);
                  console.log(respnsedata);
                  navigate("/");
                  }
              
               
               catch(err){
               console.error("error Resetpassword",err);
               }
               
               };
     
    return(
<div>
        <Logo/>
<EmailText>Your New Password must be different from previously used password </EmailText>
<div className="align-item-start">

<form method="POST" onSubmit={(e)=>onSubmit(e)}>

 <div style={{marginLeft:48,fontWeight:600}}>
    <MainHead>Create New Password</MainHead>
  </div>
 
  <Form.Group>
     
       <Form.Label id={classes.label}>Password </Form.Label>
       <span className={classes.icon} style={{marginTop:400}} id={classes.icnPw}/>
       <div style={{ position: 'relative' }}>
       <Form.Control 
      type={passwordVisible ? "text" : "password"} 
       className={classes.input} 
       id="password" 
       aria-describedby="passwordHelp" 
       placeholder="********"
       name="password"
       value={password}
       onChange={handleInputs}
       />
          <span
                        onClick={togglePasswordVisibility}
                        style={{
                            position: 'absolute',
                            top: '30%',
                            right: '1005px',
                            cursor: 'pointer',
                            color:"white"
                        }}
              >
                        {passwordVisible ? 'Hide' : 'Show'}
                    </span>
                    </div>
    
</Form.Group>
<Form.Group>
     
       <Form.Label id={classes.label}>Password </Form.Label>
       <span className={classes.icon} style={{marginTop:505}} id={classes.icnPw}/>
       <div style={{ position: 'relative' }}>
       <Form.Control 
       type={ChpasswordVisible ? "text" : "password"}
       className={classes.input} 
       id="confirmpassword" 
       aria-describedby="passwordHelp" 
       placeholder="********"
       name="ConfirmPassword"
       value={ConfirmPassword}
       onChange={handleInputs}
       />
          <span
                        onClick={toggleChPasswordVisibility }
                        style={{
                            position: 'absolute',
                            top: '30%',
                            right: '1005px',
                            cursor: 'pointer',
                            color:"white"
                        }}
              >
                        {ChpasswordVisible ? 'Hide' : 'Show'}
                    </span>
                    </div>
</Form.Group>

    <Form.Group>
       <ButtonT type="submit" name="resetpw" value="submit">Save</ButtonT>
    </Form.Group>
    
</form>
</div>
<SecureConnection/>
</div>
)

}
export default ResetPassword;