import classes from './ConfirmModal.module.css';
const ConfirmModal = ({ isOpen, onClose, onConfirm, message }) =>{
    if (!isOpen) return null;

    return (
      <div className={classes.overlay}>
        <div className={classes.modal}>
          <p>{message}</p>
          <div className={classes.buttons}>
            <button onClick={onConfirm}>Yes</button>
            <button onClick={onClose}>No</button>
          </div>
        </div>
      </div>
    );
}
export default ConfirmModal;