//import {Container,Row,Col} from 'react-bootstrap'; 
import classes from './haveAccount.module.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
// import SignUpForm from '../SignupFP/SignUpForm';
// import RegisterPge from '../Registerpge/RegisterPge';
const HaveAccount = ({togglePage,isSignUp}) => {
  
  //   const [isSignUp, setIsSignUp] = useState(true);

  // const toggleSignUp = () => {
  //   setIsSignUp(!isSignUp);
  // };

  return (
    <div className="d-flex align-items-center justify-content-center">
      {isSignUp ? (
        <>
          {/* Render signup page content */}
          <p className={classes.account}>
            Already have an account?{' '}
            <NavLink to="/" onClick={() => togglePage(false)} style={{color:"#3a3838"}}>
              Sign in 
            </NavLink>
          </p>
        </>
      ) : (
        <>
          {/* Render login page content */}
          <p className={classes.account}>
            Don't have an account?{' '}
            <NavLink to="/signup" onClick={() => togglePage(true)} style={{color:"#3a3838"}}>
              Sign up here
            </NavLink>
          </p>
        </>
      )}

    </div>
  );
};

  

export default HaveAccount;