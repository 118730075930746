// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#Logo_logoPW__xZX9J{\n    background-Image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    width: 196px;\n    height: 249px;\n    margin-top: 8px;;\n}\n\n#Logo_setLogo__6Jn5L{\n    vertical-align: middle;\n \n    \n}", "",{"version":3,"sources":["webpack://./src/Components/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;IACI,wDAAwC;IACxC,4BAA4B;IAC5B,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,sBAAsB;;;AAG1B","sourcesContent":["#logoPW{\n    background-Image:url(../images/logo.png);\n    background-repeat: no-repeat;\n    width: 196px;\n    height: 249px;\n    margin-top: 8px;;\n}\n\n#setLogo{\n    vertical-align: middle;\n \n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoPW": "Logo_logoPW__xZX9J",
	"setLogo": "Logo_setLogo__6Jn5L"
};
export default ___CSS_LOADER_EXPORT___;
