// import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import TopHeader from "../Logo/TopHeader";
import PrayerSign from "./PrayerSign";
import PrayerTypes from "../PrayerType/PrayerTypes";
import {socket} from '../../index';
import React,{useEffect} from "react";
import { useBackground } from '../../context/BackgroundContext';
//import { isTokenExpired } from '../../auth';
 //import classes from '../Registerpge/WBackground.module.css';
const Main = () =>{
  const { setBackgroundVisibility } = useBackground();

  useEffect(() => {
    setBackgroundVisibility(false);
    return () => setBackgroundVisibility(true); // Re-enable background on unmount
  }, [setBackgroundVisibility]);

    useEffect(() => {
        const handleReconnect = () => {
          console.log('Socket reconnected');
        };
    
        const handleReconnectAttempt = (attemptNumber) => {
          console.log(`Reconnecting attempt ${attemptNumber}`);
        };
    
        socket.on('reconnect', handleReconnect);
        socket.on('reconnect_attempt', handleReconnectAttempt);
    
        return () => {
          socket.off('reconnect', handleReconnect);
          socket.off('reconnect_attempt', handleReconnectAttempt);
        };
      }, []);
  //     const token = localStorage.getItem('token');
  // if (!token || isTokenExpired(token)) {
  //     navigate("/");
  //     return;
  // }
return(

<div>
<div>
<TopHeader/>
</div>
<div>
<PrayerTypes/>
</div>
<div>
<PrayerSign/>
</div>

</div>
)

}
export default Main;