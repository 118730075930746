import React,{ useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import classses from  './WBackground.module.css';
import PrayerHead from "../Logo/PrayerHead";
import config from "../../config";
import axios from "axios";
import classes from '../Registerpge/Registerpge.module.css';
import { S3Client } from '@aws-sdk/client-s3';
import NetworkStatus from './NetworkStatus';

const headers = {'Authorization': `Bearer ${localStorage.getItem('token')}`};
 const country = localStorage.getItem("country");

const key = 'DO00PFUZFUPP9QY8YX4F';
const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
const spaceName = 'prayer-weaver-files';
const region = 'ams3';
const host = `${spaceName}.${region}.digitaloceanspaces.com`;

const client = new S3Client({
  region,
  endpoint: `https://${host}`,
  credentials: {
    accessKeyId: key,
    secretAccessKey: secret,
  },
  bucketEndpoint: true,
 
});


const Profille = () =>{
  const [username, setUsername] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [firstName , setFirstName] = useState('');

 useEffect(() => {
    setUsername(localStorage.getItem('user-name') || '');
    setProfilePic(localStorage.getItem('user-image') || '');
    setFirstName(localStorage.getItem("first-name")|| '')
  }, []);
   const imgUrl = `https://${host}/member/${profilePic}`;
    const { handleSubmit, register, formState: { errors }, setValue} = useForm();
    const navigate = useNavigate();
    const [errorMsg , setErrorMsg]= useState('');
    const [isPrayerWarrier, setIsPrayerWarrier] = useState(false);
    const [addpic,setAddPic] = useState ("");
    const [userData, setUserData] = useState(null);
    const [networkError, setNetworkError] = useState(false);

    useEffect(() => {
      const url= `${process.env.REACT_APP_API_HOST}/members/${localStorage.getItem("user-id")}`;
      const fetchUserData = async () => {
        try {
          const userrData = await axios.get(url,headers);
          setUserData(userrData.data.singleUser);
         } catch (err) {}
           };
           fetchUserData();
      
    }, []);
  const onSubmit = async (data,e) => {
    try {
      await handleSubmit(SubmitForm)(data,e);
    } catch (e) {
      console.error(e);
    }
  };

       
            const SubmitForm = async(data,e) =>{
                e.preventDefault();

                if (!navigator.onLine) {
                  setNetworkError(true);
                  return;
                  }

                try{
                  const formData = new FormData();
                  formData.append('isPrayerWarrier',isPrayerWarrier);
                  formData.append('ChurchAffiliation',data.ChurchAffiliation);
                  formData.append('Country',data.Country);
                  formData.append('AddPic',addpic);
                  formData.append('Address',data.Address);
                  formData.append('City',data.City);
                  formData.append('LandMark',data.LandMark);
                  formData.append('Pincode',data.Pincode);
       
            const response =  await axios.put(
                    `${process.env.REACT_APP_API_HOST}/members/update/profile/${localStorage.getItem("user-id")}`,
                    formData,
                     {
                      headers: {
                        "content-type":"multipart/form-data",
                      },
                      
                    }
                     );
                     setNetworkError(false);
                     localStorage.setItem('user-image',response.data.user.AddPic);
          
                     alert('Prfile is updated');
                     navigate("/main")
                  
                }catch(error){
                  setNetworkError(true);
                    console.log(error);
                   
               
                }
                };
         useEffect(() => {
                  if (userData) {
                      setValue('AddPic',userData.AddPic);
                      setValue('Country', userData.Country);
                      setValue('ChurchAffiliation', userData.ChurchAffiliation);
                      setValue('isPrayerWarrier', userData.isPrayerWarrier);
                      setValue('Address', userData.Address);
                      setValue('Pincode', userData.Pincode);
                      setValue('LandMark', userData.LandMark);
                      setValue('City', userData.City);
                  }
              }, [userData, setValue]);

              const handleFileInputChange = (event) => {
                const file = event.target.files[0];
                const maxSize = 1.5 * 1024 * 1024; // 2.5 MB
              
                if (file && file.size > maxSize) {
                  setErrorMsg(`The selected image exceeds the 1.5 MB size limit.`);
                   
                  return;
                }
                setAddPic(file);
                setErrorMsg('');
              }
return(
<div id={classses.Backgrnd} className='bg-image'>
<PrayerHead/>
    <div className="mt-5"/>
    <NetworkStatus  networkError={networkError} />
       <Row>
    <Col md={2}>
          <Row>
        <div>
         
        <img src={imgUrl} style={{width: 150, height: 150, marginTop:-30, marginLeft:70, borderRadius:100, }}  />
         
        </div>
      </Row>
    </Col>
    <Col md={6}>
    <Row>
    <h5 style={{marginTop:-20,fontWeight:600}}>{username}</h5>
    <h6 style={{marginLeft:2, marginTop: -13 }}>{firstName}</h6>
    </Row>
      <Form method="PUT" onSubmit={onSubmit}>
        <Row className="mb-3 mt-3">
          <Form.Label id={classes.lblle} className='left-side' column md={4}>Edit picture</Form.Label>
          <Col md={8}>
            <Form.Control 
            type="file" 
            id= "AddPic"
            name="AddPic"
            aria-describedby="FileUpload" 
            placeholder="Choose your Picture"
            {...register("AddPic")}
            accept=".jpg .png .jpeg .gif"
            onChange={handleFileInputChange}
             />
              <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errorMsg && <span>{errorMsg}</span>}</span>
          </Col>
        </Row>

        <Row className="mb-3">
        <Form.Label id={classes.lblle} column md={4}>Country</Form.Label>
          <Col md={8}>
        <Form.Select     name="Country" aria-label="Country" {...register("Country",{ required: true })}>
        <option value="Afghanistan" className={classes.bg}>Afghanistan</option>
                <option value="Åland Islands" className={classes.bg}>Åland Islands</option>
                <option value="Albania" className={classes.bg}>Albania</option>
                <option value="Algeria" className={classes.bg}>Algeria</option>
                <option value="American Samoa" className={classes.bg}>American Samoa</option>
                <option value="Andorra" className={classes.bg}>Andorra</option>
                <option value="Angola" className={classes.bg}>Angola</option>
                <option value="Anguilla" className={classes.bg}>Anguilla</option>
                <option value="Antarctica" className={classes.bg}>Antarctica</option>
                <option value="Antigua and Barbuda" className={classes.bg}>Antigua and Barbuda</option>
                <option value="Argentina" className={classes.bg}>Argentina</option>
                <option value="Armenia" className={classes.bg}>Armenia</option>
                <option value="Aruba" className={classes.bg}>Aruba</option>
                <option value="Australia" className={classes.bg} >Australia</option>
                <option value="Austria" className={classes.bg}>Austria</option>
                <option value="Azerbaijan" className={classes.bg}>Azerbaijan</option>
                <option value="Bahamas" className={classes.bg}>Bahamas</option>
                <option value="Bahrain" className={classes.bg}>Bahrain</option>
                <option value="Bangladesh" className={classes.bg}>Bangladesh</option>
                <option value="Barbados" className={classes.bg}>Barbados</option>
                <option value="Belarus" className={classes.bg}>Belarus</option>
                <option value="Belgium" className={classes.bg}>Belgium</option>
                <option value="Belize" className={classes.bg}>Belize</option>
                <option value="Benin" className={classes.bg}>Benin</option>
                <option value="Bermuda" className={classes.bg}>Bermuda</option>
                <option value="Bhutan" className={classes.bg}>Bhutan</option>
                <option value="Bolivia" className={classes.bg}>Bolivia</option>
                <option value="Bosnia and Herzegovina" className={classes.bg}>Bosnia and Herzegovina</option>
                <option value="Botswana" className={classes.bg}>Botswana</option>
                <option value="Bouvet Island" className={classes.bg}>Bouvet Island</option>
                <option value="Brazil" className={classes.bg}>Brazil</option>
                <option value="British Indian Ocean Territory" className={classes.bg}>British Indian Ocean Territory</option>
                <option value="Brunei Darussalam" className={classes.bg}>Brunei Darussalam</option>
                <option value="Bulgaria" className={classes.bg}>Bulgaria</option>
                <option value="Burkina Faso" className={classes.bg}>Burkina Faso</option>
                <option value="Burundi" className={classes.bg}>Burundi</option>
                <option value="Cambodia" className={classes.bg}>Cambodia</option>
                <option value="Cameroon" className={classes.bg}>Cameroon</option>
                <option value="Canada" className={classes.bg}>Canada</option>
                <option value="Cape Verde" className={classes.bg}>Cape Verde</option>
                <option value="Cayman Islands" className={classes.bg}>Cayman Islands</option>
                <option value="Central African Republic" className={classes.bg}>Central African Republic</option>
                <option value="Chad" className={classes.bg}>Chad</option>
                <option value="Chile" className={classes.bg}>Chile</option>
                <option value="China" className={classes.bg}>China</option>
                <option value="Christmas Island" className={classes.bg}>Christmas Island</option>
                <option value="Cocos (Keeling) Islands" className={classes.bg}>Cocos (Keeling) Islands</option>
                <option value="Colombia" className={classes.bg}>Colombia</option>
                <option value="Comoros" className={classes.bg}>Comoros</option>
                <option value="Congo" className={classes.bg}>Congo</option>
                <option value="Congo, The Democratic Republic of The" className={classes.bg}>Congo, The Democratic Republic of The</option>
                <option value="Cook Islands" className={classes.bg}>Cook Islands</option>
                <option value="Costa Rica" className={classes.bg}>Costa Rica</option>
                <option value="Cote D'ivoire" className={classes.bg}>Cote D'ivoire</option>
                <option value="Croatia" className={classes.bg}>Croatia</option>
                <option value="Cuba" className={classes.bg}>Cuba</option>
                <option value="Cyprus" className={classes.bg}>Cyprus</option>
                <option value="Czech Republic" className={classes.bg}>Czech Republic</option>
                <option value="Denmark" className={classes.bg}>Denmark</option>
                <option value="Djibouti" className={classes.bg}>Djibouti</option>
                <option value="Dominica" className={classes.bg}>Dominica</option>
                <option value="Dominican Republic" className={classes.bg}>Dominican Republic</option>
                <option value="Ecuador" className={classes.bg}>Ecuador</option>
                <option value="Egypt" className={classes.bg}>Egypt</option>
                <option value="El Salvador" className={classes.bg}>El Salvador</option>
                <option value="Equatorial Guinea" className={classes.bg}>Equatorial Guinea</option>
                <option value="Eritrea" className={classes.bg}>Eritrea</option>
                <option value="Estonia" className={classes.bg}>Estonia</option>
                <option value="Ethiopia" className={classes.bg}>Ethiopia</option>
                <option value="Falkland Islands (Malvinas)" className={classes.bg}>Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands" className={classes.bg}>Faroe Islands</option>
                <option value="Fiji" className={classes.bg}>Fiji</option>
                <option value="Finland" className={classes.bg}>Finland</option>
                <option value="France" className={classes.bg}>France</option>
                <option value="French Guiana" className={classes.bg}>French Guiana</option>
                <option value="French Polynesia" className={classes.bg}>French Polynesia</option>
                <option value="French Southern Territories" className={classes.bg}>French Southern Territories</option>
                <option value="Gabon" className={classes.bg}>Gabon</option>
                <option value="Gambia" className={classes.bg}>Gambia</option>
                <option value="Georgia" className={classes.bg}>Georgia</option>
                <option value="Germany" className={classes.bg}>Germany</option>
                <option value="Ghana" className={classes.bg}>Ghana</option>
                <option value="Gibraltar" className={classes.bg}>Gibraltar</option>
                <option value="Greece" className={classes.bg}>Greece</option>
                <option value="Greenland" className={classes.bg}>Greenland</option>
                <option value="Grenada" className={classes.bg}>Grenada</option>
                <option value="Guadeloupe" className={classes.bg}>Guadeloupe</option>
                <option value="Guam" className={classes.bg}>Guam</option>
                <option value="Guatemala" className={classes.bg}>Guatemala</option>
                <option value="Guernsey" className={classes.bg}>Guernsey</option>
                <option value="Guinea" className={classes.bg}>Guinea</option>
                <option value="Guinea-bissau" className={classes.bg}>Guinea-bissau</option>
                <option value="Guyana" className={classes.bg}>Guyana</option>
                <option value="Haiti" className={classes.bg}>Haiti</option>
                <option value="Heard Island and Mcdonald Islands" className={classes.bg}>Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)" className={classes.bg}>Holy See (Vatican City State)</option>
                <option value="Honduras" className={classes.bg}>Honduras</option>
                <option value="Hong Kong" className={classes.bg}>Hong Kong</option>
                <option value="Hungary" className={classes.bg}>Hungary</option>
                <option value="Iceland" className={classes.bg}>Iceland</option>
                <option value="India" className={classes.bg}>India</option>
                <option value="Indonesia" className={classes.bg}>Indonesia</option>
                <option value="Iran, Islamic Republic of" className={classes.bg}>Iran, Islamic Republic of</option>
                <option value="Iraq" className={classes.bg}>Iraq</option>
                <option value="Ireland" className={classes.bg}>Ireland</option>
                <option value="Isle of Man" className={classes.bg}>Isle of Man</option>
                <option value="Israel" className={classes.bg}>Israel</option>
                <option value="Italy" className={classes.bg}>Italy</option>
                <option value="Jamaica" className={classes.bg}>Jamaica</option>
                <option value="Japan" className={classes.bg}>Japan</option>
                <option value="Jersey" className={classes.bg}>Jersey</option>
                <option value="Jordan" className={classes.bg}>Jordan</option>
                <option value="Kazakhstan" className={classes.bg}>Kazakhstan</option>
                <option value="Kenya" className={classes.bg}>Kenya</option>
                <option value="Kiribati" className={classes.bg}>Kiribati</option>
                <option value="Korea, Democratic People's Republic of" className={classes.bg}>Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of" className={classes.bg}>Korea, Republic of</option>
                <option value="Kuwait" className={classes.bg}>Kuwait</option>
                <option value="Kyrgyzstan" className={classes.bg}>Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic" className={classes.bg}>Lao People's Democratic Republic</option>
                <option value="Latvia" className={classes.bg}>Latvia</option>
                <option value="Lebanon" className={classes.bg}>Lebanon</option>
                <option value="Lesotho" className={classes.bg}>Lesotho</option>
                <option value="Liberia" className={classes.bg}>Liberia</option>
                <option value="Libyan Arab Jamahiriya" className={classes.bg}>Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein" className={classes.bg}>Liechtenstein</option>
                <option value="Lithuania" className={classes.bg}>Lithuania</option>
                <option value="Luxembourg" className={classes.bg}>Luxembourg</option>
                <option value="Macao" className={classes.bg}>Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of" className={classes.bg}>Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar" className={classes.bg}>Madagascar</option>
                <option value="Malawi" className={classes.bg}>Malawi</option>
                <option value="Malaysia" className={classes.bg}>Malaysia</option>
                <option value="Maldives" className={classes.bg}>Maldives</option>
                <option value="Mali" className={classes.bg}>Mali</option>
                <option value="Malta" className={classes.bg}>Malta</option>
                <option value="Marshall Islands" className={classes.bg}>Marshall Islands</option>
                <option value="Martinique" className={classes.bg}>Martinique</option>
                <option value="Mauritania" className={classes.bg}>Mauritania</option>
                <option value="Mauritius" className={classes.bg}>Mauritius</option>
                <option value="Mayotte" className={classes.bg}>Mayotte</option>
                <option value="Mexico" className={classes.bg}>Mexico</option>
                <option value="Micronesia, Federated States of" className={classes.bg}>Micronesia, Federated States of</option>
                <option value="Moldova, Republic of" className={classes.bg}>Moldova, Republic of</option>
                <option value="Monaco" className={classes.bg}>Monaco</option>
                <option value="Mongolia" className={classes.bg}>Mongolia</option>
                <option value="Montenegro" className={classes.bg}>Montenegro</option>
                <option value="Montserrat" className={classes.bg}>Montserrat</option>
                <option value="Morocco" className={classes.bg}>Morocco</option>
                <option value="Mozambique" className={classes.bg}>Mozambique</option>
                <option value="Myanmar" className={classes.bg}>Myanmar</option>
                <option value="Namibia" className={classes.bg}>Namibia</option>
                <option value="Nauru" className={classes.bg}>Nauru</option>
                <option value="Nepal" className={classes.bg}>Nepal</option>
                <option value="Netherlands" className={classes.bg}>Netherlands</option>
                <option value="Netherlands Antilles" className={classes.bg}>Netherlands Antilles</option>
                <option value="New Caledonia" className={classes.bg}>New Caledonia</option>
                <option value="New Zealand" className={classes.bg}>New Zealand</option>
                <option value="Nicaragua" className={classes.bg}>Nicaragua</option>
                <option value="Niger" className={classes.bg}>Niger</option>
                <option value="Nigeria" className={classes.bg}>Nigeria</option>
                <option value="Niue" className={classes.bg}>Niue</option>
                <option value="Norfolk Island" className={classes.bg}>Norfolk Island</option>
                <option value="Northern Mariana Islands" className={classes.bg}>Northern Mariana Islands</option>
                <option value="Norway" className={classes.bg}>Norway</option>
                <option value="Oman" className={classes.bg}>Oman</option>
                <option value="Pakistan" className={classes.bg}>Pakistan</option>
                <option value="Palau" className={classes.bg}>Palau</option>
                <option value="Palestinian Territory, Occupied" className={classes.bg}>Palestinian Territory, Occupied</option>
                <option value="Panama" className={classes.bg}>Panama</option>
                <option value="Papua New Guinea" className={classes.bg}>Papua New Guinea</option>
                <option value="Paraguay" className={classes.bg}>Paraguay</option>
                <option value="Peru" className={classes.bg}>Peru</option>
                <option value="Philippines" className={classes.bg}>Philippines</option>
                <option value="Pitcairn" className={classes.bg}>Pitcairn</option>
                <option value="Poland" className={classes.bg}>Poland</option>
                <option value="Portugal" className={classes.bg}>Portugal</option>
                <option value="Puerto Rico" className={classes.bg}>Puerto Rico</option>
                <option value="Qatar" className={classes.bg}>Qatar</option>
                <option value="Reunion" className={classes.bg}>Reunion</option>
                <option value="Romania" className={classes.bg}>Romania</option>
                <option value="Russian Federation" className={classes.bg}>Russian Federation</option>
                <option value="Rwanda" className={classes.bg}>Rwanda</option>
                <option value="Saint Helena" className={classes.bg}>Saint Helena</option>
                <option value="Saint Kitts and Nevis" className={classes.bg}>Saint Kitts and Nevis</option>
                <option value="Saint Lucia" className={classes.bg}>Saint Lucia</option>
                <option value="Saint Pierre and Miquelon" className={classes.bg}>Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines" className={classes.bg}>Saint Vincent and The Grenadines</option>
                <option value="Samoa" className={classes.bg}>Samoa</option>
                <option value="San Marino" className={classes.bg}>San Marino</option>
                <option value="Sao Tome and Principe" className={classes.bg}>Sao Tome and Principe</option>
                <option value="Saudi Arabia" className={classes.bg}>Saudi Arabia</option>
                <option value="Senegal" className={classes.bg}>Senegal</option>
                <option value="Serbia" className={classes.bg}>Serbia</option>
                <option value="Seychelles" className={classes.bg}>Seychelles</option>
                <option value="Sierra Leone" className={classes.bg}>Sierra Leone</option>
                <option value="Singapore" className={classes.bg}>Singapore</option>
                <option value="Slovakia" className={classes.bg}>Slovakia</option>
                <option value="Slovenia" className={classes.bg}>Slovenia</option>
                <option value="Solomon Islands" className={classes.bg}>Solomon Islands</option>
                <option value="Somalia" className={classes.bg}>Somalia</option>
                <option value="South Africa" className={classes.bg}>South Africa</option>
                <option value="South Georgia and The South Sandwich Islands" className={classes.bg}>South Georgia and The South Sandwich Islands</option>
                <option value="Spain" className={classes.bg}>Spain</option>
                <option value="Sri Lanka" className={classes.bg}>Sri Lanka</option>
                <option value="Sudan" className={classes.bg}>Sudan</option>
                <option value="Suriname" className={classes.bg}>Suriname</option>
                <option value="Svalbard and Jan Mayen" className={classes.bg}>Svalbard and Jan Mayen</option>
                <option value="Swaziland" className={classes.bg}>Swaziland</option>
                <option value="Sweden" className={classes.bg}>Sweden</option>
                <option value="Switzerland" className={classes.bg}>Switzerland</option>
                <option value="Syrian Arab Republic" className={classes.bg}>Syrian Arab Republic</option>
                <option value="Taiwan" className={classes.bg}>Taiwan</option>
                <option value="Tajikistan" className={classes.bg}>Tajikistan</option>
                <option value="Tanzania, United Republic of" className={classes.bg}>Tanzania, United Republic of</option>
                <option value="Thailand" className={classes.bg}>Thailand</option>
                <option value="Timor-leste" className={classes.bg}>Timor-leste</option>
                <option value="Togo" className={classes.bg}>Togo</option>
                <option value="Tokelau" className={classes.bg}>Tokelau</option>
                <option value="Tonga" className={classes.bg}>Tonga</option>
                <option value="Trinidad and Tobago" className={classes.bg}>Trinidad and Tobago</option>
                <option value="Tunisia" className={classes.bg}>Tunisia</option>
                <option value="Turkey" className={classes.bg}>Turkey</option>
                <option value="Turkmenistan" className={classes.bg}>Turkmenistan</option>
                <option value="Turks and Caicos Islands" className={classes.bg}>Turks and Caicos Islands</option>
                <option value="Tuvalu" className={classes.bg}>Tuvalu</option>
                <option value="Uganda" className={classes.bg}>Uganda</option>
                <option value="Ukraine" className={classes.bg}>Ukraine</option>
                <option value="United Arab Emirates" className={classes.bg}>United Arab Emirates</option>
                <option value="United Kingdom" className={classes.bg}>United Kingdom</option>
                <option value="United States" className={classes.bg}>United States</option>
                <option value="United States Minor Outlying Islands" className={classes.bg}>United States Minor Outlying Islands</option>
                <option value="Uruguay" className={classes.bg}>Uruguay</option>
                <option value="Uzbekistan" className={classes.bg}>Uzbekistan</option>
                <option value="Vanuatu" className={classes.bg}>Vanuatu</option>
                <option value="Venezuela" className={classes.bg}>Venezuela</option>
                <option value="Viet Nam" className={classes.bg}>Viet Nam</option>
                <option value="Virgin Islands, British" className={classes.bg}>Virgin Islands, British</option>
                <option value="Virgin Islands, U.S." className={classes.bg}>Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna" className={classes.bg}>Wallis and Futuna</option>
                <option value="Western Sahara" className={classes.bg}>Western Sahara</option>
                <option value="Yemen" className={classes.bg}>Yemen</option>
                <option value="Zambia" className={classes.bg}>Zambia</option>
                <option value="Zimbabwe" className={classes.bg}>Zimbabwe</option>
        </Form.Select>

       <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.Country && <span>Country is required</span>}</span>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Label id={classes.lblle} column md={4}>Select Church Affiliation</Form.Label>
          <Col md={8}>
            <Form.Select  placeholder="Catholic"    name="ChurchAffiliation" aria-label="Default select example" {...register("ChurchAffiliation", { required: true })}>
         
               <option value="Catholic" className={classes.bg}>Catholic</option>
               <option value="Protestant" className={classes.bg}>Protestant</option>

            </Form.Select>
<span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.ChurchAffiliation && <span>Church Affiliation is required</span>}</span>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={{ span: 8, offset: 4 }}>
            <Form.Check type="checkbox" label="Prayer Warrior:"
            id="PrayerWarrior"
            name= "isPrayerWarrier"
            checked={isPrayerWarrier}
            {...register("isPrayerWarrier")}
            onChange={(e) => setIsPrayerWarrier(e.target.checked)}  />
           
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Label id={classes.lblle} column md={4}>Address</Form.Label>
          <Col md={8}>
            <Form.Control type="text" className='input' 
            id="address"
            name="Address"
            aria-describedby="Address" 
            placeholder="Aitchson street 6"
            {...register("Address", { required: true })}
       
            />
            <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.Address && <span>Address is required</span>}</span>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Label id={classes.lblle} column md={4}>Pincode</Form.Label>
          <Col md={8}>
            <Form.Control 
            className='input' 
            type="number" 
        
       id="pincode" 
       name= "Pincode"
       aria-describedby="pincode" 
       placeholder="23456"
       {...register("Pincode", { required: true })} />
       <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.Pincode && <span>Pincode is required</span>}</span>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Label id={classes.lblle} column md={4}>Landmark</Form.Label>
          <Col md={8}>
            <Form.Control className='input' type="text" 
            id="landmark" 
            name="LandMark"
            aria-describedby="landmark" 
            placeholder="Near Dgx"
            {...register("LandMark", { required: true })}
      
            />
            <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.LandMark && <span>Land Mark is required</span>}</span>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Label id={classes.lblle} column md={4}>City</Form.Label>
          <Col md={8}>
            <Form.Control className='input' type="text" 
            id ="city" 
            name = "City"
            aria-describedby="City" 
            placeholder="City"
            {...register("City", {required: true })}
       
            />
            <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.City && <span>City is required</span>}</span>
          </Col>
        </Row>


      
      <Row>
    
        
          <Button className="btn w-25 mb-3" style={{ padding: 5, marginLeft: 1030, marginTop:-52 }} type="submit" value="Save" name="save">Save</Button>
        
      </Row>
    
       
      </Form>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Col>

   
  </Row>

  </div>
 
);
}

export default Profille;