import classes from './MainHeading.module.css';
const PopularPT = (props)=>{
    return(
        <div>
         <h1 className={classes.PopularPT}>
         {props.children}</h1>
     </div>
       
    )
}
export default PopularPT;