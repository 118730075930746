// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmModal_overlay__ztJOc {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .ConfirmModal_modal__xcrlK {\n    background: white;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .ConfirmModal_buttons__TrupF {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 20px;\n  }\n  \n  .ConfirmModal_modal__xcrlK button {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .ConfirmModal_modal__xcrlK button:hover {\n    background-color: #ddd;\n  }", "",{"version":3,"sources":["webpack://./src/Components/UI/Modal/ConfirmModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .modal {\n    background: white;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .buttons {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 20px;\n  }\n  \n  .modal button {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .modal button:hover {\n    background-color: #ddd;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "ConfirmModal_overlay__ztJOc",
	"modal": "ConfirmModal_modal__xcrlK",
	"buttons": "ConfirmModal_buttons__TrupF"
};
export default ___CSS_LOADER_EXPORT___;
