
import React,{useState,useEffect} from "react";
import { useNavigate,Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PrayerHead from "../Logo/PrayerHead";
import ButtonT from "../UI/Button/ButtonT";
import classess from './Registerpge.module.css';
import {Form} from 'react-bootstrap';
import classes from  './WBackground.module.css';
import axios from 'axios';
import { socket } from '../../index';
import config from "../../config";
import { useForm } from "react-hook-form";
import NetworkStatus from "./NetworkStatus";

const userId = localStorage.getItem("user-id");
const headers = {'Authorization': `Bearer ${localStorage.getItem('token')}`};
let prayerId;

const PrayerReqSend = (props)=>{
  const { handleSubmit, register, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prayerTitle,setprayerTitle]= useState('');
   const [prayerdesc,setPrayerReq]= useState(''); 
   const [image,setImage] = useState('');
   const [errorMsg , setErrorMsg]= useState('');
   const [groupdata,setGroupData] = useState([]);
   const [isChecked, setIsChecked] = useState(false);
   const [networkError, setNetworkError] = useState(false);
  const favPrayer = false;
  const prayerTypes = 'prayer';
  let notificationSent = false; 

   useEffect(() => {
    const url= `${process.env.REACT_APP_API_HOST}/groups/all`;
    const fetchGroups = async () => {
      try {
        const groupData = await axios.get(url,headers);
        setGroupData(groupData);
      } catch (err) {}
    };
    fetchGroups();
    
  }, []);
const onSubmit = async (data,e) => {
  try {
   await SubmitForm(data,e);
    console.log("data",data);
    console.log("data",e);
  } catch (e) {
    console.error(e);
  }
}

const SubmitForm = async(data,e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        if (!navigator.onLine) {
          setNetworkError(true);
          return;
        }
    
        try{
          const formData = new FormData();
          formData.append('prayerTitle',prayerTitle);
          formData.append('prayerdesc',prayerdesc);
          formData.append('favPrayer',favPrayer);
          formData.append('prayerPublic',isChecked);
          formData.append('prayerPriority',data.prayerPriority);
          formData.append('prayerTypes',prayerTypes);
          formData.append('image',image);
          formData.append('prayergroup',data.prayergroup);
          formData.append('userId', localStorage.getItem("user-id").toString()); //'643fe4df0513d0b1717316a3');
          let prayerStatus = isChecked ? 'awaiting' : 'approved'; // Set to 'awaiting' if prayerPublic is true

          formData.append('prayerStatus', prayerStatus);
         
         const url = `${process.env.REACT_APP_API_HOST}/prayers/add`;

         const config = {
          headers:{
              "content-type":"multipart/form-data"
          }
      }
          const response = await axios.post(url, formData, config);
  
          setNetworkError(false)
          prayerId = response.data.prayer._id;
          
          console.log("prayerid of created prayer",prayerId);
        
          console.log("Prayer Title",response.data.prayer.prayerTitle);
          if (!notificationSent) {
            socket.emit("post_data", {prayerId:prayerId, userId:userId});
            notificationSent = true; // Set the flag to indicate that the notification has been sent
          }
          
          navigate("/Main/UrgentPrayers");      
        }catch(err){
          setNetworkError(true)
          console.log(err);
        }finally {
          setIsSubmitting(false);
        }
        };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= maxLength) {
          setPrayerReq(inputValue);
        }
      };
      const maxLength = 500;
      const minLength = 100;
      const handleKeyDown = (e) => {
        if (e.ctrlKey && e.keyCode === 13) {
          e.preventDefault();
    
          const position = e.target.selectionEnd;
          const value = e.target.value;
          const newValue = value.substring(0, position) + '\n' + value.substring(position);
          e.target.value = newValue;
    
          // Move the cursor to the next line
          e.target.selectionStart = e.target.selectionEnd = position + 1;
        }
      };
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        setIsChecked(checked);
    };  
    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      const maxSize = 1.5 * 1024 * 1024; // 2.5 MB
    
      if (file && file.size > maxSize) {
        setErrorMsg(`The selected image exceeds the 1.5 MB size limit.`);
         
        return;
      }
      setImage(file);
      setErrorMsg('');
    }       

return(
   <div id={classes.Backgrnd} className='bg-image'>
    <PrayerHead/>
<div className="d-flex align-item-center justify-content-center">
<NetworkStatus networkError={networkError} />
<form method="POST" onSubmit={handleSubmit(onSubmit)}>

<Form.Group className="mt-4">

      <Form.Label id={classess.lablle}>Write your prayer</Form.Label>
      <Form.Control 
         as="textarea"
         id="tb"
          className={classess.txtarea} //"form-control" 
          
          aria-describedby="prayerReqSend" 
          placeholder="Describe in 500 characters, what you would like to pray"
          name="prayerdesc"
          {...register("prayerdesc", { required: true })}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
      
          />
            <p style={{marginLeft:5}}>
        Character Count: {prayerdesc.length}/{maxLength}
      </p>
      <span style={{color:'red',fontFamily:'calibri',fontSize:15,marginLeft:5,position:'relative',top:-22}}>{errors.prayerdesc && 'Prayer Description required'}</span>
  </Form.Group>
  <Form.Group className="mt-2">

      <Form.Label id={classess.lablle}>Prayer Title:</Form.Label>
      <Form.Control 
          type="input" 
          className="form-control" 
          id={classess.input} 
          aria-describedby="prayerReqSend" 
          placeholder="Add prayer title"
          name="prayerTitle"
          {...register("prayerTitle", { required: true })}
          onKeyDown={handleKeyDown}
          maxLength={minLength}
          onChange={event=>setprayerTitle(event.target.value)}
          />
         <p style={{marginLeft:5}}>
        Character Count: {prayerTitle.length}/{minLength}
        
      </p>
      <span style={{color:'red',fontFamily:'calibri',fontSize:15,marginLeft:5,position:'relative',top:-21}}>{errors.prayerTitle && 'Prayer Title required'}</span>
      
  </Form.Group>

  <Form.Group className="form-check form-switch" >
            <Form.Label className='mt-2' style={{ marginLeft: -56, fontFamily: 'calibri', fontStyle: "normal", fontWeight: 700, fontSize: 16 }}>Show the Prayer Public: </Form.Label>
            <Form.Control type="checkbox"
                className="form-check-input"
                data-toggle="toggle"
                aria-describedby="show Prayer"
                name="prayerPublic"
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ width: 56, height: 28, position: 'relative',  left: 190 }} />
            <Form.Label style={{ marginLeft: 4, lineHeight: 2 }}>{isChecked ? 'On' : 'Off'}</Form.Label>
            <p style={{ color: 'red', fontFamily: 'calibri', fontSize: 15, marginLeft: -39, position: 'relative', top: -17 }}>{errors.prayerPublic && 'Prayer Public required'}</p>
    </Form.Group>

    <Form.Group className="mt-4"> 
      <Form.Label id={classess.lablle}> Prayer Priority: </Form.Label>
               <select  style ={{width:910,borderRadius:4, backgroundColor:'white'}} id={classess.input}  
                name="prayerPriority"
                {...register("prayerPriority", { required: true })}
              
      >
                    <option value="Urgent">Urgent</option>
                    <option value="Normal">Normal</option>
           
   </select>
   <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.prayerPriority && 'Please select a field'}</span>
    </Form.Group>
   
  <Form.Group className="mt-5">
       <Form.Label id={classess.lablle} >Do you want to add a picture: </Form.Label>
       <Form.Control 
       type="file" 
       className="form-control" 
       id={classess.fileUpload} 
       name="image"
       {...register("image")}
       aria-describedby="FileUpload" 
       placeholder="Choose your Picture"
       accept=".jpg .png .jpeg .gif"
       onChange={handleFileInputChange}
       />
       <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errorMsg && <span>{errorMsg}</span>}</span>
    </Form.Group>

    <Form.Group className="mt-5"> 
      <Form.Label id={classess.lablle} > Share in Group: </Form.Label>
   <select
   id={classess.input}
    name = "prayergroup"
    {...register("prayergroup")}
    defaultValue={props.prayergroup || 'none'} 
   style = {{width:910,borderRadius:4, backgroundColor:'white'}}
   
   >   <option value="none">None</option>
         {
             groupdata.data !== undefined && groupdata.data.data.length > 0 && (
               groupdata.data.data.map(group =>(   
                    <option key={group._id}>{group.groupName}</option>
            )
            ) )
           }
   </select>
   <p style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.prayergroup && 'Prayer group required'}</p>
    </Form.Group>
    <Form.Group>
      <div style={{marginLeft:160}}className="mt-5">
       <ButtonT type="submit" name="sendPrayerReq" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</ButtonT>
       </div>
    </Form.Group>
    
</form>
</div>

  </div>
 )
 
 }
 export default PrayerReqSend;