import React from 'react';
import classes from './ButtonT.module.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
const ButtonT = (props) =>{
    return(
<button type={props.type} className="btn btn-success mt-1 mb-4" id= {classes.button} onClick={props.onClick}>
    {props.children}</button>
    )

}
export default ButtonT;