// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#PrayerTypes_setCol__23XCx{\n    display:flex;\n}\n#PrayerTypes_WBackgrnd__8pwAx{\n    background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    width:400px;\n    height:50vh;\n    }\n    #PrayerTypes_height__O8fcN{\n        height:11rem;\n    }", "",{"version":3,"sources":["webpack://./src/Components/PrayerType/PrayerTypes.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,wDAAsC;IACtC,4BAA4B;IAC5B,WAAW;IACX,WAAW;IACX;IACA;QACI,YAAY;IAChB","sourcesContent":["#setCol{\n    display:flex;\n}\n#WBackgrnd{\n    background-image:url(../images/bg.png);\n    background-repeat: no-repeat;\n    width:400px;\n    height:50vh;\n    }\n    #height{\n        height:11rem;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setCol": "PrayerTypes_setCol__23XCx",
	"WBackgrnd": "PrayerTypes_WBackgrnd__8pwAx",
	"height": "PrayerTypes_height__O8fcN"
};
export default ___CSS_LOADER_EXPORT___;
