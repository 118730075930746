import React,{ useState,useEffect} from 'react';
import classes from './ArrowR.module.css';
import { useApi } from '../../../context/ApiContext';
import axios from 'axios';

const SavePop = ({prayerId}) =>{
  const [clicked, setClicked] = useState(false)
  const {SavePryer} = useApi();
  useEffect(() => {
    const savedClicked = localStorage.getItem(`savedClicked_${prayerId}`);
    if (savedClicked) {
      setClicked(JSON.parse(savedClicked));
      }
  }, [prayerId]);

  const handleSavePrayer = async() => {
      try {
         await SavePryer(prayerId);
        localStorage.setItem(`savedClicked_${prayerId}`,JSON.stringify(true));
        setClicked(true);
      } catch (error) {
        console.error(error);
      }
      // setClicked(true);
    }
    const handleUnSavePrayer = async() => {
      try {
       const res = await axios.put(`http://localhost:8080/prayers/unsave/${prayerId}`);
       localStorage.setItem(`savedClicked_${prayerId}`, JSON.stringify(false));
       console.log(res);
       console.log("test unsave prayer")
       setClicked(false);
     } catch (error) {
        console.error(error);
      }

    }
    return(
      <div>
      {clicked ? (
        <div className={classes.HrtRed} onClick={handleUnSavePrayer} id={classes.clickable} />
      ) : (
        <div className={classes.HeartSignB} onClick={handleSavePrayer} id={classes.clickable} />
      )}
    </div>
   
        )

}
export default SavePop;