import React,{useState,useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import classes from './UrgentPrayer.module.css';
import MainHeading from "../../UI/MainHead/MainHeading";
import axios from 'axios';
import PrayerText from "../../UI/MainHead/PrayerText";
import Loading from "../../UI/Loading/Loadding";
import Share from '../../notification/feedback/Share';
import settings from '../../UI/secConnection/SecureConnection.module.css';
import {Link} from 'react-router-dom';
import CommentB from "../../notification/feedback/CommentB";
import { socket } from '../../../index';
import ResponseN from "../../notification/feedback/ResponseN";
import { S3Client } from '@aws-sdk/client-s3';
import nonProfilPic from '../../images/nonIcon.png'

  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,

  });


const churchhId = localStorage.getItem("churchId");
const NewsPrayer = () =>{
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [likesCount, setLikesCount] = useState(0);
  const [postRemoveLike, setPostRemoveLike] = useState([]);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const url = `${process.env.REACT_APP_API_HOST}/news/church/all/${churchhId}`;
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const updateImageUrls = (data) => {
    return data.map((newItem) => {
      const prayrImg = newItem.image;
      const profileImg = newItem.postedBy?.AddPic;
      if (prayrImg) {
        const imageUrl = prayrImg ? `https://${host}/news/${prayrImg}` : null;
      const imageUrl2 = profileImg ? `https://${host}/pastor/${profileImg}` : null;
        return {
          ...newItem,
          imageUrl: imageUrl,
          imageUrl2: imageUrl2,
        };
      }
      return newItem;
    });
  };

  useEffect(() => {
    const fetchNews = async () => {
      const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
      try {
        setLoading(true);
        const responseData = await axios.get(url, headers);
        const data = responseData.data;
        if (data.news.length > 0) {
          const updatedNews = updateImageUrls(data.news);
          setNewsData(updatedNews);
        } else {
          setNewsData([]);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    fetchNews();
  }, [url]);

  useEffect(() => {
    const handleNewsAdded = (data) => {
      setNewsData((prevNews) => {
        const updatedNews = updateImageUrls([data, ...prevNews]);
        return updatedNews;
      });
    };

    socket.connect();
    socket.on("newAdded", handleNewsAdded);

    socket.on('likeAdded', (updatedNews) => {
      setNewsData((prevNews) => {
        const updated = prevNews.map((news) => news._id === updatedNews._id ? updatedNews : news);
        return updateImageUrls(updated);
      });
    });

    socket.on('unlikeAdded', (updatedNews) => {
      setNewsData((prevNews) => {
        const updated = prevNews.map((news) => news._id === updatedNews._id ? updatedNews : news);
        return updateImageUrls(updated);
      });
    });

    return () => {
      socket.off("newAdded", handleNewsAdded);
      socket.off("likeAdded");
      socket.off("unlikeAdded");
    };
  }, [socket]);

  const handleClick = (newsId, action) => {
    setSelectedNewsId(newsId);
    console.log("news ID", newsId);
    if (action === 'like') {
      setSelectedNewsId(newsId);
      console.log("Selected Post Id post comment:", newsId);
    }
  };

  const updateLikedata = (newLikesCount) => {
    setLikesCount(newLikesCount);
    console.log("likes inside prayer page", likesCount);
  };

  let uiPosts = likesCount.length > 0 ? likesCount : postRemoveLike.length > 0 ? postRemoveLike : newsData;

  return (
    <div>
      <div className="d-flex justify-content-center">
        {loading ? (
          <Loading />
        ) : uiPosts !== undefined && uiPosts.length > 0 ? (
          <div>
            {uiPosts.map((item, index) => (
              <div id= {classes.Prayer} className={'mt-3 mb-3 bg-image'} key={index}  newsid={item._id} onClick={() => handleClick(item._id)}>
                <div className="col mt-3 text-black">
                  <img src={item.imageUrl2 || nonProfilPic} style={{ width: 46, height: 43, borderRadius: 50, marginTop: 10 }} />
                  <div className="ms-4">
                    {item.postedBy && item.postedBy.username && (
                      <p style={{ marginTop: -43, marginLeft: 27, fontFamily: 'calibri', fontSize: 16, fontWeight: 700, color: 'black' }}>
                        {item.postedBy.username}
                      </p>
                    )}
                    {new Date(item.createdAt) instanceof Date && !isNaN(new Date(item.createdAt)) ? (
                      <span style={{ fontFamily: 'calibri', fontSize: 14, fontWeight: 600, color: 'black', opacity: 0.3, marginLeft: 27, position: 'relative', top: -22 }}>
                        {new Date(item.createdAt).toLocaleString(undefined, dateOptions)}
                      </span>
                    ) : (
                      <span>Invalid Date</span>
                    )}
                  </div>
                </div>
                <div style={{ marginLeft: -23 }}>
                  <MainHeading>{item.newsTitle}</MainHeading>
                  <Link to={`/singlenew/${item._id}`} style={{ textDecoration: 'none' }}>
                    <PrayerText className="justify-content-center" style={{ marginLeft: 10 }}>{item.newsDescription}</PrayerText>
                  </Link>
                  <img src={item.imageUrl || 'defaultNewsImage.jpg'} style={{ width: 500 }} className="d-block mx-auto mt-5" />
                </div>
                <div className="col mt-5" id={settings.optionP}>
                  <div style={{ marginLeft: -30 }}>
                    <ResponseN style={{ marginLeft: 24 }} onClick={() => handleClick(item._id, 'like')} newsid={item._id} likes={item.likes} updateLikedata={updateLikedata} />
                    <p style={{ position: "relative", bottom: 43, right: -124 }}>{item.likes.length}</p>
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <CommentB />
                    <p style={{ position: "relative", left: -34, top: -29, color: "black", opacity: 0.50 }}>{item.Comments.length}</p>
                  </div>
                  <div>
                    <Share style={{ marginLeft: -100 }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No records found</p>
        )}
      </div>
    </div>
  );
// const [loading,setloading] = useState(false);
// const [newsData,setNewsData] = useState([]);
// const [likescount, setLikesCount] = useState(0);
// const [postRemoveLike, setPostRemoveLike] = useState([]);
// const [selectedNewsId, setSelectedNewsId] = useState(null);
// const url= `${apiUrl}/news/church/all/${churchhId}`;
// var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

// const updateImageUrls = (data) => {
//   return data.map((newItem) => {
//     const prayrImg = newItem.image;
//     const profileImg = newItem.postedBy.AddPic;
//     if (prayrImg) {
//       const imageUrl = `https://${host}/news/${prayrImg}`;
//       const imageUrl2 = `https://${host}/member/${profileImg}`;
//       return {
//         ...newItem,
//         imageUrl: imageUrl,
//         imageUrl2: imageUrl2,
//       };
//     }
//     return newItem;
//   });
// };
// useEffect(() => {
  
//   const fetchNews = async () => {
//   const headers = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
//      try {
//       setloading(true);
//      const responseData = await axios.get(url,headers);
//      console.log(responseData.data);
//      const data = responseData.data;
//      if (data.news.length > 0) {
//       const updatedNews = updateImageUrls(data.news);
//       setNewsData(updatedNews);
//     } else {
//       setNewsData([]);
//     }
//     //  if (data.news.length > 0) {
//     //    const updatedNews = data.news.map(newItem => {
//     //      const prayrImg = newItem.image;
//     //      const profileImg = newItem.postedBy.AddPic;
//     //      if (prayrImg) {
//     //        const imageUrl = `https://${host}/news/${prayrImg}`;
//     //        const imageUrl2 = `https://${host}/member/${profileImg}`;
//     //        return {
//     //          ...newItem,
//     //          imageUrl: imageUrl,
//     //          imageUrl2: imageUrl2
//     //        };
//     //      }
//     //      return newItem;
//     //    });

//     //    // Set the state with updated news items
//     //    setNewsData(updatedNews);
//     //  } else {
//     //   setNewsData([]);
//     //  }
   
//    } catch(err) {
//        console.log(err);
//    }
//    finally {
//     console.log(loading,"test");
//     setTimeout(() => {
//       setloading(false);
//     }, 1000);
//   }
      
//  }
//      fetchNews();
//    console.log(newsData);
//    console.log(selectedNewsId);
//  },[url]);
//  useEffect(() => {
 
//   const handleNewsAdded = (data) => {
//     console.log("Socket connected");
//     console.log("data Received from socketio:", data);
//     console.log(data.prayerTitle); //&& data.prayerdesc)
//     socket.on("newAdded", (data) => {
//       setNewsData((prevnews) => {
//       console.log("prevPosts test", prevnews);
//       return [data, ...prevnews];
//     });
//   });
//   };

//   socket.connect();
//   console.log("UseEffect Socket.io PostAdded", socket);

//   socket.on("connect", () => {
//     console.log("Socket connected successfully");
//   });

//   socket.on("newAdded", handleNewsAdded);
 
 
// //   socket.on('likeAdded', (newsPosts) => {
// //            setLikesCount(newsPosts);
// //             setPostRemoveLike('');
// //         });
// //   socket.on('unlikeAdded', (newPosts) => {
// //     setPostRemoveLike(newPosts);
// //     setLikesCount('');
// // });
// socket.on('likeAdded', (updatedNews) => {
//   setNewsData((prevNews) => prevNews.map(news => news._id === updatedNews._id ? updatedNews : news));
// });

// socket.on('unlikeAdded', (updatedNews) => {
//   setNewsData((prevNews) => prevNews.map(news => news._id === updatedNews._id ? updatedNews : news));
// });

//   return () => {
//     socket.off("newAdded",handleNewsAdded);
//     socket.off("likeAdded");
//      socket.off("unlikeAdded");
    
//   };
// }, [socket]);

// const handleClick = (newsId,action) => {
  
//   setSelectedNewsId(newsId);
//   console.log("news ID",newsId);
//   if (action === 'like') {
//     setSelectedNewsId(newsId);
//     //setIsModalOpened(true);
//     console.log("Selected Post Id post comment:", newsId);
//   }
  
// };
// const updateLikedata =(newLikesCount) =>{
//   setLikesCount(newLikesCount);
//   console.log("likes inside prayer page",likescount);
//    };
//    let uiPosts = likescount.length > 0 ? likescount : postRemoveLike.length > 0 ? postRemoveLike : newsData;
// return(
//   <div>
// <div className={'d-flex justify-content-center'}>
// {   loading ? ( // Show loading spinner while data is being fetched
//         <Loading />
//       ) :
//       uiPosts !== undefined && uiPosts.length > 0 ? (
  
//         <div>
// {uiPosts.map((item,index) => (
//               <div id= {classes.Prayer} className={'mt-3 mb-3 bg-image'} key={index}  newsid={item._id} onClick={() => handleClick(item._id)}>
//                 <div className={'col mt-3 text-black'}>
//                    {/* <UserPic className={'justify-content-between'} newsid={item._id} />`https://${host}/member/${item.postedBy.AddPic}` */}
//                    <img src={item.imageUrl2} style={{width:46, height:43, borderRadius:50, marginTop:10}} />
//                    <div className="ms-4"> {item.postedBy && item.postedBy.username && (<p style={{marginTop:-43,marginLeft:27,fontFamily:'calibri',fontSize:16,fontWeight:700,color:'black'}}>{item.postedBy.username}</p>
// ) }
// {new Date(item.createdAt) instanceof Date && !isNaN(new Date(item.createdAt)) ? (
//     <span style={{fontFamily:'calibri',fontSize:14,fontWeight:600,color:'black',opacity:0.3,marginLeft:27,position:'relative',top:-20}}>
//       {new Date(item.createdAt).toLocaleString(undefined, dateOptions)}
//     </span>
//   ) : (
//     <span>Invalid Date</span>
//   )}</div>
//                 </div>
//                 <div style={{marginLeft:-23}}>
//                 <MainHeading>{item.newsTitle}</MainHeading>
//                 <Link to={`/singlenew/${item._id}`} style={{ textDecoration: 'none' }}> <PrayerText className={'justify-content-center'} style={{ marginLeft: 10 }}>{item.newsDescription}</PrayerText></Link>
//                 <img src={item.imageUrl} style={{width:500}} className="d-block mx-auto mt-5" alt="Profilepic"/>
//                 </div>
                
//   <div className={'col mt-5'} id={settings.optionP}>
//         <div style={{marginLeft:-30}}>
//             <ResponseN style={{marginLeft:24}} onClick={() => handleClick(item._id,'like')} newsid={item._id} likes={item.likes} updateLikedata={updateLikedata} />
//             <p style={{position:"relative", bottom:43,right:-124}}>{item.likes.length}</p>
//         </div>
//         <div style={{marginLeft:20}}> 
//          <CommentB/>
//          <p style={{position:"relative", left:-34, top:-29, color:"black", opacity:0.50}}>{item.Comments.length}</p>
//         </div>
//       <div>
//          <Share style={{marginLeft:-100}}/>
//        </div>
//      </div>
//       </div>
//           ))}   
//           </div>
//       ) : (
//       <p>No records found</p>
//       )} 


// </div>


//   </div>
// )

}
export default NewsPrayer;
//https://www.youtube.com/watch?v=Dx4IeaL3oHA
//https://www.youtube.com/watch?v=T5aQWFVfeCs     //https://www.youtube.com/watch?v=B3pApL0Sey8