//import Logo from './Logo';
//import Notification from '../notificaton/Notification';

import classes from './header.module.css';
import GoBackbtn from '../PrayerWeaverLogo/GoBackbtn';
import HeaderText from '../UI/MainHead/HeaderText';
import LoginIcon from '../LoginIcon/LoginIcon';
import {NavLink, useLocation} from 'react-router-dom';
//import { useLocation } from 'react-router-dom';
const NAVBAR_TEXTS = [{page:"/Main/PrayerReqSend", text: "Prayer for me"},{page:"/Main/NewsReqSend", text: "I need a news request"},{page:"/profile", text: "Update profile"}, {page:"/Main/UrgentPrayers", text: "I want to prayer for"},{page:"/Main/NewsPrayers", text: "I want to prayer for"},{page:"/Main/PopularPrayers", text: "I want to prayer for"},{page:"/Main/MakeStories", text: "I want to prayer for"},{page:"/Main/SavedPrayer", text: "Saved prayer"},{page:"/Main/MinePrayers", text: "Saved prayer"}]

const PrayerHead = ()=>{
    const location = useLocation();
    
    const isPostBlogPage = location.pathname === '/Main/PrayerReqSend';
    
    const textToShow = NAVBAR_TEXTS.find(el => el.page === location.pathname)?.text;

    return(
        
        <div id={classes.toppBaner}>
       <NavLink to="/Main"><GoBackbtn/></NavLink>
            

            <HeaderText style={{textAlign: 'left'}}> {textToShow} </HeaderText>

            <div className='gap-4' style={{ position: 'relative', marginLeft: isPostBlogPage ? 810: 820}}>
                <LoginIcon isPostBlogPage={isPostBlogPage} style={{transform:'translate3d(0px, 100.8px, 0px)!important'}}/>
            </div>
     

        </div>
    );
}
export default PrayerHead;
//234CompScien*