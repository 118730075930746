import UrgentPrayer from './UrgentPrayer/UrgentPrayer';
import PrayerFor from './PrayerFor/PrayerFor';
import SavePrayer from './SavePrayer/SavePrayer';
//import { Link, Outlet } from 'react-router-dom';
import classes from './PrayerTypes.module.css';
import classess from '../MainHeader/MainHeader.module.css';

const PrayerTypes =()=>{
    return(
    <div className={classess.mainHeader} id={classes.height}>
    <div id={classes.setCol} className='d-flex align-item-center justify-content-center mt-3 gap-3'>
    {/* <Link to="/PrayerReqSend" >Prayer Request </Link> */}

        
         <UrgentPrayer/>  
         <PrayerFor/> 
         <SavePrayer/> 
         </div>
         </div>

    )
}
export default PrayerTypes;