import React,{useEffect} from 'react';
import classes from './Modal.module.css';
//import react from 'react';
//import { createPortal } from 'react-dom';
import ReactDOM from 'react-dom';

const Modal = ({isOpened,children,onClose}) =>{

    useEffect(() => {
        if (isOpened) {
          document.body.style.overflow = 'hidden';
          document.body.style.position = 'fixed';
          document.body.style.width = '100%';
          document.body.style.height = '100%';
        } else {
          document.body.style.overflow = '';
          document.body.style.position = '';
          document.body.style.width = '';
          document.body.style.height = '';
        }
    
        return () => {
          document.body.style.overflow = '';
          document.body.style.position = '';
          document.body.style.width = '';
          document.body.style.height = '';
        };
      }, [isOpened]);

        if(!isOpened){
            return null;
        }
        
return ReactDOM.createPortal(
       
     <>
                <div className={classes.overlay}> </div>
                <div className={classes.modal}>
                 <div>
                    <div>
                    {/* <div>{children}</div>    */}
                    <span className={classes.closeButton} onClick={onClose}>X</span>
                    </div>
                    
                    <div className={classes.modalcontent}>{children}</div>
                    {/* <div className={classes.modalFooter}>{children}</div> */}
                </div>    
                </div>

            </>,document.body
       
    );
        
}
export default Modal;