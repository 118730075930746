 import classes from './LoginIcon.module.css'
 import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap/dist/js/bootstrap.bundle.min.js';


//import IconText from '../IconText/IconText';

 const UserPic = () =>{

     return (
      <div className='d-flex'>
       
        
        <div className='me-2'  id={classes.loginIcon} alt=""/>
       
      
        </div>
              
     )

 }
 export default UserPic;