import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import classes from '../../Registerpge/WBackground.module.css';

import PrayerHead from "../../Logo/PrayerHead";
import MainHeader from "../../MainHeader/MainHeader";
import SavePrayers from "./Saveprayers";
//import { ApiProviders } from "../../../context/ApiContext";



const SavedPrayer = (userId) =>{
    //const userId = localStorage.getItem("user-id").toString();
    //console.log("userId SavedPrayers",userId);
return(

<div id={classes.Backgrnd} className='bg-image'>
    <PrayerHead/>
    <div>
        <MainHeader page="SavedPrayer"/>
    </div>
<div className="d-flex justify-content-center">
    {/* <ApiProviders> */}
      <SavePrayers userId={userId}/>
    {/* </ApiProviders> */}
</div>

</div>

)

}
export default SavedPrayer;