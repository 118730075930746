// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#MainHeader_titleHead__DyDvv {\n    \n  font-family: 'Calibri';\n  font-size: 14px;\n  font-weight: 400;\n  font-style: normal;\n  color:rgb(0, 0, 0);\n  display: flex;\n  line-height: 7px;\n  opacity:0.75;\n  margin-left:75px;\n}\n.MainHeader_mainHeader__ZnUAd {\n  \n  height: 5rem;\n  display: flex;\n  /* justify-content: space-between; */\n  align-items: center;\n  justify-content: center;\n  background: rgb(251, 248, 248);\n  /* padding: 0 2rem 0 0; */\n}\n\n.MainHeader_main-header__VgsC7 h1 {\n  color: rgb(3, 3, 3);\n}", "",{"version":3,"sources":["webpack://./src/Components/MainHeader/MainHeader.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;AACA;;EAEE,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["#titleHead {\n    \n  font-family: 'Calibri';\n  font-size: 14px;\n  font-weight: 400;\n  font-style: normal;\n  color:rgb(0, 0, 0);\n  display: flex;\n  line-height: 7px;\n  opacity:0.75;\n  margin-left:75px;\n}\n.mainHeader {\n  \n  height: 5rem;\n  display: flex;\n  /* justify-content: space-between; */\n  align-items: center;\n  justify-content: center;\n  background: rgb(251, 248, 248);\n  /* padding: 0 2rem 0 0; */\n}\n\n.main-header h1 {\n  color: rgb(3, 3, 3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleHead": "MainHeader_titleHead__DyDvv",
	"mainHeader": "MainHeader_mainHeader__ZnUAd",
	"main-header": "MainHeader_main-header__VgsC7"
};
export default ___CSS_LOADER_EXPORT___;
