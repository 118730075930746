
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useParams } from "react-router-dom"; 
import CompletePost from './CompletePost';
//import comment from '../../../../../PWAPI/models/comment';
// import {Link} from 'react-router-dom';
//import comment from '../../../../../PWAPI/models/comment';
const SinglePost = ()=>{
  const {prayerid} = useParams();
  console.log(prayerid);
  
    return(
   
      
        <div>
        
            <CompletePost prayerId={prayerid}/>
       </div>
  
    )
}
export default SinglePost;