import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import classes from '../../Registerpge/WBackground.module.css';
import PrayerHead from "../../Logo/PrayerHead";
import MainHeader from "../../MainHeader/MainHeader";
import Prayers from "./Prayers";
//import UrgentPrayer2 from "./UrgentPrayer2";
// import Prayers from "./Prayers";
//import {ApiProviders} from "../../../context/ApiContext";
//import UrgentPrayer2 from "./UrgentPrayer2";
const UrgentPrayers = () =>{
return(

<div id={classes.Backgrnd} className='bg-image'>
    <PrayerHead/>
    <div>
        <MainHeader page="PrayerFor"/>
    </div>
<div className="d-flex justify-content-center">
    <Prayers/>
 
    
</div>

</div>

)

}
export default UrgentPrayers;