import classes from './PrayerWeaverLogo.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const GoBackbtn = () =>{
    return (
     <div className='my-auto'>
       
       <img  id={classes.goBackBtn}  alt=""/>

      </div>
    )

}
export default GoBackbtn;