//import { propTypes } from 'react-bootstrap/esm/Image';
import classes from './MainHead.module.css';
const MHead = (props) =>{
    return(
        <div>
  <p id={classes.titleHead}>{props.children}</p> 
        </div>
       
    )
}
export default MHead;