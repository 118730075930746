
import classes from './EmailText.module.css';
const InstructionTxt = (props) =>{
    return(
     
        <div className="d-flex justify-content-center mt-5">
      
            <p className={classes.account}>{props.children}</p>
         
            </div>
         
    )
}
export default InstructionTxt;