
import classes from './App.module.css';
import {Container} from 'react-bootstrap';
import Router from './Router';
import { AuthContextProvider } from './context/AuthContext';
import axios from 'axios';
import { BackgroundProvider, useBackground } from './context/BackgroundContext';
axios.defaults.withCredentials = true;

function AppContent() {
  const { isBackgroundVisible } = useBackground();

  return (
    <Container fluid className={classes.centter}>
      <div id={isBackgroundVisible ? classes.Background : ''} className='bg-image'>
        <Router />
      </div>
    </Container>
  );
}

function App() {
  return (
    <AuthContextProvider>
      <BackgroundProvider>
        <AppContent />
      </BackgroundProvider>
    </AuthContextProvider>
   
    
  );
}

export default App;
