// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#IconText_paragraphText__f4PC8 {\n    max-width: 144px;\n    height:79px;\n    font-family: 'Calibri';\n    font-size: 18px;\n    font-weight: 400;\n    font-style: normal;\n    color: #000;\n   \n}\n#IconText_IconF__AaUTF{\n    justify-content: space-around;\n   font-family: 'Calibri';\n   font-style: normal;\n   font-weight: 400;\n   font-size: 15px;\n   line-height: 25px;\n   margin-left:83px;\n   color:white;\n   opacity: 0.5;\n  \n \n }", "",{"version":3,"sources":["webpack://./src/Components/notification/IconText.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;;AAEf;AACA;IACI,6BAA6B;GAC9B,sBAAsB;GACtB,kBAAkB;GAClB,gBAAgB;GAChB,eAAe;GACf,iBAAiB;GACjB,gBAAgB;GAChB,WAAW;GACX,YAAY;;;CAGd","sourcesContent":["#paragraphText {\n    max-width: 144px;\n    height:79px;\n    font-family: 'Calibri';\n    font-size: 18px;\n    font-weight: 400;\n    font-style: normal;\n    color: #000;\n   \n}\n#IconF{\n    justify-content: space-around;\n   font-family: 'Calibri';\n   font-style: normal;\n   font-weight: 400;\n   font-size: 15px;\n   line-height: 25px;\n   margin-left:83px;\n   color:white;\n   opacity: 0.5;\n  \n \n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraphText": "IconText_paragraphText__f4PC8",
	"IconF": "IconText_IconF__AaUTF"
};
export default ___CSS_LOADER_EXPORT___;
