import classes from '../Notification.module.css';
import icon from '../IconText.module.css';
const Comment = (props) =>
{
    return(
  
        <div className='mb-2'>
      
           <div className={classes.comments} style={{marginLeft:-39}}>
           {/* <p id={icon.IconF} className='mt-1' style={{marginLeft:44}}/> */}
           </div>
        </div>
        )

}
export default Comment
; 