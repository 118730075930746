import classes from './IconText.module.css'
const IconText = (props) =>{
    return(
        <div>
 <p  id= {classes.paragraphText}>
    {props.children}</p>
        </div>
       
    )
}
export default IconText;