import classes from './PrayerPopular.module.css';
// import PrayerText from "../../UI/MainHead/PrayerText";
const PopText = (props)=>{
return(
 
    <div className='mt-5'>
    <p className={classes.PrayerText}>{props.children}</p>
    </div>

)
}
export default PopText;