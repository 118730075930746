import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
const ApiContext = createContext();


// Create a custom hook to use the context
 export const useApi = () => useContext(ApiContext);

// Create a provider component to wrap around the App
export const ApiProviders = ({children}) => {

  const [prayerData, setPrayerReq] = useState(null);
  const [prayerId,setPrayerId] = useState();
  const [commments,getCommments] = useState([]);
  const [likedata,setlikeData] = useState([]);
  const [savePrayer,setSavePrayer] = useState([]);
  const [favPrayer,setFavPrayer] = useState([]);
  const [reelp,setReelp] = useState([]);
  const [reeluid,setReelUid] = useState([]);
  const [postedComment, setPostedComment] = useState(null);
  const userId = localStorage.getItem("user-id");
  const userrId = localStorage.getItem("user-id");
  const churchhId = localStorage.getItem("churchId");
 
  
   const updatedPrayerId = (newPrayerId) =>{
    setPrayerId(newPrayerId);
  
  }
  

  const urlD=`${process.env.REACT_APP_API_HOST}/prayers/deleteCompletePost/${prayerId}`;
  const favP = `${process.env.REACT_APP_API_HOST}/prayers/favPrayer/${localStorage.getItem("churchId")}`;
  const reel = `${process.env.REACT_APP_API_HOST}/reel/allreel/${localStorage.getItem("churchId")}`;
  const reelUid = `${process.env.REACT_APP_API_HOST}/reel/myreels/${userrId}`;
  const urlAC = `${process.env.REACT_APP_API_HOST}/prayers/${prayerId}/comments`;
  const headers = {'Authorization': `Bearer ${localStorage.getItem('token')}`};

 console.log("post comment",urlAC);
  const config = {
    headers:{
        'Content-Type': 'application/json'
       }
  }

const favoritePrayer = async ()=>{
  try {
        
    const PrayerD = await axios.get(favP);
    //console.log("token favp",localStorage.getItem('token'));
    console.log(PrayerD.data.prayers);
    return PrayerD.data.prayers;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const allReel = async()=>{
  try{
    const reelD = await axios.get(reel,headers);
    setReelp(reelD.data.prayerreels);
    console.log(reelD.data.prayerreels)
  }catch(err){
    console.log(err);
  }
  console.log("All Reels data",reelp);
}
const ReelUserId = async()=>{
  try {
    const reelInfo = await axios.get(reelUid,headers);
    setReelUid(reelInfo.data.reels);
    console.log(reelInfo.data.reels); 

  }catch(err){
    console.log(err);
  }
}
const fetchSinglePost = async (prayerId) => {
  
    try {
        
        const responseData = await axios.get(`${process.env.REACT_APP_API_HOST}/prayers/${prayerId}`,headers);
        setPrayerReq([responseData.data.prayer]);
        return responseData.data.prayer;
      } catch (err) {
        console.log(err);
      }
  };
  const makeCommentApi = async (commentData) => {

    try {
     
        const res = await axios.post(`${process.env.REACT_APP_API_HOST}/prayers/${prayerId}/comments`, commentData, config);
        setPostedComment(res.data.comments);
        return res.data; // You can return the response data if needed
    } catch (error) {
        console.error('Error making comment:', error);
        throw error; // Rethrow the error to handle it elsewhere
    }
};
const commentFetching =async (prayerId)=>{
    try{
      const commentData = await axios.get(`${process.env.REACT_APP_API_HOST}/prayers/getComments/${prayerId}`);
      console.log(commentData.data.data.comments); 
      getCommments(commentData.data.data.comments);
      
    }
      catch(error){
        console.log(error);
      }
    }
  const DeleteCompletePost = async(prayerId)=>{
      
      try{

             await axios.delete(urlD,config);
         }
      catch(err){
             console.log(err);
      }};
const fetchSavedPrayer = async(userId) => {
  
        try {
            const savedPrayer = await axios.get(`${process.env.REACT_APP_API_HOST}/prayers/saved/${userId}`);
            //console.log("Api response Save prayer",savedPrayer);
            setSavePrayer(savedPrayer.data.prayers); 
            console.log("State Variable",savedPrayer.data.prayers);
            return savedPrayer.data.prayers;
          } catch (err) {
            console.log(err);
          }
          console.log(savePrayer);
    }; 
  const SavePryer = async(prayerId)=>{
        
        try {
           const SavePInfo = {
            userId: userId,
            prayerId: prayerId
           };
         const res = await axios.post(
                    `${process.env.REACT_APP_API_HOST}/prayers/saved/add`,
               SavePInfo ,headers);
                console.log(res.data);
               
        }catch(err){
              console.error("error",err);
           }           
        }; 
 

const fetchpeoplesPraying = async (prayerId) => {
  try {
    // const pryerdata = {
    //   prayerId: prayerId,
    //   likes: userrId
    // };
  
    const result = await axios.put(`${process.env.REACT_APP_API_HOST}/prayers/like/${prayerId}`);
    
    // const newData = likedata.map((posts) => {
    //   if (posts._id === result.data._id) {
    //     return result.data; // Assuming the response contains the updated data
    //   } else {
    //     return posts;
    //   }
    // });
  
    // setlikeData(newData);
    console.log(result.data);
  } catch (err) {
    console.log(err);
  }
  
    
       };
const unfetchpeoplesPraying = async (prayerId) => {
    try {
  
       const res =  await axios.put(`${process.env.REACT_APP_API_HOST}/prayers/unlike/${prayerId}`)
      
      console.log(res);
    } catch (err) {
       console.log(err);
       }
      console.log("unlike Data",likedata);
  };
 const deactivatePrayer = async (prayerId)=>{
    const isActive = false;
    console.log("delete PrayerID",prayerId);
    try{
  
  const res = await axios.delete(
    `${process.env.REACT_APP_API_HOST}/prayers/delete/${prayerId}`,
        
        {
          data: { isActive },
        headers:{
               'Content-Type': 'application/json',
               //'Authorization': `Bearer ${localStorage.getItem('token')}`
             }})
    if(res.ok){
      console.log("Prayer deactivated successfully");
    }else{
      console.error('Failed to deactivate prayer');
    }
    }catch(err){
      console.log(err);
    }
  }
  const contextValues = {
    prayerId,
    updatedPrayerId,
    //postData,
    prayerData,
    getCommments,
    commments,
    postedComment,
    likedata,
    savePrayer,
    favPrayer,
    reelp,
    reeluid,
    setFavPrayer,
    setSavePrayer,
    setPrayerReq,
    fetchSinglePost,
    commentFetching,
    makeCommentApi,
    DeleteCompletePost,
    SavePryer,
    fetchpeoplesPraying,
    unfetchpeoplesPraying,
    fetchSavedPrayer,
    favoritePrayer,
    allReel,
    ReelUserId,
    deactivatePrayer
    
  };

  return (
    <ApiContext.Provider value={contextValues}>
      {children}
    </ApiContext.Provider>
  );
};
export default ApiContext;
//export {ApiProviders};