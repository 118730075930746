 import classes from './Notification.module.css'
 import 'bootstrap/dist/css/bootstrap.min.css';
 import 'bootstrap/dist/js/bootstrap.bundle.min.js';
 //import classses from '../LoginIcon/LoginIcon.module.css';
 import Button from 'react-bootstrap/Button';
 import React,{useState,useEffect} from "react";
 import {Link} from 'react-router-dom';
 import ButtonGroup from 'react-bootstrap/ButtonGroup';
 import Dropdown from 'react-bootstrap/Dropdown';
 import { socket } from '../../index';
 const churchId = localStorage.getItem("churchId");

 const Notification = () =>{
  const [notifications,setNotifications] = useState([]);
  const [isNewNote , setIsNewNote] = useState(false);
  useEffect(() => {
    console.log("socket connected");
    const user = {churchId};

    socket.emit('join_room', user);
    socket.emit('initial_data', user);

    const handleGetData = (notifications) => {
      console.log('Received notifications:', notifications);
      setNotifications(notifications);
      console.log('isNewNote:', notifications.some(notification => !notification.read));
    };

    const handleChangeData = () => {
      console.log('change_data event received');
      const user = { churchId };
      console.log('Re-fetching initial data for user',user);
      socket.emit("initial_data",user);
    };

    socket.on("get_data", handleGetData);
    socket.on("change_data", handleChangeData);

    return () => {
      socket.off('get_data', handleGetData);
      socket.off('change_data',handleChangeData);
    };
  },[]);

  useEffect(() => {
    setIsNewNote(notifications.some(notification => !notification.read));
  }, [notifications]);

  const handleClick = (notificationId) => {
    console.log("notification ID" ,notificationId)
    socket.emit("mark_notification_read",notificationId);
  };



  return (
      <div className='justify-content-center align-self-center' style={{position:'relative', left:120, zIndex:2}}> 
      <Dropdown as={ButtonGroup} >  

      <Button style={{backgroundColor:'transparent',borderColor:'transparent'}} id={classes.bellIcon} className={classes.iconPp}> <span id={classes.counnter}  className='badge bg-secondary position-absolute top-2 start-50 badge translate-middle p-2 rounded-pill bg-danger'>{notifications.length}</span></Button>
       <Dropdown.Toggle style={{backgroundColor:'transparent',borderColor:'transparent'}} />

     <Dropdown.Menu className={classes.dropDown}> 
     {notifications.length ? (
            notifications.map((notification, index) => (
              <Dropdown.Item 
                key={index}
                onClick={() => handleClick(notification._id)} id={classes.menu}  className={classes.menuItem}>
                 <Link to={`/singlepost/${notification.prayerId}`}  className={classes.menuItem} style={{ textDecoration: 'none', background:'light',color:'black' }}>{notification.notificationText} {notification.userId?.username}</Link>
                
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item className={classes.menuItem}>No notifications</Dropdown.Item>
          )}

  </Dropdown.Menu>
      
    </Dropdown>
     
       
       </div>
     )


 }
 export default Notification;