import React,{useState} from 'react';
import classes from '../Notification.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Dropdown from 'react-bootstrap/Dropdown';
//import classses from './shareDropD.module.css';
import './share.css';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon ,
  WhatsappIcon
} from "react-share";
//import ShareModal from './ShareModal';

const Share = ({page}) =>{
  const [isCopied, setIsCopied] = useState(false);
    // const [showComponent, setShowComponent] = useState(false);
    const currentUrl = window.location.href;
    const shareUrl = typeof window !== "undefined" ? window.location.href : "";
    const title =
      typeof document !== "undefined"
        ? document.title
        : "Check out this awesome content!";
   
        const handleCopyClick = async () => {
          try {
            await navigator.clipboard.writeText(currentUrl);
            setIsCopied(true);
          } catch (error) {
            console.error("Error copying to clipboard:", error);
          }
        }
    return(
  <div>
    <Dropdown >  
     {
    /* margin-left: 103px; */
      page === 'PrayerForr' ? <Dropdown.Toggle className={`${classes.share} ${classes.dropdowntoggle}`}  style={{backgroundColor:'transparent',borderColor:'transparent',marginLeft:-55}} /> :
      <Dropdown.Toggle className={`${classes.SharePrayer} ${classes.dropdowntoggle} `} style={{backgroundColor:'transparent',borderColor:'transparent'}} />
     }
      


    <Dropdown.Menu style={{width: 240, marginLeft: -105}}> 
   
    <h6 className="share_other">Share with others</h6>
        <Dropdown.Item  style={{margin:0}}> 
          <TwitterShareButton url={shareUrl} title={title}>
              <TwitterIcon round style={{width:40 , height:40,marginLeft:78}} />
              <p style={{marginLeft:77}}>twitter</p>
              </TwitterShareButton>
        </Dropdown.Item>
      
     <Dropdown.Item key="nothing" style={{margin:0}}>
         <FacebookShareButton url={shareUrl} title={title}>
          <FacebookIcon round style={{width:40 , height:40,marginLeft:63}} />
               <p style={{marginLeft:70}}>Facebook</p>
           </FacebookShareButton>
     </Dropdown.Item>
     <Dropdown.Item style={{margin:0}}>
     <WhatsappShareButton url={shareUrl} title={title}>
    <WhatsappIcon round style={{width:40 , height:40,marginLeft:63}} />
    <p style={{marginLeft:65}}>whatsApp</p>
    </WhatsappShareButton>
     </Dropdown.Item>
     <div>
      <h4 className="text-center">Share Link</h4>
          <button className="modal-footer-button" onClick={handleCopyClick}>
              {isCopied ? "Link Copied!" : "Copy Link"}
      </button>
      </div>
 </Dropdown.Menu>
     
   </Dropdown>
    
  </div>
        );
}
export default Share;