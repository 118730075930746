import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
  
const ProgressB = ({bgcolor,progress,height}) => {
     
    const Parentdiv = {
        height: height,
        width: 510,
        backgroundColor: 'whitesmoke',
        borderRadius: 30,
        marginLeft:23,
        // zIndex:999

      }
      
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
         borderRadius:40,
        textAlign: 'right',
       
      }
      
      const progresstext = {
        padding: 10,
        color: 'lightblue',
        fontWeight: 900
      }
        
    return (
      
    <div style={Parentdiv}>
      <div style={Childdiv}>
      </div>
    </div>
    
    )
}
  
export default ProgressB;