 import classes from './PrayerWeaverLogo.module.css'
 import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

 const PrayerWeaverLogo = () =>{
     return (
      <div className='my-auto'>
        
        <div  id={classes.logoPW}  alt=""/>
      
     
       
       </div>
     )

 }
 export default PrayerWeaverLogo;