//import React,{useState} from "react";
import { useState,useEffect } from "react";
//import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//import { useQuery } from "react-query";
//import ButtonT from "../UI/Button/ButtonT";
//import classes from './Registerpge.module.css';
//import {Form} from 'react-bootstrap';
import axios from 'axios';
const PrayerReqq = ({prayerId}) =>{
  const [prayerData, setPrayerReq] = useState(null);
  const url = `${process.env.REACT_APP_API_HOST}/prayer/${prayerId}`;
  console.log(prayerId);
  useEffect(() => {
    const fetchPrayer = async () => {
      try {
        const responseData = await axios.get(url);
        setPrayerReq([responseData.data.prayer]);
        console.log(responseData.data.prayer);
      } catch (err) {
        console.log(err);
      }
    };
    
    fetchPrayer();
  }, [prayerId]);

  // useEffect(() => {
  //   console.log("Prayer Data", prayerData);
  //   console.log("length of collection", prayerData.length);
  // }, [prayerData]);
  //console.log("data", prayerData);
  return (
    <div className="d-flex align-item-center justify-content-center">
      {prayerData !==null ? (
        
        <div>
          
          {prayerData.map((item) => (
            <div key={item._id}>
             
              <p>{item.prayerdesc}</p>
              <p>{item._id}</p>
            </div>
            
          ))}
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};
export default PrayerReqq;
//<label for="img">Select image:</label>
//<input type="file" id="img" name="img" accept="image/*">
//<input type="submit">