import classes from './MainHead.module.css';
const PrayerText =(props)=>
{
    return(
        <div>
  <p className={classes.Prayertext}>{props.children}</p> 
        </div>
       
    )
}
export default PrayerText;