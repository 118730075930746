import classes from './MainHead.module.css';
const StoryTitle = (props)=>{
    return(
        <div>
         <h1 className={classes.titleStory}>
         {props.children}</h1>
     </div>
       
    )
} 
export default StoryTitle;