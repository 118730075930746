//import PrayerHead from "../Logo/PrayerHead";
import React,{useState} from "react";
import ButtonT from "../../UI/Button/ButtonT";
import classes from '../../Registerpge/Registerpge.module.css';
import {Form} from 'react-bootstrap';
import { useForm } from "react-hook-form"
import config from "../../../config";

 import axios from "axios";
const apiUrl = config();

const ReelPost = ({setIsModalOpened})=>{
  const { handleSubmit, register, formState: { errors } } = useForm();
  const [image,setImage] = useState('');
  const [errorMsg , setErrorMsg]= useState('');
  const onSubmit = async (data,e) => {
    try {
     await SubmitForm(data,e);
      console.log("data",data)
    } catch (e) {
      console.error(e);
    }
  }
   
        const SubmitForm = async(data,e) =>{
             e.preventDefault();
             console.log(data);
             try{
              const formData = new FormData();
              formData.append('PrayerReelTitle',data.PrayerReelTitle);
              formData.append('ReelsStatus',"success");
              formData.append('PrayerReelImage',image);
              formData.append('userId',localStorage.getItem("user-id"))
         
               await axios.post(
                 `${process.env.REACT_APP_API_HOST}/reel/add`,
                 formData,
                  {
                   headers: {
                    "content-type":"multipart/form-data"
                   },
                  
                 }
                  );
                  setIsModalOpened(false);
                 
            }catch(err){
                console.error("error",err);
                }
                };
                const handleFileInputChange = (event) => {
                  const file = event.target.files[0];
                  const maxSize = 1.5 * 1024 * 1024; // 2.5 MB
                
                  if (file && file.size > maxSize) {
                    setErrorMsg(`The selected image exceeds the 1.5 MB size limit.`);
                     
                    return;
                  }
                  setImage(file);
                  setErrorMsg('');
                }
return(
    <div>
    <div style={{marginTop:93}}>    
        <form method="POST" onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
       <Form.Label id={classes.label}>Prayer Reel Title </Form.Label>
       <Form.Control type="text" 

         id ="Prayer Reel Title" 
         name = "PrayerReelTitle"
         aria-describedby="Prayer Reel Title" 
         placeholder="Prayer Reel Title"
         {...register("PrayerReelTitle", { required: true })}
     
         />
         <span style={{color:'red',fontFamily:'calibri',fontSize:15,marginLeft:5,position:'relative',top:-2}}>{errors.PrayerReelTitle && 'Prayer Description required'}</span>

      </Form.Group>


        <Form.Group>
       <Form.Label id={classes.label}>Add Image </Form.Label>
       <Form.Control 
            type="file" 
            name="PrayerReelImage"
            aria-describedby="FileUpload" 
            placeholder="Add Pic"
            {...register("PrayerReelImage")}
            accept=".jpg .png .jpeg .gif"
            onChange={handleFileInputChange}
          />
    <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errorMsg && <span>{errorMsg}</span>}</span>
    </Form.Group>
    <div style={{marginLeft:60}}>
    <ButtonT type="submit" name="signup" value="Submit">Submit</ButtonT>
    </div>
    </form>
    </div>
</div>
)
}
export default ReelPost;