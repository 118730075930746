import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import classes from './PrayerPopular.module.css';

const LeftImg = ()=>{
    return (
        <div className={'d-flex'}>
            <div id={classes.worship}/>

        </div>
    )

}
export default LeftImg;