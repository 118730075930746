import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React,{useEffect,useState} from "react";
import classes from '../UrgentPrayer/UrgentPrayer.module.css';
import Loading from "../../UI/Loading/Loadding";
import { useApi } from '../../../context/ApiContext';
import LeftImg from "./LeftImg";
import PopText from './PopText';
//import UserInfo2 from "../../UI/secConnection/UserInfo2";
import ResponseP from '../../notification/feedback/ResponseP';
// import ResponseP from '../../notification/feedback/ResponseP';
import PopularPT from '../../UI/MainHead/PopularPT';

//import UserInfop from '../../UI/secConnection/UserInfop';
import SavePop from '../../UI/ArrowRight/SavePop';
// import UserPic from '../../LoginIcon/UserPic';
import { S3Client } from '@aws-sdk/client-s3';
import { socket } from '../../../index';
import nonProfilPic from '../../images/nonIcon.png'
//import nonProfilPic from '../../images/nonIcon.png';

  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,

  });
const isProduction = process.env.NODE_ENV === 'production';
 
const PopularPrayer= () =>{
  //const churchhId = localStorage.getItem("churchId");
   const {favoritePrayer} = useApi();
   const [loading,setloading] = useState(false);
   const [likescount, setLikesCount] = useState(0);
   const [favPrayer,setFavPrayer] = useState([]);
   
   var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
   const updateImageUrls = (data) => {
    return data.map((pryrItem) => {
      if (!Array.isArray(data)) {
        console.error('Expected an array, but got:', data);
        return [];
      }
      const prayrImg = pryrItem.image;
        const profileImg = pryrItem.userId?.AddPic;
        console.log("prayer image",prayrImg);
        console.log("profile img",profileImg);
        const imageUrl = prayrImg ? `https://${host}/prayer/${prayrImg}` : null;
        const imageUrl2 = profileImg ? `https://${host}/member/${profileImg}` : null;

        return {
          ...pryrItem,
          imageUrl: imageUrl,
          imageUrl2: imageUrl2,
        };

    });
  };
   useEffect(() => {
     const fetchfav = async ()=>{
     try{
      setloading(true);
       const favpryer = await favoritePrayer();
       if (Array.isArray(favpryer)) {
        const updatedPrayer = updateImageUrls(favpryer);
        setFavPrayer(updatedPrayer);
      } else {
        console.error('Expected an array, but got:',favpryer);
      }
      }catch (error) {
       console.log(error);
        } finally {
          console.log(loading,"test");
          setTimeout(() => {
            setloading(false);
          }, 1500); // Set loading to false after the API call completes
        }
     }
       fetchfav();
       console.log("favorite Prayer",favPrayer);
    },[]);
    useEffect(() => {
 
      socket.connect();
      console.log("UseEffect Socket.io PostAdded", socket);
    
      socket.on("connect", () => {
        console.log("Socket connected successfully");
      });
      const updatePostImageUrls = (newPosts) => ({
        ...newPosts,
        imageUrl: `https://${host}/prayer/${newPosts.image}`,
        imageUrl2: `https://${host}/member/${newPosts.userId?.AddPic}`
      });
      socket.on('likeAdded', (newPosts) => {
        setFavPrayer((prevPrayers) => {
          const updated = prevPrayers.map((prayer) => prayer._id === newPosts._id ? updatePostImageUrls(newPosts) : prayer);
          return updated;
        });
      });
  
      socket.on('unlikeAdded', (newPosts) => {
        setFavPrayer((prevPrayers) => {
          const updated = prevPrayers.map((prayer) => prayer._id === newPosts._id ? updatePostImageUrls(newPosts) : prayer);
          return updated;
        });
      });
  
    
      return () => {
     
        socket.off("likeAdded");
        socket.off("unlikeAdded");
        // socket.disconnect();
      };
    }, [socket]);
    
    const updatedLikesCount =(newLikesCount) =>{
      setLikesCount(newLikesCount);
      console.log("likes inside prayer page",likescount);
       };
   
return(
  <div className={'d-flex justify-content-center'}>
  
  {   loading ? ( // Show loading spinner while data is being fetched
        <Loading />
      ) :
   
   favPrayer !== undefined && favPrayer.length > 0 ? (
         <div>
           {favPrayer.map((item,index) => (
            
            <div id= {classes.Prayer} key={index} className={'d-flex bg-image mt-2 p-0'}>
            <div className='row'>
          <div className={'col-3'} style={{backgroundColor:'#242424' }}>
              <LeftImg/>
          </div>
          <div className='col-9'>
          <img src={item.imageUrl2 || nonProfilPic} style={{ width:46,height:43,borderRadius:50,padding:4, marginLeft:5, marginTop:4}}  />
           <div className='row'>
               
               
     {item.userId && item.userId.username && (<span className={classes.user} style={{marginTop:-40, marginLeft:55}}>{item.userId.username}</span>
) }
{new Date(item.createdAt) instanceof Date && !isNaN(new Date(item.createdAt)) ? (
<samp id={classes.date} className='text-nowrap' style={{marginLeft:55, marginTop:-20, fontSize:13, fontFamily:'calibri'}}>
{new Date(item.createdAt).toLocaleString(undefined, dateOptions)}
</samp>
) : (
<span>Invalid Date</span>
)}
        
               <SavePop prayerId={item._id}/>

               </div>
               <div className='row'>

               <PopularPT>{item.prayerTitle}</PopularPT>
               <PopText>{item.prayerdesc}</PopText>
               
               <img src={item.imageUrl} style={{width:280,marginLeft:280}}  />


               </div>
          
              
             
             
           

                

                  <div className='row'>
          
                <ResponseP prayerId={item._id} likes={item.likes} updatedLikesCount={updatedLikesCount} />
                <p style={{position:"relative",top:-45,left:97}}>{item.likes.length}&nbsp;praying </p>

                </div>
           
           </div>
           </div>
      </div>
              
           ))}   
           </div>
       ) : (
        <p>No data available</p>
      )} 
        
         </div>


   )
}
export default PopularPrayer;