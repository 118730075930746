// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/SavePrayer.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/heart.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SavePrayer_savePrayer__Mgxbm{\n    background-Image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    color:#040404;\n    border:0px;\n    width:70px;\n    max-width:70px;\n    height: 70px;\n   \n }\n .SavePrayer_saveP__e1YJE{\n  background-Image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  color:#040404;\n  border:0px;\n  width:40px;\n  max-width:40px;\n  height: 40px;\n  margin-top: 12px;\n  margin-left: -500px;\n \n}\n  #SavePrayer_setCol__d3saW{\n    flex-direction: column;\n    /*justify-content: center;*/\n}\n/* .fixedWidth{\n  \n} */\n  ", "",{"version":3,"sources":["webpack://./src/Components/PrayerType/SavePrayer/SavePrayer.module.css"],"names":[],"mappings":"AAAA;IACI,wDAAmD;IACnD,4BAA4B;IAC5B,aAAa;IACb,UAAU;IACV,UAAU;IACV,cAAc;IACd,YAAY;;CAEf;CACA;EACC,wDAA8C;EAC9C,4BAA4B;EAC5B,aAAa;EACb,UAAU;EACV,UAAU;EACV,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;;AAErB;EACE;IACE,sBAAsB;IACtB,2BAA2B;AAC/B;AACA;;GAEG","sourcesContent":[".savePrayer{\n    background-Image:url('../../images/SavePrayer.png');\n    background-repeat: no-repeat;\n    color:#040404;\n    border:0px;\n    width:70px;\n    max-width:70px;\n    height: 70px;\n   \n }\n .saveP{\n  background-Image:url('../../images/heart.svg');\n  background-repeat: no-repeat;\n  color:#040404;\n  border:0px;\n  width:40px;\n  max-width:40px;\n  height: 40px;\n  margin-top: 12px;\n  margin-left: -500px;\n \n}\n  #setCol{\n    flex-direction: column;\n    /*justify-content: center;*/\n}\n/* .fixedWidth{\n  \n} */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savePrayer": "SavePrayer_savePrayer__Mgxbm",
	"saveP": "SavePrayer_saveP__e1YJE",
	"setCol": "SavePrayer_setCol__d3saW"
};
export default ___CSS_LOADER_EXPORT___;
