import axios from "axios";
import React,{useContext} from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import classes from './LoginIcon.module.css'


function LogOutBtn(){
  const { getLoggedIn } = useContext(AuthContext);
 const navigate = useNavigate();
 const cookieName = 'jwtPW';

 function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

async function logOut(e) {
 e.preventDefault();
 try {
  const resp = await axios.get(`${process.env.REACT_APP_API_HOST}/members/logout`,{
     timeout:5000,
     withCredentials:true,
   });
   console.log("response in logout", resp);
 } catch (error) {
   console.error('Logout request failed', error);
 } finally {
   await getLoggedIn();
   localStorage.removeItem('token');
   localStorage.removeItem('username');
   localStorage.clear();
   sessionStorage.clear();
   removeCookie(cookieName);

   navigate("/");
 }

}
 return <a href="/" onClick={logOut} style={{textDecoration:'none',color:'black'}} className={classes.menuItem}>Logout</a>;
}

export default LogOutBtn;