//import React,{useState} from "react";
import ProgressB from "./ProgressB";
import classes from './PrayerStory.module.css';
import SavePrayer from "../../UI/ArrowRight/SaveP";
import StoryTitle from "../../UI/MainHead/StoryTitle";
import { useState} from "react";
import ReelPost from "../prayerStory/ReelPost";
import Modal from "../../UI/Modal/Modal";
import fab from '../../notification/Notification.module.css';
import { S3Client } from '@aws-sdk/client-s3';

const key = 'DO00PFUZFUPP9QY8YX4F';
const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
const spaceName = 'prayer-weaver-files';
const region = 'ams3';
const host = `${spaceName}.${region}.digitaloceanspaces.com`;

const client = new S3Client({
  region,
  endpoint: `https://${host}`,
  credentials: {
    accessKeyId: key,
    secretAccessKey: secret,
  },
  bucketEndpoint: true,

});
var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };


const MakeStory = ({reels,userId})=>{
   

    const [currentReelIndex, setCurrentReelIndex] = useState(0);
    const [isModalOpened,setIsModalOpened] = useState(false);
    console.log("reels",reels[0]);
   
    const handleNextReel = () => {
      setCurrentReelIndex((prevIndex) => (prevIndex + 1) % reels.length);
    };
  
    const handlePreviousReel = () => {
      setCurrentReelIndex((prevIndex) => (prevIndex - 1 + reels.length) % reels.length);
    };

    


    const timeStlye = {
        color:'#ffff',
        fontWeight:400,
        lineHeight:.5,
        marginLeft:40,
        position:'absolute',
        top: 383, 
      }
      //for displaying time use timestamp

  
    
    
return(
    <div className={classes.WBackground}>
       <div id={classes.worship}>
       <div className={'col mt-2 pt-3 z-3'}>
        <ProgressB bgcolor="#ffff" progress='25' height={24} />
      </div>
     
      
      {userId ? (
          <>
          
            <div>
              <button className={classes.leftbutton} style={{marginTop:370}}  onClick={handlePreviousReel}>&lt;</button>
              <button className={classes.rightbutton} onClick={handleNextReel}>&gt;</button>
            </div>
            <div>
              {/* Display current reel */}
              {reels[currentReelIndex] && (
                <div key={reels[currentReelIndex]._id} className={'d-flex'}>
                   <div>
                    <StoryTitle>{reels[currentReelIndex].PrayerReelTitle} </StoryTitle>
                    
                    {new Date(reels[currentReelIndex].createdAt) instanceof Date && !isNaN(new Date(reels[currentReelIndex].createdAt)) ? (
    <span style={{fontFamily:'calibri',fontSize:14,fontWeight:600,color:'white',marginLeft:36,position:'relative',top:-410,left:-1}}>
      {new Date(reels[currentReelIndex].createdAt).toLocaleString(undefined, dateOptions)} <p style={{marginLeft:37}}>{reels[currentReelIndex].userId.username}</p>
     
    </span>
  ) : (
    <span>Invalid Date</span>
  )} 
  
  </div>
                  <div className='col'>
                    
                  {reels[currentReelIndex].PrayerReelImage && (
                    <img 
                      src={`https://${host}/reel/${reels[currentReelIndex].PrayerReelImage}`} 
                       style={{ width: 560, height: 800, marginTop: -380, marginLeft: -400 }} 
                     />
                    )}   
                    
                   
                  {/* <div style={timeStlye} className={classes.IconP}>{reels[currentReelIndex].createdAt}</div> */}
                  </div>
                  <div className='col' style={{ marginLeft: -25 }}>
                    <SavePrayer />
                  </div>
                </div>
              )}
            </div>
         
          </>
        ) : (
          <>
            <div>
              <button className="btn bg-transparent" id={fab.EditPrayer} style={{marginLeft:509,marginTop:380}} onClick={()=>setIsModalOpened(true)} />
            </div>
            <Modal isOpened={isModalOpened} onClose ={()=>setIsModalOpened(false)}>
              <ReelPost setIsModalOpened={setIsModalOpened}/>
            </Modal>
          </>
        )}
     </div>
    </div>

)
}
export default MakeStory;