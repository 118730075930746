//import React from "react";
import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PrayerHead from "../Logo/PrayerHead";
import ButtonT from "../UI/Button/ButtonT";
import classess from './Registerpge.module.css';
import {Form} from 'react-bootstrap';
import classes from  './WBackground.module.css';
import axios from 'axios';

import { useForm } from "react-hook-form"


const NewsReqSend = (props)=>{
  const { handleSubmit, register, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [newsTitle,setnewsTitle]= useState('');
   const [newsDescription,setNewsReq]= useState(''); 
   const [image,setImage] = useState('');
 
  
const onSubmit = async (data,e) => {
  try {
   await SubmitForm(data,e);
    console.log("data",data)
  } catch (e) {
    console.error(e);
  }
}

const SubmitForm = async(data,e) =>{
        e.preventDefault();
        if (!image) {
          console.error("Image object is not defined.");
          return;
      }
        console.log(data);
        console.log("test prayer request send ");
        console.log(localStorage.getItem("user-id"));
        try{
          const formData = new FormData();
          formData.append('newsTitle',newsTitle);
          formData.append('newsDescription',newsDescription);
     
          formData.append('image',image);
      
          formData.append('postedBy', localStorage.getItem("user-id")); //'643fe4df0513d0b1717316a3');
         
          console.log("formData",image);
         const url = `${process.env.REACT_APP_API_HOST}/news/add`;

         const config = {
          headers:{
              "content-type":"multipart/form-data"
          }
      }
          const response = await axios.post(url, formData, config).then()
      
          console.log(response);
         
          
          navigate("/Main/NewsPrayers");      
        }catch(err){
          console.log(err);
        }
        };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= maxLength) {
          setNewsReq(inputValue);
        }
      };
      const maxLength = 500;
      const minLength = 100;
      const handleKeyDown = (e) => {
        if (e.ctrlKey && e.keyCode === 13) {
          e.preventDefault();
    
          const position = e.target.selectionEnd;
          const value = e.target.value;
          const newValue = value.substring(0, position) + '\n' + value.substring(position);
          e.target.value = newValue;
    
          e.target.selectionStart = e.target.selectionEnd = position + 1;
        }
      };
 ;      

return(
   <div id={classes.Backgrnd} className='bg-image'>
    <PrayerHead/>
   
<div className="d-flex align-item-center justify-content-center" >

<form method="POST" onSubmit={handleSubmit(onSubmit)}>
<Form.Group className="mt-4">

<Form.Label id={classess.lablle}>News Title:</Form.Label>
<Form.Control 
    type="input" 
    className="form-control" 
    id={classess.input} 
    aria-describedby="prayerReqSend" 
    placeholder="Add prayer title"
    name="newsTitle"
    {...register("newsTitle", { required: true })}
    onKeyDown={handleKeyDown}
    maxLength={minLength}
    onChange={event=>setnewsTitle(event.target.value)}
    />
   <p style={{marginLeft:5}}>
  Character Count: {newsTitle.length}/{minLength}
  
</p>
<span style={{color:'red',fontFamily:'calibri',fontSize:15,marginLeft:5,position:'relative',top:-21}}>{errors.newsTitle && 'Prayer Title required'}</span>

</Form.Group>

<Form.Group className="mt-4">

      <Form.Label id={classess.lablle}>Please give complete detail of news </Form.Label>
      <Form.Control 
         as="textarea"
         id="tb"
          className={classess.txtarea} //"form-control" 
          
          aria-describedby="newsDescription" 
          placeholder="Describe in 30 words what you would like to pray"
          name="newsDescription"
          {...register("newsDescription", { required: true })}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
          
          //style={{textAlignVertical: 'top'}}
          //wrap="physical"
          />
            <p style={{marginLeft:5}}>
        Character Count: {newsDescription.length}/{maxLength}
      </p>
      <span style={{color:'red',fontFamily:'calibri',fontSize:15,marginLeft:5,position:'relative',top:-22}}>{errors.newsDescription && 'Prayer Description required'}</span>
  </Form.Group>

  <Form.Group>
       <Form.Label id={classess.lablle} className="mt-4">Do you want to add a picture: </Form.Label>
       <Form.Control 
       type="file" 
       className="form-control" 
       id={classess.fileUpload} 
       name="image"
       {...register("image", { required: true })}
       aria-describedby="FileUpload" 
       placeholder="Choose your Picture"
       
       onChange={event => setImage(event.target.files[0])}
       />
       <span style={{color:'red',fontFamily:'calibri',fontSize:15}}>{errors.image && 'Image required'}</span>
    </Form.Group>

    
    <Form.Group>
      <div style={{marginRight:112}}className="mt-5">
       <ButtonT type="submit" name="sendNewsReq" value="Submit">Submit</ButtonT>
       </div>
    </Form.Group>
    
</form>
</div>
</div>

 
 )
 
 }
 export default NewsReqSend;