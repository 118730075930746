import React,{useState, useContext,useEffect} from "react";
import {NavLink,useNavigate,useLocation} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ButtonT from "../UI/Button/ButtonT";
import classes from './Registerpge.module.css';
import {Form} from 'react-bootstrap';
import Logo from '../Logo/Logo'
import AuthContext from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import axios from 'axios';
import HaveAccount from "../haveAccount/HaveAccount";
import config from '../../config';
import SecureConnection from "../UI/secConnection/SecureConnection";
import MainHead from "../UI/MainHead/MainHead";
import NetworkStatus from "./NetworkStatus";

const apiUrl = config();

const RegisterPge = () =>{
        const { handleSubmit, register, formState: { errors } } = useForm();
        const navigate = useNavigate();
        const location = useLocation();
        const [errorMessage, setErrorMessage] = useState('');
        const {getLoggedIn} = useContext(AuthContext);
        const [passwordVisible, setPasswordVisible] = useState(false);
        const [Message,setMessage] = useState('');
        const [networkError, setNetworkError] = useState(false);
       const [user,setUser] = useState({
              username:"", 
              password:"",
            }); 

       
       const {username, password} = user;
            
          let name,value;
       
       const handleInputs = (e)  =>{
              console.log(e);
              name = e.target.name;
              value = e.target.value;
              setUser({...user,[name]:value});
            
              }
        const togglePasswordVisibility = () => {
                setPasswordVisible(!passwordVisible);
          };


              const onSubmit = async(data,e) =>{
                     e.preventDefault();
                     if (!navigator.onLine) {
                      setNetworkError(true);
                      return;
                      }
                     
                     try {
                        const loginData = {
                            username,
                            password
                        };
                      const res = await axios.post(
                            `${process.env.REACT_APP_API_HOST}/members/login`,
                            loginData,
                            {
                            headers:{
                                   'Content-Type': 'application/json',
                                 
                                 }}

                        )//res.user._id
                        setNetworkError(false);
                       console.log("test1");
                        console.log(res.data.user._id);
                        console.log(res.data.user.username);
                        console.log("token" +res.data.user.token);
                        localStorage.setItem("user-id", res.data.user._id);
                        localStorage.setItem("token", res.data.user.token);
                        localStorage.setItem("email", res.data.user.email);
                        localStorage.setItem("church-belong", res.data.user.ChurchBelongs);
                        localStorage.setItem("pastor_recomend", res.data.user.PastorRecomendation);
                        localStorage.setItem("user-name", res.data.user.username);
                        localStorage.setItem("user-image", res.data.user.AddPic);
                        localStorage.setItem("first-name", res.data.user.FirstName);
                        localStorage.setItem("last-name", res.data.user.LastName);
                        localStorage.setItem("birthdate", res.data.user.BirthDate);
                        localStorage.setItem("country", res.data.user.Country);
                        localStorage.setItem("city", res.data.user.City);
                        localStorage.setItem("cnicpic", res.data.user.CNICPic);
                        localStorage.setItem("codeofconduct", res.data.user.CodeOfConduct);
                        localStorage.setItem("document3", res.data.user.Document3);
                        localStorage.setItem("document4", res.data.user.Document4);
                        localStorage.setItem("churchId", res.data.user.churchId);
                   
                     if(res.data.user.status === true || res.data.user.status === 1 ){
                            console.log("Cookies before getLoggedIn:", document.cookies);
                            await getLoggedIn();
                           
                            navigate("/main");
                     } else if(res.data.user.status === false || res.data.user.status === 0) {
                           setMessage('Inactive account. Await email for status update.')
                     }
                   
                     }catch(error){
                            setNetworkError(true);
                            console.log("error",error.response.data.error)
                          if (error.response && error.response.data && error.response.data.error) {
                            setErrorMessage(error.response.data.error);
                            
                          } else {
                            
                            console.error("error",error);
                          }
                     }  
                    
                     };      
    

  useEffect(() => {
    console.log('Current Location:', location.pathname);
  }, [location]);   
return(
      
<div>

<Logo/>

<div className="mt-5"/>

<div className="d-flex align-item-start">
<NetworkStatus  networkError={networkError} />
<form method="POST" onSubmit={handleSubmit(onSubmit)}>
<div style={{position:"relative", left:49}}>
<MainHead >Login</MainHead></div>

<Form.Group>
      <Form.Label id={classes.label}>Username </Form.Label>
      <span className={classes.icon} style={{marginTop:-86}} id={classes.iconUN} />
      <Form.Control 
      type="text" 
      id="username"
      className={classes.input} 
      aria-describedby="usernameHelp" 
      placeholder="JohnDoe"
      
      name="username"
      {...register("username", {required:true,pattern: /^[a-zA-Z0-9_]+$/i,})}
      onChange={handleInputs}
      
       />
    <span style={{color:'red',marginLeft:118,fontFamily:'calibri',fontSize:15,position:'relative',top:-28}}> {errors.username?.type==="required" && "Username is Required"} </span>
  
</Form.Group>
 
<Form.Group>
     
       <Form.Label id={classes.label}>Password </Form.Label>
       <span className={classes.icon} 
       style={{marginTop:490}} 
       id={classes.icnPw}
       />
       <div style={{ position: 'relative' }}>
       <Form.Control 
        type={passwordVisible ? "text" : "password"}  
       className={classes.input} 
       id="password" 
       aria-describedby="passwordHelp" 
       placeholder="*******"
       {...register("password", {required:true,maxLength:9})}
       value={password}
       onChange={handleInputs}
       style={{background:'transparent',border:0,borderBottomWidth:2,borderRadius:0}}
       />
         <span
                        onClick={togglePasswordVisibility}
                        style={{
                            position: 'absolute',
                            top: '30%',
                            right: '10px',
                            cursor: 'pointer',
                            color:"white"
                        }}
              >
                        {passwordVisible ? 'hide' : 'show'}
                    </span>
                    </div>
       <span style={{color:'red',marginLeft:118,fontFamily:'calibri',fontSize:15,position:'relative',top:-28}}> { errors.password?.type==="required" && "Password is Required"}</span>
       
</Form.Group>
<Form.Group> 
     <Form.Label><NavLink to='/forgetpassword' style={{color:'white',textDecoration:'none',marginLeft:413,position:'relative',top:-53}}>Forget password?</NavLink>
     </Form.Label>
</Form.Group>
<h6  style={{color:'red',fontFamily:'calibri',fontSize:15,position:'relative',left:179,top:-47}}>{Message}</h6>
{errorMessage && <div style={{color:'red',fontFamily:'calibri',fontSize:16,position:'relative',left:262,top:-92}} className="mx-auto">{errorMessage}</div>}
<Form.Group>
       <ButtonT type="submit" name="Login" value="Submit" >Login</ButtonT>
</Form.Group>

</form>
</div>
<HaveAccount isSignUp={false} />
<SecureConnection/>
</div>

)

}
export default RegisterPge;