// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/Secure.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#SecureConnection_optionP__l4l4X{\n    display: flex;\n   flex-direction: row;\n   justify-content: space-between;\n}\n#SecureConnection_option__vteZz{\n    display: flex;\n   flex-direction: row;\n   justify-content: space-around;\n}\n.SecureConnection_connection__nn2HH{\n    line-height: 22px;   \n    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n    font-style: normal;\n    font-weight:normal;\n    font-size: 16px;\n    color: #3a3838;\n   \n}\n#SecureConnection_iconC__7rN-o{\n    background-Image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    color:rgb(7, 7, 7);\n    border:0px;\n    width:26px;\n    height: 20px;\n\n  }\n ", "",{"version":3,"sources":["webpack://./src/Components/UI/secConnection/SecureConnection.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;GACd,mBAAmB;GACnB,8BAA8B;AACjC;AACA;IACI,aAAa;GACd,mBAAmB;GACnB,6BAA6B;AAChC;AACA;IACI,iBAAiB;IACjB,6EAA6E;IAC7E,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,cAAc;;AAElB;AACA;IACI,wDAA+C;IAC/C,4BAA4B;IAC5B,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,YAAY;;EAEd","sourcesContent":["#optionP{\n    display: flex;\n   flex-direction: row;\n   justify-content: space-between;\n}\n#option{\n    display: flex;\n   flex-direction: row;\n   justify-content: space-around;\n}\n.connection{\n    line-height: 22px;   \n    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n    font-style: normal;\n    font-weight:normal;\n    font-size: 16px;\n    color: #3a3838;\n   \n}\n#iconC{\n    background-Image:url('../../images/Secure.svg');\n    background-repeat: no-repeat;\n    color:rgb(7, 7, 7);\n    border:0px;\n    width:26px;\n    height: 20px;\n\n  }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionP": "SecureConnection_optionP__l4l4X",
	"option": "SecureConnection_option__vteZz",
	"connection": "SecureConnection_connection__nn2HH",
	"iconC": "SecureConnection_iconC__7rN-o"
};
export default ___CSS_LOADER_EXPORT___;
