
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PrayerText from "../../UI/MainHead/PrayerText";
import Sharre from '../../notification/feedback/Ssharre';
import classes from './UrgentPrayer.module.css';
import ResponseB from '../../notification/feedback/ResponseB';
import settings from '../../UI/secConnection/SecureConnection.module.css';
//import icon from '../../notification/IconText.module.css'

import React,{useState,useEffect} from "react";
//import classess from '../../notification/Notification.module.css';
import axios from 'axios';
import UserPic from "../../LoginIcon/UserPic";
import config from "../../../config";
import CommentB from '../../notification/feedback/CommentB';
import { S3Client } from '@aws-sdk/client-s3';


const apiUrl = config();
const isProduction = process.env.NODE_ENV === 'production';
  const serverUrl = isProduction ? process.env.REACT_APP_ASSET_URL : process.env.REACT_APP_ASSET_URL_LOCAL;
  
  
  // const serverUrrl = isProduction ? process.env.REACT_APP_ASSET_URL : process.env.REACT_APP_ASSET_URL_LOCAL;
  // const serverUrl = process.env.NODE_ENV === 'production'
  // ? process.env.REACT_SOCKETLIVES
  // : process.env.REACT_SOCKETL;
  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,
  
  });

const CompleteNews = ({NewsId})=>{
  //  const [getComments,setComments] = useState([]);
  //  const [comments,setCommments] = useState();
  const [newsData, setNewsData] = useState([]);
   //const url = `${process.env.REACT_APP_API_HOST}/news/${NewsId}`;
   const headers = {'Authorization': `Bearer ${localStorage.getItem('token')}`}

   console.log("NewsID completeNew",NewsId);
   useEffect(() => {
     const fetchNews = async () => {
       try {
         const responseData = await axios.get(`${apiUrl}/news/${NewsId}`,headers);
         setNewsData([responseData.data.neww]);
         console.log("singleNew",responseData.data);
       } catch (err) {
         console.log(err);
       }
     };
     //console.log("collection length within function",prayerData.length);
     //console.log("Get prayer", prayerData)
     fetchNews();
   }, [NewsId]);
 
  //  const urlC = `http://localhost:8080/prayer/${prayerId}/comments`;
  //  //const url = "http://localhost:8080/prayer/6450b1380670046609d7cb41/getPrayer";
  //   useEffect(() => {
  
  //       const commentFetching =async ()=>{
  //           try{
  //             const commentData = await axios.get(`http://localhost:8080/prayer/${prayerId}/getComments`);
  //             console.log(prayerId)
  //             console.log(commentData.data); 
  //             setComments(commentData);
              
  //           }
  //             catch(error){
  //               console.log(error);
  //             }
  //           }
       
           
      //       commentFetching();
         
      //  },[prayerId]);

      
      //  const makecomment =  ()=>{
      //   console.log("comment added ");
      //   //console.log(prayerId);
      //   //console.log(prayerData.data.prayers[1]._id);
      //   //console.log(localStorage.getItem("username").toString());
      //   const commentt = {
      //       PrayerComments:comments,
      //        prayerId:prayerId,  //prayerData.data.prayers[0]._id,
      //        userId:localStorage.getItem("user-id").toString(),
      //        userName:localStorage.getItem("user-name").toString(),
      //        //userName:"Jamimah",
             
      //      //console.log(prayerData.data.prayers[0]._id)
      //   }
      //   const config = {
      //       headers:{
      //           'Content-Type': 'application/json'
      //          //"content-type":"application/json"
      //       }
      //   }
      //   try{
      //     const res = axios.post(urlC,commentt,config)
      //     setCommments(res);
      //             console.log(res);
                  
      //   }catch(err){
      //     console.log(err);
      //   }
      
      
      //    }
        

    return(
   
          <div className="d-flex row" style ={{position:"fixed", top:0}}> 
           <div className={'col-8 bg-light p-5'}  id= {classes.colPost} style ={{width:1000,marginLeft:13,height:730}}>
        
        {newsData !==null ? (
        
       <div>
          
          {newsData.map((item) => (
            <div className="ml-5">
             
             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
             <img src="" style={{width:550,height:550,position:"relative",bottom:48}} className="d-block mx-auto mt-5" alt="Profilepic"/>
            </div>
            
          ))}
        </div>
      ) : (
        <p>No data available</p>
      )}
          
         </div>  
          <div className={'col-4'} id= {classes.colPost} style={{width:520}}>
          <div className={`modal-header sticky-top fixed-top`} style ={{backgroundColor: "#f8f9fa"}}>
          <h4 className="modal-title">Prayer Weaver</h4>
        </div>
        <div className={`modal-body`} style={{maxHeight:'calc(100vh - 210px)', overflowY: "auto"}}>
                    <div>
                      <UserPic className={'justify-content-between'}/>
                    {/* <img src={`https://${host}/member/${item.postedBy.AddPic}`} style={{width:44, height:44, borderRadius:50, marginTop:10}}   alt="Nopic" />
                   <div className="ms-4"> {item.postedBy && item.postedBy.username && (<p style={{marginTop:-43,marginLeft:27,fontFamily:'calibri',fontSize:16,fontWeight:700,color:'black'}}>{item.postedBy.username}</p>
) }
{new Date(item.createdAt) instanceof Date && !isNaN(new Date(item.createdAt)) ? (
    <span style={{fontFamily:'calibri',fontSize:14,fontWeight:600,color:'black',opacity:0.3,marginLeft:27,position:'relative',top:-20}}>
      {new Date(item.createdAt).toLocaleString(undefined, dateOptions)}
    </span>
  ) : (
    <span>Invalid Date</span>
  )}</div> */}
                      {/* {localStorage.getItem("user-name").toString()} */}
                    </div> 
          {newsData.map((item) => (         
              <div key={item._id}> 
               <h3>{item.newsTitle}</h3>
                 <PrayerText >{item.newsDescription}</PrayerText> 
            </div>
            ))}
      
             <div className={'mt-4'}>
             <div id={settings.optionP}>
                  
                       <div  style={{marginLeft:-14}}>
                        <ResponseB/>
                       </div>
                       <div style={{marginLeft:-10}}>
                       
             
             {/* <div className={classess.commentb} style={{marginLeft:10}}>
                   <i className={classess.commentb}></i>
                   
             </div>
                  
             <span id={icon.IconF}  style={{color:'black', marginLeft:-20}}>12 Comments</span> */}
              
          <CommentB/>
               </div>
                       <div style={{marginLeft:-20}}>
                           <Sharre/>
                       </div>
                    <hr/>
              </div>
          </div>
                 
          </div>

          {/* <div className={`modal-footer sticky-bottom`} style ={{ marginTop:50, backgroundColor: "#f8f9fa", zIndex: 999}}>  
                 <input type="input" class="form-control"  style ={{position:"relative", top:20}} onChange={(e)=>{setCommments(e.target.value)}} name={"Prayercomment" } placeholder="Add a comment..." />
                 <button class="btn btn-light text-center" style ={{position:"relative", bottom:18}} onClick={()=>makecomment()}>Post</button> </div> */}
               
          </div>
          </div>
      
  
    )
}
export default CompleteNews;