// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/prayerrr.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/WithVerse.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main_Prayer__cYrjs {\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n   width: 1600px; \n  max-width: 100%;\n  height: 100vh;\n}\n.Main_Prayerr__h-RE8 {\n  width: 100%;\n  height: 100vh;\n  background-repeat: no-repeat;\n  background-size: cover !important;\n}\n.Main_noBackground__COnL8 #Main_Background__vvLUR {\n  display: none;\n}\n.Main_mainBackground__W26qx {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: cover !important;\n  background-position: center;\n  width: 100%;\n  height: 58vh;\n  overflow-y: hidden;\n\n}\n.Main_centering__eFTXC{\n  max-width: 992px;\n  margin:0 auto;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Main/Main.module.css"],"names":[],"mappings":"AAAA;EACE,wDAA4C;GAC3C,aAAa;EACd,eAAe;EACf,aAAa;AACf;AACA;EACE,WAAW;EACX,aAAa;EACb,4BAA4B;EAC5B,iCAAiC;AACnC;AACA;EACE,aAAa;AACf;AACA;EACE,yDAA8C;EAC9C,4BAA4B;EAC5B,iCAAiC;EACjC,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,kBAAkB;;AAEpB;AACA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".Prayer {\n  background-image:url(../images/prayerrr.png);\n   width: 1600px; \n  max-width: 100%;\n  height: 100vh;\n}\n.Prayerr {\n  width: 100%;\n  height: 100vh;\n  background-repeat: no-repeat;\n  background-size: cover !important;\n}\n.noBackground #Background {\n  display: none;\n}\n.mainBackground {\n  background-image: url(../images/WithVerse.jpg);\n  background-repeat: no-repeat;\n  background-size: cover !important;\n  background-position: center;\n  width: 100%;\n  height: 58vh;\n  overflow-y: hidden;\n\n}\n.centering{\n  max-width: 992px;\n  margin:0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Prayer": "Main_Prayer__cYrjs",
	"Prayerr": "Main_Prayerr__h-RE8",
	"noBackground": "Main_noBackground__COnL8",
	"Background": "Main_Background__vvLUR",
	"mainBackground": "Main_mainBackground__W26qx",
	"centering": "Main_centering__eFTXC"
};
export default ___CSS_LOADER_EXPORT___;
