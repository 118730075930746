import React,{useEffect,useState,useCallback} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
 import classes from './UrgentPrayer.module.css';
 import MainHeading from "../../UI/MainHead/MainHeading";
import PrayerText from "../../UI/MainHead/PrayerText";
import Loading from "../../UI/Loading/Loadding";
// import Pagination from 'react-bootstrap/Pagination';
 import icon from '../../notification/IconText.module.css';
import Share from '../../notification/feedback/Share';
import ResponseB from '../../notification/feedback/ResponseB';
import settings from '../../UI/secConnection/SecureConnection.module.css';
import Modal from '../../UI/Modal/Modal';
import PrayerReq from "./PrayerReq";
import { useApi } from "../../../context/ApiContext";
import CommentB from "../../notification/feedback/CommentB";
import { S3Client } from '@aws-sdk/client-s3';
import { socket } from '../../../index';
import {Link} from 'react-router-dom';
import nonProfilPic from '../../images/nonIcon.png';

  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,
 
  });

  

const SavePrayers = () =>{
 
  const {savePrayer,fetchSavedPrayer,setSavePrayer,updatedPrayerId} = useApi();
  const [likescount, setLikesCount] = useState(0);
  const [loading,setloading] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isModalOpened,setIsModalOpened] = useState(false);
  const userId = localStorage.getItem("user-id");
  var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
console.log("userId savePrayers",userId);

const updateImageUrls = (data) => {
  return data.map((pryrItem) => {
    if (!Array.isArray(data)) {
      console.error('Expected an array, but got:', data);
      return [];
    }
      const prayrImg = pryrItem.image;
      const profileImg = pryrItem.userId ? pryrItem.userId.AddPic : null;
      const imageUrl = prayrImg ? `https://${host}/prayer/${prayrImg}` : null;
      const imageUrl2 = profileImg ? `https://${host}/member/${profileImg}` : null;
      console.log("image URL Save prayer",imageUrl);
      console.log("image URL Save prayer",imageUrl2);
      return {
        ...pryrItem,
        imageUrl: imageUrl,
        imageUrl2: imageUrl2,
      };
 
  });
};
  useEffect(() => {
    const fetchP = async ()=>{
    try{
      setloading(true);
      const savedprayers = await fetchSavedPrayer(userId);
      if (Array.isArray(savedprayers)) {
        const updatedPrayer = updateImageUrls(savedprayers);
        setSavePrayer(updatedPrayer);
        console.log("Saved updated Prayer",updatedPrayer);
      } else {
        console.error('Expected an array, but got:',savedprayers);
      }

     }catch (error) {
      console.log(error);
       }
       finally {
        console.log(loading,"test");
        setTimeout(() => {
          setloading(false);
        }, 1000);
      }
    }
      fetchP();
   },[userId]);
   useEffect(() => {
 
    socket.connect();
    console.log("UseEffect Socket.io PostAdded", socket);
  
    socket.on("connect", () => {
      console.log("Socket connected successfully");
    });
    const updatePostImageUrls = (newPosts) => ({
      ...newPosts,
      imageUrl: `https://${host}/prayer/${newPosts.image}`,
      imageUrl2: `https://${host}/member/${newPosts.userId?.AddPic}`
    });
  socket.on('likeAdded', (newPosts) => {
    setSavePrayer((prevPrayers) => {
      const updated = prevPrayers.map((prayer) => prayer._id === newPosts._id ? updatePostImageUrls(newPosts) : prayer);
      return updated;
    });
  });
  socket.on('unlikeAdded', (newPosts) => {
    setSavePrayer((prevPrayers) => {
      const updated = prevPrayers.map((prayer) => prayer._id === newPosts._id ? updatePostImageUrls(newPosts) : prayer);
      return updated;
    })
  });

  
    return () => {
   
      socket.off("likeAdded");
      socket.off("unlikeAdded");
      // socket.disconnect();
    };
  }, [socket]);
     console.log("State Variable Save",savePrayer);
  const updatedLikesCount =(newLikesCount) =>{
    setLikesCount(newLikesCount);
     console.log("likes inside prayer page",likescount);
     };

    const handleClick =useCallback ((postId,action) => {
      setSelectedPostId(postId);
      updatedPrayerId(postId);
      //setIsModalOpened(true);
      if (action === 'viewC') {
      // setSelectedPostId(postId);
       //prayerId = {selectedPostId};
    
       setIsModalOpened(true);
     }
      console.log("prayer ID Mine Prayer2", selectedPostId);
    },[]);
  
  return(
    <div>
  <div className={'d-flex justify-content-center'}>
  
  {   loading ? ( // Show loading spinner while data is being fetched
        <Loading />
      ) :
  savePrayer !== undefined && savePrayer.length > 0 ? (
        <div>
          {savePrayer.map((item,index) => (
              <div id= {classes.Prayer} className={'mt-3 mb-3 bg-image'} key={index} prayerId={item._id}>
                <div className={'col mt-3'}>
              
                   <img src={item.imageUrl2 || nonProfilPic}  style={{width:46, height:43, borderRadius:50, marginTop:10}}  />
                   <div className="ms-4">{item.userId && item.userId.username && (<p style={{marginTop:-43,marginLeft:30,fontFamily:'calibri',fontSize:16,fontWeight:700,color:'black'}}>{item.userId.username}</p>
) }
{new Date(item.createdAt) instanceof Date && !isNaN(new Date(item.createdAt)) ? (
    <span style={{marginLeft:30,position:'relative',top:-20,fontFamily:'calibri',fontSize:14,fontWeight:600,color:'black',opacity:0.3}}>
      {new Date(item.createdAt).toLocaleString(undefined, dateOptions)}
    </span>
  ) : (
    <span>Invalid Date</span>
  )}
  </div>
                </div>
                <div style={{marginLeft:-20}}>
                <MainHeading>{item.prayerTitle}</MainHeading>
                <PrayerText className={'justify-content-center'} style={{ marginLeft: 10 }}>{item.prayerdesc}</PrayerText>
                <img src={item.imageUrl} style={{width:500}} className="d-block mx-auto mt-5" />
                </div>
              
          
  <div className={'col mt-5'} id={settings.optionP}>
        <div style={{marginLeft:-22}}>
            <ResponseB style={{marginLeft:70}} prayerId={item._id} likes={item.likes} updatedLikesCount={updatedLikesCount}/>
            <p className={icon.IconF}style={{marginLeft:128, marginTop:-42, color:"black", fontFamily: "Calibri", opacity: 0.5,}}>{item.likes.length}</p>
        </div>
        <div>
        <Link className="btn bg-transparent" style={{opacity: 0.6}}  onClick={() => handleClick(item._id,'viewC')}> <CommentB/>
         <p className={icon.IconF}style={{marginLeft:-54, marginTop:-27, color:"black", fontFamily: "Calibri", opacity: 0.5}}>{item.Comments.length}</p></Link>
        </div>
      <div>
         <Share style={{marginLeft:-100}}/>
       </div>
     </div>
      </div>
          ))}   
          </div>
      ) : (
        <p>No data available</p>
      )} 
       
  {isModalOpened && (
 <div>
 <Modal isOpened={isModalOpened} onClose={() => setIsModalOpened(false)}>
   <PrayerReq prayerId={selectedPostId}/>
 </Modal>
</div>


            )}
        </div>
     
     </div>
   
  
  )
  
}
export default SavePrayers;