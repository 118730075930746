
import PrayerText from "../../UI/MainHead/PrayerText";
import Sharre from '../../notification/feedback/Ssharre';
import classes from './UrgentPrayer.module.css';
import ResponseB from '../../notification/feedback/ResponseB';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React,{useState,useEffect} from "react";
import axios from 'axios';
import CommentB from "../../notification/feedback/CommentB";
import { socket } from '../../../index';
import { useApi } from "../../../context/ApiContext";
import { S3Client } from '@aws-sdk/client-s3';
import nonProfilPic from '../../images/nonIcon.png'
import NetworkStatus from "../../Registerpge/NetworkStatus";

  const key = 'DO00PFUZFUPP9QY8YX4F';
  const secret = 'iAAImPhVSVUS2TTFYlJNLkPryaobYVHV9E0aOdp0G6k';
  const spaceName = 'prayer-weaver-files';
  const region = 'ams3';
  const host = `${spaceName}.${region}.digitaloceanspaces.com`;
  
  const client = new S3Client({
    region,
    endpoint: `https://${host}`,
    credentials: {
      accessKeyId: key,
      secretAccessKey: secret,
    },
    bucketEndpoint: true,

  });



var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const CompletePost = ({prayerId})=>{
  const {prayerData,setPrayerReq,commments,postedComment,getCommments,fetchSinglePost,commentFetching} = useApi();
   const [comments,setCommments] = useState([]);
   const [commentsRealTime, setCommentsRealTime] = useState([]);
    const [likescount, setLikesCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const navigate = useNavigate();
 
   const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  
  const updateImageUrls = (post) => {
    if (!post) return null;

    const imageUrl = post.image ? `https://${host}/prayer/${post.image}` : null;
    const profileImgUrl = post.userId?.AddPic ? `https://${host}/member/${post.userId.AddPic}` : null;

    return {
      ...post,
      imageUrl,
      profileImgUrl,
    };
  };

   useEffect(() => {

    const fetchData = async () => {
      try {
        console.log("prayerId post id",prayerId);
        const fetchedData = await fetchSinglePost(prayerId);
        const updatedPrayerData = updateImageUrls(fetchedData);
        setPrayerReq(updatedPrayerData);
        console.log("updated prayer", updatedPrayerData);
      } catch (err) {
        console.log(err);
      }
    };
     const fetchComment = async () => {
      try {
        await commentFetching(prayerId);
        console.log("comment prayerReq")
       
      } catch (err) {
        console.log(err);
      }
    };
  
     fetchData();
    fetchComment();

   },[prayerId]);
 
  
   const urlC = `${process.env.REACT_APP_API_HOST}/prayers/${prayerId}/comments`;
   
 
     
      const makecomment = async ()=>{
        if (!navigator.onLine) {
          setNetworkError(true);
          return;
          }
    
        const commentt = {
             PrayerComments:comments,
             prayerId:prayerId,  //prayerData.data.prayers[0]._id,
             userId:localStorage.getItem("user-id").toString(),
             userName:localStorage.getItem("user-name").toString(),
  
        }
       
        try{
          const res = await axios.post(urlC,commentt,config)
              setNetworkError(false);

             if (res.status === 201 && postedComment) {
         
              console.log('comment',postedComment);
              }
              setCommments("");  
             
        }catch(err){
          setNetworkError(true);
          console.log(err);
       
        }
   
         }

   useEffect(() => {
          const handleCommentAdded = (comment) => {
            console.log("new Comment", comment);
            getCommments(prevComments => [comment, ...prevComments]);
          };
          const handleLikeUpdate = (newPost) => {
            const updatedPost = updateImageUrls(newPost);
            setPrayerReq(updatedPost);
          };
          socket.connect();
         console.log("UseEffect Socket.io commentAdded", socket);
        socket.on("connect", () => {
        console.log("Socket connected successfully");
      });

      
          socket.on("newComment", handleCommentAdded);
          socket.on('likeAdded', handleLikeUpdate);
          socket.on('unlikeAdded', handleLikeUpdate);
          return () => {
            socket.off("likeAdded");
            socket.off("unlikeAdded");
            socket.off("newComment");
            
          };
     },[socket]);

         const minLength = 100;
         const handleKeyDown = (e) => {
           if (e.ctrlKey && e.keyCode === 13) {
             e.preventDefault();
       
             const position = e.target.selectionEnd;
             const value = e.target.value;
             const newValue = value.substring(0, position) + '\n' + value.substring(position);
             e.target.value = newValue;
       
             // Move the cursor to the next line
             e.target.selectionStart = e.target.selectionEnd = position + 1;
           }
         };

         const updatedLikesCount =(newLikesCount) =>{
          setLikesCount(newLikesCount);
           console.log("likes inside prayer page",likescount);
           };
           const handleClose = () => {
            setIsModalOpen(false);
            navigate('/Main/UrgentPrayers');
            
          };
          if(!isModalOpen){
            return null;
          }
      
          if (!prayerData) {
            return <p>Loading</p>;
          }
        
          const prayerArray = Array.isArray(prayerData) ? prayerData : [prayerData];
          let uiCommentUpdate = commentsRealTime.length > 0 ? commentsRealTime : commments;

    return(
   
          <div className="d-flex row"> 
          <NetworkStatus networkError={networkError} />
           <div className={'col-8 bg-light p-5'} style={{height:730}} id= {classes.colPost} >
          
        {prayerArray !==null ? (
        
       <div>
          
          {prayerArray.map((item) => (
            <div key={item._id} className="ml-5">
             
             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
             <div style={{marginLeft:-50, padding:5, textAlign:'justify'}} className="mb-3" > 
                 <PrayerText >{item.prayerdesc}</PrayerText> 
            </div>
             <img  src={item.imageUrl} style={{width:600,height:460,position:"relative",bottom:48,top:57}} className="d-block mx-auto mt-5" />
           
            </div>
            
          ))}
        </div>
       
      ) : (
        <p>No Post Found</p>
      )}
          
         </div>  
          <div className={'col-4 overflow-y'} id= {classes.colPost} style={{}}>
          <div className={`modal-header`} style ={{backgroundColor: "#f8f9fa"}}>
          <h4 className="modal-title mx-auto">Prayer Weaver</h4>
        </div>
        <div className={`modal-body`} style={{maxHeight:'calc(100vh - 210px)' , overflowY: "auto"}}>
                    
                    {prayerArray !==null ? (
        
        <div>
           
           {prayerArray.map((item) => (  
            <>
              <div>
              <img src={item.profileImgUrl || nonProfilPic} style={{width:46, height:43, borderRadius:50, marginTop:10,}}   alt="Nopic" />
                      <div className="ms-4">{item.userId && item.userId.username && (<p style={{fontFamily:'calibri',fontSize:16,fontWeight:700,color:'black',marginTop:-43,marginLeft:25}}>{item.userId.username}</p>
) }
{new Date(item.createdAt) instanceof Date && !isNaN(new Date(item.createdAt)) ? (
    <span style={{fontFamily:'calibri',fontSize:14,fontWeight:600,color:'black',opacity:0.3,marginLeft:25,position:'relative',top:-20}}>
      {new Date(item.createdAt).toLocaleString(undefined, dateOptions)}
    </span>
  ) : (
    <span>Invalid Date</span>
  )}</div>
                    </div>
               <div style={{marginLeft:-73, width:500, height:0, textAlign:'justify'}} className="mb-3" > 
                 <PrayerText style={{fontSize:24,fontWeight:800}}>{item.prayerTitle}</PrayerText> 
            </div>



            <div style={{marginTop: 60}} >
             <hr style={{width:430}}/>
         
                  
                <div style={{marginLeft:-30}}>
                  <ResponseB prayerId={item._id} likes={item.likes} updatedLikesCount={updatedLikesCount}/>
                  <p  style={{position:"absolute",left:92, top:162}}>{item.likes.length}</p>
                </div>
                    
                       
             
             <div style={{position:'absolute',left:273, top:158}}>
                 <CommentB/> 
                 <p style={{position:"absolute",left:-37, top:4}}>{item.Comments.length}</p>
                   
             </div>
      
              <div style={{position:"absolute",left:350, top:158}} >
                           
                    <Sharre/>
               </div>
                    
         
              <hr style={{width:430}}/>
          </div>
            </>
           
            
            ))}
            
            </div>
          ) : (
            <p>No data available</p>
          )}
       
          
          
          {uiCommentUpdate !== null ?(
          //&& getComments.data.data.comments.length > 0 && (
            
            <div>
              { (uiCommentUpdate.map((comment)=>{
                 //const {PrayerComments,_id} = comment;
                
                 console.log("check");
              return(
               
                      
               <div key={comment._id}>
               {/* <img src={`https://${host}/member/${comment.AddPic}`} style={{width:40, height:40, borderRadius:50, marginTop:10,}}   alt="Nopic" /> */}
             <p style={{fontWeight:800}}>{comment.userName} {comment.AddPic}<strong className={classes.commentdata}>{comment.PrayerComments}</strong>
             </p>
             
              
             </div>
          
  
              )
              
                }))}
      
              </div>
              
                 ):<p></p>}       
          </div>

            <div className={`modal-footer sticky-bottom`} style ={{ marginTop:50, zIndex: 999}}>  
                 <input type="input" className="form-control"  style ={{width:390,position:"relative", top:20,left:-30}} onKeyDown={handleKeyDown} minLength={minLength} onChange={(e)=>{setCommments(e.target.value)}} value={comments} name={"Prayercomment"} placeholder="Add a comment..." />
                 <button className="btn btn-light text-center" style ={{width:75,borderRadius:7, position:"relative", bottom:-19,left:-27}} onClick={()=>makecomment()}>Post</button> 
                 
                 </div>
                 <p style={{position:"relative", top:30, color:'black',opacity:0.75}}> Character Count: {comments.length}/{minLength}</p>
                 
          </div>
          </div>
      
  
    )
}
export default CompletePost;