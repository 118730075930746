// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#MainHeading_Prayertitle__mgLGJ {\n   \n    font-family: 'Calibri';\n    font-size: 16px;\n    font-weight: 700;\n    font-style: normal;\n    line-height: 20px;\n    color:rgb(0,0,0); \n    margin-left: 75px;  \n}\n.MainHeading_PopularPT__9vSHD{\n    /* width:200px; */\n   /* //max-width: 120px;\n   height:25px; */\n   font-family: 'Calibri';\n   font-size: 18px;\n   font-weight: 800;\n   font-style: normal;\n   line-height: 20px;\n   color:#4F4F4F;\n   /* margin-left: 12px; */\n   white-space: nowrap;\n   margin-top: 11px;\n   margin-left: 57px;\n}", "",{"version":3,"sources":["webpack://./src/Components/UI/MainHead/MainHeading.module.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,iBAAiB;GAClB;iBACc;GACd,sBAAsB;GACtB,eAAe;GACf,gBAAgB;GAChB,kBAAkB;GAClB,iBAAiB;GACjB,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,gBAAgB;GAChB,iBAAiB;AACpB","sourcesContent":["#Prayertitle {\n   \n    font-family: 'Calibri';\n    font-size: 16px;\n    font-weight: 700;\n    font-style: normal;\n    line-height: 20px;\n    color:rgb(0,0,0); \n    margin-left: 75px;  \n}\n.PopularPT{\n    /* width:200px; */\n   /* //max-width: 120px;\n   height:25px; */\n   font-family: 'Calibri';\n   font-size: 18px;\n   font-weight: 800;\n   font-style: normal;\n   line-height: 20px;\n   color:#4F4F4F;\n   /* margin-left: 12px; */\n   white-space: nowrap;\n   margin-top: 11px;\n   margin-left: 57px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Prayertitle": "MainHeading_Prayertitle__mgLGJ",
	"PopularPT": "MainHeading_PopularPT__9vSHD"
};
export default ___CSS_LOADER_EXPORT___;
