import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Logo from '../Logo/Logo'

import MainHead from "../UI/MainHead/MainHead";
//const NAVBAR_TEXTS = [{page:"/", text: "You'll recieve email soon, afterwards your status will be upgraded"}, {page:"/SignUp", text: "I want to Prayer for"},{page:"/Main/NewsPrayers", text: "I want to Prayer for"},{page:"/Main/PopularPrayers", text: "I want to Prayer for"},{page:"/Main/MakeStories", text: "I want to Prayer for"},{page:"/Main/SavedPrayer", text: "Saved Prayer"},{page:"/Main/MinePrayers", text: "Saved Prayer"}]
const Massage = () =>{
  
return(
      
<div>

<Logo/>



 
<div className="d-flex  justify-content-center mt-5">
 
<MainHead >Your registration details has been successfully submitted, You'll receive an email once your request status is updated. </MainHead>
<br/>
  <br/>
  
  <br/>
  <br/>
  <br/>
  <br/>

  <br/>
  <br/>
  <br/>
  <br/>
  <br/><br/>
  <br/>
  <br/><br/>
  <br/>
  <br/>
  <br/>
  <br/>


 

 
</div>


</div>

)

}
export default Massage;