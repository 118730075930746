// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Components/images/backgroundResize.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.App_centter__fdvWx{\n  max-width: 100%;\n  margin: 0 auto;\n  padding:0 !important;\n}\n#App_Background__kIKJe{\n    background-image:linear-gradient(180deg, rgba(178, 88, 241, 0.8) 0%, rgba(0, 219, 222, 0.8) 50.1%),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    /* background-image:url('http://thewowstyle.com/wp-content/uploads/2015/07/natural-landscape-purple-lake-wallpaper-.jpg'); */\n    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);\n    max-height: 100%;\n    /* height: 100vh; */\n    /* max-width:100% !important; */\n    width:100%;\n    overflow: hidden;\n    overflow-y: hidden;\n    background-repeat: repeat;\n    background-size: cover !important;\n    /* margin-left: -11px; */\n    /* position: relative;\n    right:6px; */\n    \n  }\n  \n ", "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,cAAc;EACd,oBAAoB;AACtB;AACA;IACI,0IAAgJ;IAChJ,4HAA4H;IAC5H,yCAAyC;IACzC,gBAAgB;IAChB,mBAAmB;IACnB,+BAA+B;IAC/B,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,iCAAiC;IACjC,wBAAwB;IACxB;gBACY;;EAEd","sourcesContent":["\n.centter{\n  max-width: 100%;\n  margin: 0 auto;\n  padding:0 !important;\n}\n#Background{\n    background-image:linear-gradient(180deg, rgba(178, 88, 241, 0.8) 0%, rgba(0, 219, 222, 0.8) 50.1%),url(./Components/images/backgroundResize.jpg);\n    /* background-image:url('http://thewowstyle.com/wp-content/uploads/2015/07/natural-landscape-purple-lake-wallpaper-.jpg'); */\n    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);\n    max-height: 100%;\n    /* height: 100vh; */\n    /* max-width:100% !important; */\n    width:100%;\n    overflow: hidden;\n    overflow-y: hidden;\n    background-repeat: repeat;\n    background-size: cover !important;\n    /* margin-left: -11px; */\n    /* position: relative;\n    right:6px; */\n    \n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centter": "App_centter__fdvWx",
	"Background": "App_Background__kIKJe"
};
export default ___CSS_LOADER_EXPORT___;
